import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';

const colorIndex = 500;

const useStyles = makeStyles(theme => ({
  root: {
    color: green[colorIndex],
    border: `1px solid ${green[colorIndex]}`,
    '&:hover': {
      backgroundColor: green[colorIndex],
      color: theme.palette.getContrastText(green[colorIndex + 200]),
    },
  },
}));

function DoneButton(props) {
  const {
    onDone,
  } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={classes.root}
      aria-label="cancel recording"
      size="small"
      onClick={onDone}
    >
      <DoneIcon />
    </IconButton>
  );
}

DoneButton.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default DoneButton;
