import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    color: red[500],
  },
  pulse: { animation: '$pulse 1.5s ease-in-out 0.5s infinite'},
  '@keyframes pulse': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.1 },
    '100%': { opacity: 1 },
  },
}));

function RecIcon(props) {
  const {
    pulse,
  } = props;
  const classes = useStyles();
  return (
    <span className={clsx(classes.root, {
      [classes.pulse]: pulse,
    })}>
      <FiberManualRecordIcon />
    </span>
  );
}

RecIcon.propTypes = {
  pulse: PropTypes.bool.isRequired,
};

export default RecIcon;
