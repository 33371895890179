import { createSelector } from 'reselect';
import _ from 'lodash';


const debatesSelector = state => state.discuss.debates.byId;
const currentSelector = state => state.discuss.debates.current;

export const getSelectedDebate = createSelector(
  debatesSelector,
  currentSelector,
  (debates, current) => _.has(debates, current) ? debates[current] : null
);


const claimsByIdSelector = state => state.discuss.claims.byId;
const claimIdsSelector = (state, props) => {
  const positionId = props.position.id;
  const byPosition = state.discuss.claims.byPosition;
  return _.has(byPosition, positionId) ? byPosition[positionId]: [];
};

export const makeGetClaims = () => {
  return createSelector(
    claimsByIdSelector,
    claimIdsSelector,
    (claims, ids) => _.values(_.pick(claims, ids))
  );
}


// const currentDebateSelector = state => state.discuss.debates.current;
// const positionsPendingSelector = state => state.discuss.positions.pending;
// const claimsByPositionSelector = state => state.discuss.claims.byPosition;

// @todo is it possible to optimise
// @see https://stackoverflow.com/questions/50965013/reselect-selector-that-invokes-another-selector
// export const fullyLoaded = createSelector(
//   currentDebateSelector,
//   positionsPendingSelector,
//   claimsByPositionSelector,
//   (currentDebate, positionsPending, claimsPending) => {
//     return !_.isNull(currentDebate)
//       && !positionsPending
//       && _.reduce(claimsPending, (result, item) => result && !item.pending, true)
//     ;
//   }
// );


const positionsById = state => state.discuss.positions.byId;

// @todo check if complete before proceeding?
export const composeDebate = createSelector(
  getSelectedDebate,
  positionsById,
  claimsByIdSelector,
  (debate, positions, claimsById) => {
    return {
      name: 'I',
      data: debate,
      // data: {...debate, type: 'ISSUE'}, // @todo this is a workaround,
      positions: _.map(positions, item => {
        // @todo is it possible improve?
        const claims = _.filter(claimsById, o => o.positionId === item.id);
        // const supportings = _.filter(supportingsById, o => o.positionId === item.id)
        // const opposings = _.filter(opposingsById, o => o.positionId === item.id)
        const opposings = _.filter(claims, item => item.type === 'OPPOSING');
        const supportings = _.filter(claims, item => item.type === 'SUPPORTING');
        return {
          name: 'P',
          data: item,
          opposings: _.map(opposings, item => {
            return {
              name: 'O',
              data: item,
            };
          }),
          supportings: _.map(supportings, item => {
            return {
              name: 'S',
              data: item,
            };
          }),
        };
      }),
    };
  }
);
