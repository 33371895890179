import React, { useState } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import SignInOrSignup from 'features/auth/SignInOrSignup';
import ThumbRate from 'components/ThumbRate';
import { voteThumbUp, unvoteThumbUp, voteThumbDown, unvoteThumbDown } from '../store/positionThumbs.slice';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

function PositionThumbRate(props) {
  const {
    // className,
    position,
    pending,
    // error,
    value,
    authUser,
    onVoteUp,
    onUnvoteUp,
    onVoteDown,
    onUnvoteDown,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  // @todo trasform this in a function
  const ownerId = position.metadata.createdBy;
  const owned = Boolean(authUser) && ownerId === authUser.uid;
  // ---------------------------------

  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };

  const handleToggleUp = (event) => {
    // event.preventDefault();
    if (pending) return;
    if (!Boolean(authUser)) { setOpenDialog(true); return; }
    if (value > 0) {
      onUnvoteUp();
    } else {
      if (value < 0) onUnvoteDown();
      onVoteUp();
    }
  };

  const handleToggleDown = (event) => {
    // event.preventDefault();
    if (pending) return;
    if (!Boolean(authUser)) {
      setOpenDialog(true);
      return;
    }
    if (value < 0) {
      onUnvoteDown();
    } else {
      if (value > 0) onUnvoteUp();
      onVoteDown();
    }
  };

  // console.log('PositionThumbRate',s props); // DEBUG

  return (
    <div className={classes.root}>
      <ThumbRate
        {...props}
        disabled={owned}
        onToggleUp={handleToggleUp}
        onToggleDown={handleToggleDown}
      />
      <SignInOrSignup
        openDialog={openDialog}
        onCloseDialog={handleCloseDialog}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const positionId = ownProps.position.id;
  const thumbsState = state.discuss.positionThumbs;
  return {
    pending: thumbsState.pending,
    error: thumbsState.error,
    value: _.get(thumbsState, `byId[${positionId}].value`, 0),
    totalUp: _.get(thumbsState, `byId[${positionId}].totalUp`, 0),
    totalDown: _.get(thumbsState, `byId[${positionId}].totalDown`, 0),
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const position = ownProps.position;
  const newEntry = {
    debateId: position.debateId,
    positionId: position.id,
  };
  return {
    onVoteUp: () => {
      const res = dispatch(voteThumbUp(newEntry));
      // console.log('onVoteUp', res); // DEBUG
      return res;
    },
    onUnvoteUp: () => {
      const res = dispatch(unvoteThumbUp(newEntry));
      // console.log('onUnvoteUp', res); // DEBUG
      return res;
    },
    onVoteDown: () => dispatch(voteThumbDown(newEntry)),
    onUnvoteDown: () => dispatch(unvoteThumbDown(newEntry)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionThumbRate);
