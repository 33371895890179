import { withStyles } from '@material-ui/core/styles';

import ClaimCard from './ClaimCard';

const styles = theme => ({
  colors: {
    border: '1px solid rgb(241, 178, 84)', // @todo move color into theme
    backgroundColor: 'rgb(255, 248, 238)', // @todo move into theme
  },
});

export default withStyles(styles)(ClaimCard);
