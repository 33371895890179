import React, { useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginTop: 20
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function DashboardPage(props) {
  const classes = useStyles();
  const { profile, onMount } = props;

  useEffect(() => {
    onMount();
    return () => {
      // @todo clean store
    }
  }, [onMount]);

  if (_.isNull(profile)) return <Redirect push to="/401" />; // @todo analize error type
  // console.log('Dashboard.JS', 'DashboardPage', 'error', error) //DEBUG
  return (
    <div className={classes.root}>
      <h1>Dashboard Page</h1>
      {/* @todo */}
    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onMount: () => {}, // @todo
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
