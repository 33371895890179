// @see https://cimdalli.github.io/mui-theme-generator/

export default {
  palette: {
    primary: {
      main: 'rgb(0, 73, 115)', // '#004973'
      light: 'rgb(231, 241, 245)',
    },
    secondary: {
      main: 'rgb(255, 187, 48)', // '#FFBB30'
    },
    tertiary: {
      main: 'rgb(255, 163, 68)', // '#FFA344'
      contrastText: 'black',
    },
    // quaternary, quinary, senary, septenary, octonary, nonary,
  },
  zIndex: {
    timeline: 500,
  },
}
