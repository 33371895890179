import { withStyles } from '@material-ui/core/styles';

import ClaimCard from './ClaimCard';

const styles = theme => ({
  colors: {
    border: '1px solid rgb(40, 100, 137)', // @todo move color into theme
    backgroundColor: 'rgb(233, 241, 245)', // @todo move into theme
  },
});

export default withStyles(styles)(ClaimCard);
