import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const StyledSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 8,
  },
  thumb: {
    width: 50,
    height: 30,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    borderRadius: 10,
    marginTop: -11,
    marginLeft: -25,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },

}));

function PercSlider(props) {
  const {
    className,
    ...other
  } = props;
  const classes = useStyles();

  const ThumbComponent = (props) => (
    <span {...props}>
      {props['aria-valuenow']}%
    </span>
  );

  // console.log('PercSlider', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)}>
      <StyledSlider
        valueLabelDisplay="auto"
        min={0}
        max={100}
        track={false}
        ThumbComponent={ThumbComponent}
        {...other}
      />
    </div>
  );
}

// @todo check props
// PercSlider.propTypes = {
//   : PropTypes.func.isRequired,
// };

export default PercSlider;
