import React from 'react';
import { Link } from 'react-router-dom';
import { LoremIpsum } from 'lorem-ipsum';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { DEBATES_ROUTE } from 'features/discuss/discuss.routes';

import hero from 'assets/images/hero.png';
import logoInline from 'assets/bcause-logo-inline.png';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';

import discussImage from 'assets/images/discuss.jpeg';
import exploreImage from 'assets/images/explore.jpeg';
import decideImage from 'assets/images/decide.jpeg';

const cardSize = 400;

const useStyles = makeStyles(theme => ({
  root: {
  },
  hero: {
    backgroundImage: `url(${hero})`,
    margin: '20px 0px',
    color: theme.palette.primary.main,
    '& > *': {
      marginBlockStart: '0px', marginBlockEnd: '0px',
      marginLeft: 200,
    },
    '& > h1': { textTransform: 'uppercase' },
    '& > h2': {},
  },
  cards: {
    '& > div:nth-child(even) > div ': {
      backgroundColor: theme.palette.primary.main,
    },
    '& > div:nth-child(odd) > div ': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
    card: {
      border: 'solid white 1px', // @todo why this workaround ???
      height: cardSize,
    },
    cardContent: {
      margin: '170px auto',
      padding: '0px 40px',
      fontSize: 20,
      textAlign: 'center',
      color: theme.palette.primary.contrastText,
      '& > p': {
        marginBlockStart: '0px', marginBlockEnd: '0px',
        fontWeight: 'bold',
      },
      '& > a': {
        fontSize: 30,
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
      },
      '& > a:hover': { textDecoration: 'underline' },
    }
}));

export default function() {
  const classes = useStyles();

  const lorem = new LoremIpsum({
    wordsPerSentence: { min: 10, max: 16 }
  });

  const Hero = () => (
    <div className={classes.hero}>
      <img src={logoInline} alt="bcause.it - Reasoning for change" />
      <h1>Reasoning for Change</h1>
      <h2>A Structured and Decentralised Discussion System for Distributed Decision Making</h2>
    </div>
  );

  const item = (route, title, text, icon, bgImage) => (
    <Grid item xs={6}>
      <div
        className={classes.card}
        style={{
          backgroundImage: bgImage ? `url(${bgImage})` : 'none',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={classes.cardContent}>
          {/* <p>{icon}</p> */}
          <Link to={route}>{title}</Link>
          <p>{text}</p>
        </div>
      </div>
    </Grid>
  );

  return (
    <>
      <Hero />
      <Container className={classes.root}>
        <Grid className={classes.cards} container spacing={2}>
          {item(DEBATES_ROUTE, 'Reflect', lorem.generateSentences(1), <EqualizerIcon />)}
          {item(DEBATES_ROUTE, 'Discuss', lorem.generateSentences(1), null, discussImage)}
          {item(DEBATES_ROUTE, 'Explore', lorem.generateSentences(1), null, exploreImage)}
          {item(DEBATES_ROUTE, 'Consult', lorem.generateSentences(1), <SettingsIcon />)}
          {item(DEBATES_ROUTE, 'Assess', lorem.generateSentences(1), <SearchIcon />)}
          {item(DEBATES_ROUTE, 'Decide', lorem.generateSentences(1), null, decideImage)}
        </Grid>
      </Container>
    </>
  );

}
