import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle'; // substituted by custom
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import DialogTitle from 'components/DialogTitle';
import { editPosition } from '../store/positions.slice';
import { openDialog, closeDialog } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
}));

function PositionEdit(props) {
  const {
    show,
    position,
    // pending, // @todo
    // error,
    openDialog,
    onOpen,
    onClose,
    onEdit,
  } = props;
  const classes = useStyles();
  const [values, setValues] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSave = (event) => {
    event.preventDefault();
    onEdit(values);
  };

  const buildTrigger = () => (
    <IconButton
      size="small"
      color="inherit"
      aria-label="Edit position"
      onClick={onOpen}
    >
      <EditIcon />
    </IconButton>
  );

  const buildForm = () => (
    <TextField
      name="text"
      autoComplete="text" // @todo REMOVE it. Only for an easy develop
      label="Edit your position"
      variant="filled"
      defaultValue={_.get(position, 'text', '')}
      rows={3}
      required
      multiline
      fullWidth
      onChange={handleChange}
    />
  );

  const buildActions = () => (<>
    <Button
      variant="contained"
      size="small"
      onClick={onClose}
    >Cancel</Button>
    <Button
      variant="contained"
      color="secondary"
      size="small"
      // className={classes.button}
      startIcon={<SaveIcon />}
      onClick={handleSave}
    >Save</Button>
  </>);

  useEffect(() => {
    if (position) {
      setValues(position);
    }
  }, [position]);

  // console.log('PositionDimensionsCast', props); // DEBUG

  return (
    <div className={classes.root}>
      {show && buildTrigger()}
      <Dialog
        open={openDialog}
        // maxWidth="md"
        fullWidth={true}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>Edit position</DialogTitle>
        <DialogContent>
          {buildForm()}
        </DialogContent>
        <DialogActions>
          {buildActions()}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const position = ownProps.position;
  const dialogState = state.discuss.layout.dialogs;
  // const authUser = state.auth.profile;
  const uid = _.get(state.auth.profile, 'uid', false);

  // @todo move in a selectort ?
  const agreementsState = state.discuss.positionAgreements;
  const thumbsState = state.discuss.positionThumbs;
  const heartsState = state.discuss.positionHearts;
  const dimensionsState = state.discuss.positionDimensions;
  const claimsState = state.discuss.claims;

  return {
    openDialog: dialogState.positionEdit === position.id,
    show: uid === position.metadata.createdBy
      && !_.has(agreementsState, `byId[${position.id}]`)
      && !_.has(thumbsState, `byId[${position.id}]`)
      && !_.has(heartsState, `byId[${position.id}]`)
      && !_.has(dimensionsState, `byId[${position.id}]`)
      && !_.has(claimsState, `byPosition[${position.id}]`)
      ,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const position = ownProps.position;
  return {
    onEdit: values => {
      return dispatch(editPosition(values))
        .then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(closeDialog('positionEdit'));
          }
          return res;
        });
    },
    onOpen: () => dispatch(openDialog({
      resourceName: 'positionEdit',
      resourceId: position.id,
    })),
    onClose: () => dispatch(closeDialog('positionEdit')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionEdit);
