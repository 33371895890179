import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';


export const fetchPositionAgreements = createAsyncThunk(
  'positionAgreements/fetchPositionAgreement',
  async (debateId, thunkAPI) => {
    // const ownerId = thunkAPI.getState().auth.profile.uid;
    // const agreementsById = await api.getResource('positionAgreements', {
    //   relativePath: debateId,
    // });
    // _.each(agreementsById, item => {
    //   item.value = _.get(item, `users[${ownerId}].value`, 0);
    //   delete item.users;
    // });
    // return agreementsById;
    return []; // @todo fix this
  }
);

export const fetchPositionAgreement = createAsyncThunk(
  'positionAgreements/fetchPositionAgreement',
  async (position, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = position.debateId;
    const positionId = position.id;
    return await api.getResource('positionAgreements', {
      relativePath: `${debateId}/${positionId}/users/${ownerId}`,
    });
  }
);

export const savePositionAgreement = createAsyncThunk(
  'positionAgreements/savePositionAgreement',
  async (newEntry, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = newEntry.debateId;
    const positionId = newEntry.positionId;
    return await api.addResource('positionAgreements', newEntry, {
      relativePath: `${debateId}/${positionId}/users/${ownerId}`,
      unique: true,
    });
  }
);

const initialState = {
  // byId: {
  //   //<positionId>: {
  //     //  pending:false,
  //     //  error : null,
  //     //  data: null,
  //   //}
  // },
  pending:false,
  error : null,
  byId: {},
};

const slice = createSlice({
  name: 'positionAgreements',
  initialState,
  reducers: {
    resetPositionAgreements() {
      return initialState;
    },
  },
  extraReducers: {
    //
    [fetchPositionAgreement.pending]: (state, action) => {
      // console.log('fetchpositionAgreement.fulfilled', action); // DEBUB
      const positionId = action.meta.arg.id;
      state.byId[positionId] = {
        pending: true,
        // data: null, error: null,
      };
    },
    [fetchPositionAgreement.fulfilled]: (state, action) => {
      // console.log('fetchpositionAgreement.fulfilled', action); // DEBUB
      const positionId = action.meta.arg.id;
      state.byId[positionId].pending = false;
      state.byId[positionId]['data'] = action.payload;
    },
    [fetchPositionAgreement.rejected]: (state, action) => {
      // console.log('fetchpositionAgreement.rejected', action); // DEBUB
      const positionId = action.meta.arg.id;
      state.byId[positionId].pending = false;
      state.byId[positionId]['error'] = action.error;
    },
    //
    [savePositionAgreement.pending]: (state, action) => {
      // console.log('savepositionAgreement.pending', action); // DEBUB
      const positionId = action.meta.arg.positionId;
      state.byId[positionId] = {
        pending: true,
        // data: null, error: null,
      };
    },
    [savePositionAgreement.fulfilled]: (state, action) => {
      // console.log('savepositionAgreement.fulfilled', action); // DEBUB
      const positionId = action.meta.arg.positionId;
      state.byId[positionId].pending = false;
      state.byId[positionId]['data'] = action.payload;
    },
    [savePositionAgreement.rejected]: (state, action) => {
      // console.log('savepositionAgreement.rejected', action); // DEBUB
      const positionId = action.meta.arg.positionId;
      state.byId[positionId].pending = false;
      state.byId[positionId]['error'] = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetPositionAgreements } = actions;
export default reducer;
