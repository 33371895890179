import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import sandboxRoutes, {
  DEBATE_CARDS_ROUTE,
  POSITION_CARDS_ROUTE,
  CLAIM_CARDS_ROUTE,
  AGREEMENT_ROUTE,
  DIMENTIONS_ROUTE,
  COGNITIVER_ROUTE,
  THUMB_RATE_ROUTE,
  // RECORDER_ROUTE,
} from 'features/sandbox/sandbox.routes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '90vh',
  },
    left: { width: 200 },
    content: {
      border: '2px solid red',
      width: '100%'
    },
}));

const item = (route, label) => (
  <ListItem button component={Link} to={route}>
    <ListItemText primary={label} />
  </ListItem>
);

function Sandbox(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <List component="nav">
          {item(DEBATE_CARDS_ROUTE, 'Debate')}
          {item(POSITION_CARDS_ROUTE, 'Positions')}
          {item(CLAIM_CARDS_ROUTE, 'Claims')}
          {item(AGREEMENT_ROUTE, 'Agreement')}
          {item(DIMENTIONS_ROUTE, 'Cognitive Dimentions')}
          {item(COGNITIVER_ROUTE, 'Cognitive Ranking')}
          {item(THUMB_RATE_ROUTE, 'Thumb rate')}
          {/* {item(RECORDER_ROUTE, 'Recorder')} */}
          {/* @note add new links HERE */}

        </List>
      </div>
      <div className={classes.content}>
        {sandboxRoutes}
      </div>
    </div>
  );
}

export default Sandbox;
