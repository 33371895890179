import React, { useState } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
// import Tooltip from '@material-ui/core/Tooltip';

import SignInOrSignup from 'features/auth/SignInOrSignup';
import HeartFeedback from 'components/HeartFeedback';
import { giveHeart, ungiveHeart } from '../store/positionHearts.slice';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

// const snackText = 'Thanks the author for the fairness and balance of your contrib.'; // @todo

function getTooltipTitle(value) {
  return value > 0
    ? 'Thanks, this is a useful contrib'
    : 'Thanks author';
};

function PositionHeartCast(props) {
  const {
    // className,
    position,
    pending,
    // error,
    value,
    total,
    authUser,
    onToggleUp,
    onToggleDown,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  // @todo trasform this in a function
  const ownerId = position.metadata.createdBy;
  const owned = Boolean(authUser) && ownerId === authUser.uid;
  // ---------------------------------

  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };

  const handleToggle = () => {
    if (pending) return;
    if (!Boolean(authUser)) { setOpenDialog(true); return; }
    value > 0 ? onToggleUp() : onToggleDown();
  };

  // console.log('PositionHeartCast', props); // DEBUG

  return (
    // <div className={classes.root}>
    //   <Tooltip title={getTooltipTitle(value)} placement="top" arrow>
    //     <IconButton
    //       size="small"
    //       // aria-label="Open reply to an argument dialog" // @todo
    //       onClick={handleToggle}
    //     >
    //       { value > 0 ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
    //     </IconButton>
    //   </Tooltip>
    // </div>
    <div className={classes.root}>
      <HeartFeedback
        {...props}
        showLabel={owned}
        disabled={owned}
        onToggle={handleToggle}
      />
      <SignInOrSignup
        openDialog={openDialog}
        onCloseDialog={handleCloseDialog}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const positionId = ownProps.position.id;
  const heartsState = state.discuss.positionHearts;
  return {
    pending: heartsState.pending,
    error: heartsState.error,
    value: _.get(heartsState, `byId[${positionId}].value`, 0),
    total: _.get(heartsState, `byId[${positionId}].total`, 0),
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const position = ownProps.position;
  const newEntry = {
    debateId: position.debateId,
    positionId: position.id,
  };
  return {
    onToggleDown: () => {
      dispatch(giveHeart(newEntry))
        // .them(res => {
        //   // @todo if fulfilled dispatch open snackbar
        // });
    },
    onToggleUp: () => dispatch(ungiveHeart(newEntry)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionHeartCast);
