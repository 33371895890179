import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { red } from '@material-ui/core/colors';

const colorIndex = 500;

const useStyles = makeStyles(theme => ({
  root: {
    color: red[colorIndex],
    border: `1px solid ${red[colorIndex]}`,
    '&:hover': {
      backgroundColor: red[colorIndex],
      color: theme.palette.getContrastText(red[colorIndex]),
    },
  },
}));

function ClearButton(props) {
  const {
    onClear,
  } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={classes.root}
      aria-label="cancel recording"
      size="small"
      onClick={onClear}
    >
      <ClearIcon />
    </IconButton>
  );
}

ClearButton.propTypes = {
  onClear: PropTypes.func.isRequired,
};

export default ClearButton;
