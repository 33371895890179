import React from 'react';
import { Route } from 'react-router-dom';

import DebatesPage from './DebatesPage';
import DebatePage from './DebatePage';

const baseUrl = '/discuss';
export const DEBATES_ROUTE = `${baseUrl}/debates`;
export const  DEBATE_ROUTE = `${baseUrl}/debate/:debateId`;

export function getDebateRoute(id) {
  return `${baseUrl}/debate/${id}`;
};

export default (
  <Route path={baseUrl}>
    <Route path={DEBATES_ROUTE} exact component={DebatesPage} />
    <Route path={DEBATE_ROUTE} exact component={DebatePage} />
  </Route>
);
