import React, {
  //useState,
  useCallback, useEffect
  } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

// import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DialogTitle from 'components/DialogTitle';

import { checkSubscription } from '../store/participants.slice';

// const useStyles = makeStyles(theme => ({

// }));

function ParticipantAlert(props) {
  const {
    // loading,
    // user,
    // authUser,
    onMount,
    // onDismount,
  } = props;
  // const classes = useStyles();
  // const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    // @todo
    // @todo check in some way if pseudo already exists
    // save participant

    // setOpenDialog(false);
  };

  const buildContent = () => (<>
    This is the first time....
  </>);

  const buildActions = () => (<>
    <Button variant="contained" color="primary" size="small"
      onClick={handleCloseDialog}
    >Enter</Button>
  </>);

  const mount = useCallback(onMount, []);
  useEffect(() => {
    mount();
  }, [mount]);

  // const mount = useCallback(onMount, []);
  // const dismount = useCallback(onDismount, []);
  // useEffect(() => {
  //   mount();
  //   return () => dismount();
  // }, [mount, dismount]);

  // console.log('ParticipantAlert', props); // DEBUG

  // if (loading) return null;

  return (
    <Dialog
      open={false} // @todo
      // open={openDialog}
      maxWidth="xs"
      fullWidth={true}
      onClose={handleCloseDialog}
    >
      <DialogTitle>Welcome</DialogTitle>
      <DialogContent>
        {buildContent()}
      </DialogContent>
      <DialogActions>
        {buildActions()}
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state, ownProps) => {
  const profileState = state.auth.profile;
  const participantsState = state.discuss.participants;
  return {
    //ready
    participant: _.get(participantsState, `byId['${profileState.uid}']`, null),
    authUser: profileState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onMount: () => dispatch(checkSubscription()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantAlert);
