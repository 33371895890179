import React from 'react'

// @see https://stackoverflow.com/questions/38510443/how-to-use-an-svg-file-in-a-svgicon-in-material-ui
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';

import imageSvg from 'assets/icons/amap/position.svg';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit'
  }
});

export default function(props) {
  const classes = useStyles();
  return (
    <Icon {...props}><img className={classes.imageIcon}
           src={imageSvg} alt="" // @todo
      /></Icon>
  );
}