import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    '& > div': { textAlign: 'center' }
  },
  amount: {

  },
  selected: { color: 'black' },
}));

const voteLabel = value => {
  const DIV = 1000;
  return value > DIV ? _.ceil(value / DIV, 1) + 'K' : (value || 0);
};

function ThumbRate(props) {
  const {
    className,
    amount,
    value,
    onRate,

  } = props;
  const classes = useStyles();

  const handleRating = value => event => {
    event.preventDefault();
    onRate(value);
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <IconButton
          className={clsx({
            [classes.selected]: value > 0,
          })}
          aria-label="like"
          size="small"
          onClick={handleRating(1)}
        >
          <ThumbUpIcon
            fontSize="small"
          />
        </IconButton>
      </div>
      <div className={classes.amount}>
        {voteLabel(amount)}
      </div>
      <div>
        <IconButton
          className={clsx({
            [classes.selected]: value < 0,
          })}
          aria-label="dislike"
          size="small"
          onClick={handleRating(-1)}
        >
          <ThumbDownIcon
            fontSize="small"
          />
        </IconButton>
      </div>
    </div>
  );
}

ThumbRate.propTypes = {
  onRate: PropTypes.func.isRequired,
};

export default ThumbRate;
