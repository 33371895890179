import React from 'react'
import _ from 'lodash';

// @see https://stackoverflow.com/questions/38510443/how-to-use-an-svg-file-in-a-svgicon-in-material-ui
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';

import bwSvg from 'assets/icons/thanks/clap-bw.svg';
import bwNegSvg from 'assets/icons/thanks/clap-bw-neg.svg';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit'
  }
});

export default function(props) {
  const classes = useStyles();
  const variant = _.get(props, 'variant', 'bw');

  let image = null;
  switch(variant) {
    case 'bw':
      image = bwSvg;
      break;
    case 'neg':
      image = bwNegSvg;
      break;
    default:
      image = bwSvg;
  }

  return (
    <Icon {...props}>
      <img className={classes.imageIcon}
           src={image} alt="" // @todo
      />
    </Icon>
  );
}