import React, { forwardRef, useState } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: '20px',
    // padding: '20px',
    '& form div': { marginTop: '5px' },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  error: {
    color: 'red',
  },
  image: {
    width: '100%',
    '& > input': { display: 'none' },
    '& > p': {
      position: 'relative',
      margin: '0 auto',
      width: 150, height: 120, lineHeight: '120px',
      textAlign: 'center',
      overflow: 'hidden',
    },
    '& > p > img': {
      position: 'absolute', left: -9999, right: -9999,
      // top: -9999, bottom: -9999,
      margin: 'auto',
      // maxWidth: '100%',
      maxHeight: '100%'
    },
    '& .bordered': {
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: 5,
    },
  },
  fields: {
    '& > div': { marginBottom: theme.spacing(1) }
  },
  actions: {
    marginBottom: theme.spacing(1),
    textAlign: 'right',
    '& > button': { margin: '0px 5px' }
  },
}));

const DebateForm = forwardRef((props, ref) => { // @todo maybe we don't need anymore to use the ref pattern
  const {
    pending,
    error, // @todo become status={success/error with message} ?
    onSubmit,
    onCancel,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({
    title: '',
    text: '',
  });

  function handleChange(event) {
    const { name, value, files } = event.target;
    const _value = _.isEmpty(files) ? value : files[0];
    setValues({ ...values, [name]: _value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    return onSubmit(values);
  }

  return (
    <div className={classes.root}>

      {Boolean(error) &&  <p className={classes.error}>{error.message}</p>}

      <form
        ref={ref}
        className={classes.form}
        autoComplete="off"
        // noValidate
        onSubmit={handleSubmit}
      >
        {/* <div className={classes.image}>
          <input
            id="debate-image"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleChange}
          />
          {_.has(values, 'image')
            ? <p>
                <img src={URL.createObjectURL(values.image)} alt="preview" />
              </p>
            : <p className="bordered">
                <label htmlFor="debate-image">
                  <IconButton color="primary" aria-label="upload debate picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </p>
          }
        </div> */}

        <div className={classes.fields}>
          <TextField
            name="title"
            autoComplete="title" // @todo REMOVE it. Only for an easy develop
            label="Title"
            variant="outlined"
            autoFocus
            required
            // error={false} // @todo
            // helperText="Incorrect entry." // @todo
            fullWidth
            onChange={handleChange}
          />
          <TextField
            name="text"
            autoComplete="text" // @todo REMOVE it. Only for an easy develop
            label="Description"
            variant="filled"
            rows={3}
            // required // @todo take a look to previous field
            multiline
            fullWidth
            onChange={handleChange}
          />
        </div>

        <div className={classes.actions}>
          <Button
            variant="contained"
            size="small"
            onClick={onCancel}
          >Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            disabled={pending}
            startIcon={<SaveIcon />}
          >Save</Button>
        </div>
      </form>
    </div>
  );
});

export default DebateForm;
