import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import PercSlider from 'components/PercSlider';

// @todo change class names
const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  sliderRow: {
    display: 'flex',
    alignItems: 'center',
  },
    sliderBox: {
      // border: 'solid black 1px', // DEBUG
      borderRadius: '10px',
      margin: '5px 0px',
      padding: 5,
      width: 100,
      height: 80,
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '14px',
      '& strong': { fontSize: '16px' },
    },
    slider: {
      // border: 'solid black 1px', // DEBUG
      marginLeft: 30,
      width: 400
    },
    slideBoxPrimary: {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.contrastText,
      color: 'white',
    },
    slideBoxSecondary: {
      backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.secondary.contrastText,
      color: 'white',
    },
}));

function DimensionSlider(props) {
  const {
    name,
    variant,
    label,
    ...other
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.sliderRow}>
      <div className={clsx(
        classes.sliderBox,
        classes[`slideBox${variant}`])
      }>
        {label}
      </div>
      <div className={classes.slider}>
        <PercSlider {...other} />
      </div>
    </div>
  );
}

// @todo check props
// DimensionSlider.propTypes = {
//   : PropTypes.func.isRequired,
// };

DimensionSlider.defaultProps = {
  value: 50,
};

export default DimensionSlider;