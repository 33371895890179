import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import PercSlider from 'components/PercSlider';
import DimensionSlider from 'features/discuss/components/DimensionSlider';

const useStyles = makeStyles(theme => ({
  root: {

  },
}));

export default function(props) {
  const classes = useStyles();
  const [level, setLevel] = useState(50);
  const [levels, setLevels] = useState({ test2: 50 });

  const onSlide = (event, value) => {
    console.log('onSlide', level);
    setLevel(value);
  };

  const onSlide2 = (event, value) => {
    console.log('onSlide2', levels);
    setLevels({ ...levels, test2: value });
  };

  return (
    <div className={classes.root}>

      <Container>

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          PercSlider: test 2
        </Typography>

        Value: {level}

        <PercSlider
          name="PercSliderTest1"
          value={level}
          onChange={onSlide}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          PercSlider: test 2
        </Typography>

        Value: {levels.test2}

        <PercSlider
          name="PercSliderTest2"
          value={levels.test2}
          onChange={onSlide2}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          DimensionSlider: test 1
        </Typography>

        Value: {level}

        <DimensionSlider
          variant="Primary"
          label={<>This is a very <strong>polarized</strong> position</>}
          name="DimensionSliderTest1"
          value={level}
          onChange={onSlide}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          DimensionSlider: test 2
        </Typography>

        Value: {levels.test2}

        <DimensionSlider
          variant="Secondary"
          label={<>This is a very <strong>polarized</strong> position</>}
          name="DimensionSliderTest2"
          value={levels.test2}
          onChange={onSlide2}
        />

        <p>&nbsp;</p>

      </Container>

    </div>
  );
}
