import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import DebateCard from 'features/discuss/debate/components/DebateCard';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

export default function(props) {
  const classes = useStyles();

  const mock = {
    "id" : "-LwIFKJe6vCQJpuky1lk",
    "image": "",
    "metadata" : {
      "createdBy" : "nNc3Z0wpA6ScoXKMUYbbmriffps2",
      "createdDate" : 1576576046412
    },
    "tags" : [ "hello", "world" ],
    "text" : "Cats should be more preferred by humans than dogs.",
    "title" : "Do you prefer cats or dogs?",
    "type" : "ISSUE"
  };

  return (
    <div className={classes.root}>

      <Container maxWidth="xs">

        <p>&nbsp;</p>

        <DebateCard
          entity={mock}
        />

        <p>&nbsp;</p>

        <DebateCard
          side="right"
          entity={mock}
        />

        <p>&nbsp;</p>



      </Container>

    </div>
  );
}
