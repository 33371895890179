import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  user: {
    fontSize: 13,
    fontWeight: 'bold',
    // fontStyle: 'italic',
  },

}));

const isOwned = (user, authUser) => {
  return user !== null && authUser !== null && user.id === authUser.uid;
};

// const getInitials = (user) => {
//   const diplayedName = _.get(user, 'pseudo', null); // @temp
//   return _.truncate(diplayedName, {
//     length: 1,
//     omission: ''
//   });
// };

function ParticipantLabel(props) {
  const {
    className,
    participant,
    authUser,
  } = props;
  const classes = useStyles();
  const diplayedName = _.get(participant, 'pseudo', null);
   // @note <<deleted user>> usecase in partecipants.slice
   // ??? to be revised

  // console.log('ParticipantLabel', props); // DEBUG

  if (!Boolean(participant)) return null;
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.user}>
        {isOwned(participant, authUser)
          ? 'You'
          : diplayedName
        }:
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const participantsState = state.discuss.participants;
  return {
    participant: _.get(participantsState, `byId['${ownProps.userId}']`, null),            // HERE selector
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantLabel);
