import React, { useCallback, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Issue from './debate/components/Issue';
import DebateActionPanel from './debate/DebateActionPanel';
import DebateLeftDrawers from './debate/DebateLeftDrawers'
import DebateRightDrawers from './debate/DebateRightDrawers';
import DebateImageEdit from './debate/DebateImageEdit';
import Positions from './positions/Positions';
import PositionCreate from './positions/PositionCreate';
import ParticipantAlert from './participants/ParticipantAlert';

import { getSelectedDebate } from './store/discuss.selectors';
import { loadDiscussion, unloadDiscussion } from './store/discuss.slice';

const imageHeight = 100;
const topicHeight = 95;
// const topicHeight = 130;
const actionsHeight = 58;

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid green 1px', // DEBUG
    position: 'fixed', right: 1, bottom: 1, left: 1,
    top: theme.mixins.toolbar.minHeight,
  },
    // hero: {
    //   // border: 'solid red 1px', // DEBUG
    //   overflow: 'hidden',
    //   height: imageHeight,
    //   '& > img': {
    //     width: '100%',
    //     transform: 'translateY(-50%)', // @see http://jonrohan.codes/fieldnotes/vertically-center-clipped-image/
    //   },
    // },
    topic: {
      // border: 'solid orange 1px', // DEBUG
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      height: topicHeight,
      paddingTop: 22,
    },
    content: {
      // border: 'solid blue 1px', // DEBUG
      // border: `2px solid ${theme.palette.grey[500]}`,
      borderTop: `2px solid ${theme.palette.grey[500]}`,
      background: theme.palette.primary.light,
      display: 'flex', flexGrow: 0,
      height: `calc(100% - ${topicHeight + actionsHeight}px)`,
      // height: `calc(100% - ${imageHeight + topicHeight + actionsHeight}px)`,
    },
      drawer: {
        // border: 'solid red 1px', // DEBUG
      },
      positions: {
        // border: 'solid green 1px', // DEBUG
        flexBasis: '100%'
      },
    actionPanel: {
      // border: 'solid black 1px', // DEBUG
      // backgroundColor: theme.palette.grey[300],
      borderRadius: 20,
      backgroundColor: theme.palette.primary.main,
      height: actionsHeight,
    },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  // spinner: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   height: '100vh',
  // },
}));

function DebatePage(props) {
  const {
    ready,
    debate,
    error,
    authUser,
    onMount,
    onDismount
  } = props;
  const classes = useStyles();
  const debateImage = _.get(debate, 'image', null); // @todo or default image

  const mount = useCallback(onMount, []);
  const dismount = useCallback(onDismount, []);
  useEffect(() => {
    mount();
    return () => dismount();
  }, [mount, dismount]);

  // console.log('DebatePage', props); // DEBUG

  if (Boolean(error)) {
    // @todo custom message in the redirected page
    // @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/Redirect.md#to-object
    return <Redirect push to="/404" />;
  }

  if (!ready) {
    return (
      <Backdrop className={classes.backdrop} open={!ready}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      {/* <div className={classes.hero}>
        {debateImage && <img src={debateImage} alt="debate hero"/>}
        <DebateImageEdit debate={debate} />
      </div> */}
      <div className={classes.topic}>
        <Issue entity={debate} />
        <DebateActionPanel />
      </div>
      <div className={classes.content}>
        <DebateLeftDrawers className={classes.drawer} />
        <Positions
          className={classes.positions}
          debate={debate}
        />
        <DebateRightDrawers className={classes.drawer} />
      </div>
      <PositionCreate
        className={classes.actionPanel}
        debate={debate}
      />
      {authUser && <ParticipantAlert />}
    </div>
  );
}

const mapStateToProps = (state) => {
  const discussState = state.discuss.layout;
  const debatesState = state.discuss.debates;
  return {
    ready: discussState.ready,
    error: debatesState.error,
    debate: getSelectedDebate(state),
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const debateId = ownProps.match.params.debateId; // @todo move as a onMount prop?
  return {
    onMount: () => dispatch(loadDiscussion(debateId)),
    onDismount: () => dispatch(unloadDiscussion()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebatePage);
