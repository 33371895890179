import { withStyles } from '@material-ui/core/styles';

import PositionCard from './PositionCard';

const styles = theme => ({
  colors: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  actionbarColors: {
    color: theme.palette.primary.contrastText,
  },
});

export default withStyles(styles)(PositionCard);
