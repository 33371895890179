import React from 'react'
import _ from 'lodash';

// @see https://stackoverflow.com/questions/38510443/how-to-use-an-svg-file-in-a-svgicon-in-material-ui
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';

import coloredSvg from 'assets/icons/reflect/rating.svg';
// import coloredSvg from 'assets/icons/reflect/thumbup-colored.svg';
// import coloredSvg from 'assets/icons/reflect/reviews-colored.svg';
// import whiteSvg from 'assets/icons/reflect/feedback-white.svg';
// import bwSvg from 'assets/icons/reflect/feedback-bw.svg';
// import bwNegSvg from 'assets/icons/reflect/feedback-bw-neg.svg';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit'
  }
});

export default function(props) {
  const classes = useStyles();
  const variant = _.get(props, 'variant', 'colored');

  let image = null;
  switch(variant) {
    // case 'white':
    //   image = whiteSvg;
    //   break;
    // case 'bw':
    //   image = bwSvg;
    //   break;
    // case 'neg':
    //   image = bwNegSvg;
    //   break;
    default:
      image = coloredSvg;
  }

  return (
    <Icon {...props}>
      <img className={classes.imageIcon}
           src={image} alt="" // @todo
      />
    </Icon>
  );
}