import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ClaimCard from 'features/discuss/claims/components/ClaimCard';
import ClaimCardOpposing from 'features/discuss/claims/components/ClaimCardOpposing';
import ClaimCardSupporting from 'features/discuss/claims/components/ClaimCardSupporting';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

export default function(props) {
  const classes = useStyles();

  const mock = {
    "debateId" : "-LwIFKJe6vCQJpuky1lk",
    "id" : "-MAetRFVKc2WvUEnaJUm",
    "metadata" : {
      "createdBy" : "2IV1kmD6ccRTeSjCBv6mEFznjsu1",
      "createdDate" : 1593078824320
    },
    "positionId" : "-MAesxTEoPVl4IRGc2f5",
    "text" : "I use my god as a burglers alarm, once he helped me to catch some robbers!",
    "type" : "SUPPORTING"
  };

  return (
    <div className={classes.root}>

      <Container maxWidth="xs">

        <p>&nbsp;</p>
        <ClaimCard
          entity={mock}
        />
        <p>&nbsp;</p>

        <p>&nbsp;</p>

        <ClaimCardOpposing
          side="left"
          entity={mock}
        />
        <p>&nbsp;</p>
        <ClaimCardOpposing
          side="right"
          entity={mock}
        />

        <p>&nbsp;</p>

        <ClaimCardSupporting
          side="right"
          entity={mock}
        />

        <p>&nbsp;</p>




      </Container>

    </div>
  );
}
