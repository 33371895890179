import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';

// const defaultDimension = {
//   polarized: 50,
//   unclear: 50,
//   unpolarized: 50,
//   agree: 50,
// };

const initialAverage = {
  polarized: 0,
  unclear: 0,
  unpolarized: 0,
  agree: 0,
};

function dimensionAverages(values) {
  const len = _.size(values);
  if (len > 0) {
    const sums = _.reduce(values, (acc, curr) => ({
      polarized:   acc.polarized   + curr.polarized,
      unclear:     acc.unclear     + curr.unclear,
      unpolarized: acc.unpolarized + curr.unpolarized,
      agree:       acc.agree       + curr.agree,
    }), initialAverage);
    return {
      polarized:   sums.polarized   / len,
      unclear:     sums.unclear     / len,
      unpolarized: sums.unpolarized / len,
      agree:       sums.agree       / len,
    };
  }
  return initialAverage;
}

export const loadPositionDimension = createAsyncThunk(
  'positionDimensions/loadPositionDimension',
  async (position, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = position.debateId;
    const positionId = position.id;
    const dimensionsByUser = await api.getResource('positionDimensions', {
      relativePath: `${debateId}/${positionId}/users`,
    });
    const ownLevels = _.get(dimensionsByUser, `${ownerId}.levels`, null);
    const allLevels = _.map(dimensionsByUser, 'levels', []);
    return {
      dimension: ownLevels,
      averages: dimensionAverages(allLevels),
    };
  }
);

export const seedPositionDimensions = createAsyncThunk(
  'positionDimensions/seedPositionDimensions',
  async (debateId, thunkAPI) => {
    const dimensions = await api.getResource('positionDimensions', {
      relativePath: debateId,
    });
    return _.reduce(dimensions, (result, item, positionId) => {
      result[positionId] = {
        pending: false,
        error: null,
        votingUsers: _.size(item.users),
        data: null,
        averages: null,
      };
      return result;
    }, {});
  }
);

export const savePositionDimension = createAsyncThunk(
  'positionDimensions/savePositionDimension',
  async (newEntry, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = newEntry.debateId;
    const positionId = newEntry.positionId;
    return await api.addResource('positionDimensions', newEntry, {
      relativePath: `${debateId}/${positionId}/users/${ownerId}`,
      unique: true,
    });
  }
);

const initialState = {
  pending: false,
  error : null,
  byId: {
    //<positionId>: {
    //  pending:false,
    //  error : null,
    //  data: null,
    //}
  },
};

const slice = createSlice({
  name: 'positionDimensions',
  initialState,
  reducers: {
    resetPositionDimensions(state) {
      state.byId = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- seedPositionDimensions
    [seedPositionDimensions.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [seedPositionDimensions.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [seedPositionDimensions.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- loadPositionDimension
    [loadPositionDimension.pending]: (state, action) => {
      // console.log('loadPositionDimension.pending', action); // DEBUG
      state.error = null;
      const positionId = action.meta.arg.id;
      if (_.has(state.byId, positionId)) {
        state.byId[positionId].pending = true;
        state.byId[positionId].error = null;
      } else {
        state.byId[positionId] = {
          pending: true,
          error: null,
          votingUsers: 0,
          data: null,
        };
      }
    },
    [loadPositionDimension.fulfilled]: (state, action) => {
      // console.log('loadPositionDimension.fulfilled', action); // DEBUG
      const positionId = action.meta.arg.id;
      state.byId[positionId].pending = false;
      state.byId[positionId]['data'] = action.payload.dimension;
      state.byId[positionId]['averages'] = action.payload.averages;
    },
    [loadPositionDimension.rejected]: (state, action) => {
      // // console.log('loadPositionDimension.rejected', action); // DEBUG
      const positionId = action.meta.arg.id;
      state.byId[positionId].pending = false;
      state.byId[positionId]['error'] = action.error;
    },
    // ---------------------------------------------------- savePositionDimension
    [savePositionDimension.pending]: (state, action) => {
      // console.log('savePositionDimension.pending', action); // DEBUG
      const positionId = action.meta.arg.positionId;
      if (_.has(state.byId, positionId)) {
        state.byId[positionId].pending = true;
        state.byId[positionId].error = null;
      } else {
        state.byId[positionId] = {
          pending: true,
          error: null,
          votingUsers: 0,
          data: null,
        };
      }
    },
    [savePositionDimension.fulfilled]: (state, action) => {
      // console.log('savePositionDimension.fulfilled', action); // DEBUG
      const positionId = action.meta.arg.positionId;
      state.byId[positionId].pending = false;
      if (state.byId[positionId]['data'] === null) {
        state.byId[positionId].votingUsers++;
      }
      state.byId[positionId]['data'] = action.payload;
    },
    [savePositionDimension.rejected]: (state, action) => {
      // console.log('savepositionDimension.rejected', action); // DEBUG
      const positionId = action.meta.arg.positionId;
      state.byId[positionId].pending = false;
      state.byId[positionId]['error'] = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetPositionDimensions } = actions;
export default reducer;
