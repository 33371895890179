import React, { useCallback, useEffect } from 'react';
import { BrowserRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Collapse from '@material-ui/core/Collapse';
// import Slide from '@material-ui/core/Slide';

import LeftDrawer from 'features/layout/LeftDrawer';
import TopBar from 'features/layout/TopBar';
import MainSnackbar from 'features/layout/MainSnackbar';

import RouterOutlet from './RouterOutlet';
import { authChangedValue, authChangedError } from 'features/auth/auth.slice';
import { observeAuthentication } from 'api';
import bTheme from 'b.theme';

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
}));

function Root(props) {
  const {
    ready,
    // error,
    profile,
    onMount,
    onDismount
  } = props;
  const classes = useStyles();
  const theme = createMuiTheme(bTheme);

  const mount = useCallback(onMount, []);
  const dismount = useCallback(onDismount, []);
  useEffect(() => {
    mount();
    return () => dismount();
  }, [mount, dismount]);

  if (!ready) return null;

  // @todo how to track auth module error ?
  // if (Boolean(error)) {
  //   return <p>{error.message}</p>;
  // }

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <LeftDrawer />
        <TopBar authUser={profile} />
        <div className={classes.offset} />
        <main>
          <RouterOutlet />
        </main>
        <MainSnackbar />
        {/* @todo footer */}
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ready: state.auth.ready,
    profile: state.auth.profile,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onMount: () => {
      // const unsub =
      observeAuthentication(
        (user) => dispatch(authChangedValue(user)),
        (error) => dispatch(authChangedError(error))
      );
    },
    onDismount: () => {
      // @todo unsubscribe observeAuthentication
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);