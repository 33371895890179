import _ from 'lodash';

// todo standardize error
// @see https://www.npmjs.com/package/errors
// import { errors } from 'errors';
import { serializeError } from 'serialize-error';

import 'firebase/auth';
import 'firebase/database';
// import 'firebase/firestore';
import 'firebase/storage';

import firebase from 'app/firebase';


const auth = firebase.auth();
const db = firebase.database();
// const firestore = firebase.firestore();
const storage = firebase.storage();


// @todo move into utils.js
function serializeUser(fireUser) {
  return _.isNull(fireUser) ? null
    : _.pick(fireUser, [
      'uid', 'displayName',
      'email', 'emailVerified',
      'isAnonymous','phoneNumber', 'photoURL',
      // 'metadata' // @todo needs serialization
    ]);
}


//
// Auth
//

// @todo handle connection state
// @see https://firebase.google.com/docs/database/web/offline-capabilities

export function observeAuthentication(nextOrObserver, error, completed) {
  return auth.onAuthStateChanged(
    user => nextOrObserver(serializeUser(user)),
    err => error(serializeError(err)),
    completed
  );
}

export function createUserWithEmailAndPassword(newUser) {
  return auth.createUserWithEmailAndPassword(
    newUser.email,
    newUser.password
  ).then(res => {
    const uid = res.user.uid;
    const userData = _.omit(newUser, ['email', 'password']);
    return db.ref('users').child(uid).set(userData);
  });
}

/*

return db.ref(path).once('value')
    .then(snapshot => {
      if (!snapshot.exists()) {
        throw new Error(`Resource not found in '${path}'`);
      }
      return snapshot.val();
    });

*/

export function signInWithEmailAndPassword({ email, password }) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(user => serializeUser(user))
  ;
}

export function signOut() {
  return auth.signOut();
}


//
// Storage
//

export function uploadFile(resourceName, obj, opts) {
  const path = resourcePath(resourceName, opts);
  const resourceRef = storage.ref(path);
  return resourceRef
    .put(obj) // metadata HERE ??
    .then(snapshot => {
      return snapshot.ref.getDownloadURL();
    });
}


//
// Entity
//

/**
 * @param callback to attach
 * @return callback in input
 */
export function observeEntity(entityPath, callback) {
  let ignoreChange = true;
  // @see https://firebase.google.com/docs/reference/js/firebase.database.Reference#on
  return db.ref(entityPath).on('value',
    snapshot => {
      if (!ignoreChange) { // @todo or notify only with different owner
        callback();
      }
      ignoreChange = false;
    },
    error => {
      // @todo how to handle errors?
      //       which kind of errors?
    }
    // @todo what's the context?
  );
}

/**
 * @param callback to detach
 * */
export function unobserveEntity(entityPath, callback) {
  db.ref(entityPath).off('value', callback);
}


//
// Resource
//

function resourcePath(resourceName, opts) {
  if (_.has(opts, 'relativePath')) {
    resourceName += '/' + opts.relativePath;
  }
  if (_.has(opts, 'filename')) {
    resourceName += '/' + opts.filename;
  }
  return resourceName;
}

function appendMetadata(obj) {
  return {
    ...obj,
    metadata: {
      createdBy: auth.currentUser.uid,
      createdDate: Date.now(),
    },
  };
}

/**
 * @param opts
 *  - relativePath: string
 */
export function getResource(resourceName, opts) {
  const path = resourcePath(resourceName, opts);
  return db.ref(path).once('value')
    .then(snapshot => {
      if (!snapshot.exists()) {
        throw new Error(`Resource not found in '${path}'`);
      }
      return snapshot.val();
    });
}

/**
 * @param opts
 *  - relativePath: string
 *  - unique: boolean
 */
export function addResource(resourceName, obj, opts) {
  const path = resourcePath(resourceName, opts);
  const resourceRef = db.ref(path);
  const objWithMeta = appendMetadata(obj);
  if (_.has(opts, 'unique') && opts['unique']) {
    return resourceRef
      .set(objWithMeta)
      .then(() => resourceRef.once('value'))
      .then(snapshot => snapshot.val())
    ;
  }
  const objId = resourceRef.push().key;
  const objRef = resourceRef.child(objId);
  return objRef
    .update({
      ...objWithMeta,
      id: objId,
    })
    .then(() => objRef.once('value'))
    .then(snapshot => snapshot.val())
  ;
}

export function editResource(resourceName, values, opts) {
  const path = resourcePath(resourceName, opts);
  const resourceRef = db.ref(path);
  return resourceRef
    .update(values)
    .then(() => resourceRef.once('value'))
    .then(snapshot => snapshot.val())
  ;
}

export function deleteResource(resourceName, opts) {
  const path = resourcePath(resourceName, opts);
  return db.ref(path).remove();
  // return db.ref(path).set(null);
  // @todo return removed object? YES
}

/**
 * @param opts
 *  - relativePath: string
 *  - step: double|long default 1
 */
export function increase(resourceName, opts) {
  const step = _.get(opts, 'step', 1);
  // @todo check step > 0
  const value = firebase.database.ServerValue.increment(step);
  const path = resourcePath(resourceName, opts);
  const resourceRef = db.ref(path);
  return resourceRef.set(value)
    .then(() => resourceRef.once('value'))
    .then(snapshot => snapshot.val())
  ;
}

/**
 * @param opts
 *  - relativePath: string
 *  - step: double|long default -1
 */
export function decrease(resourceName, opts) {
  const step = _.get(opts, 'step', -1);
  // @todo check step < 0
  const value = firebase.database.ServerValue.increment(step);
  const path = resourcePath(resourceName, opts);
  const resourceRef = db.ref(path);
  return resourceRef.set(value)
    .then(() => resourceRef.once('value'))
    .then(snapshot => snapshot.val())
  ;
}


//
// User
//

export function getUser(uid) {
  return db.ref(`users/${uid}`).once('value')
    .then(snapshot => {
      if (!snapshot.exists()) {
        throw new Error(`User not found in '${uid}'`);
      }
      return {
        ...snapshot.val(),
        id: snapshot.key,
      };
    });
}
