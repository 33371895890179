import React from 'react';
import { Route } from 'react-router-dom';

import SignUp from './SignUp';
import SignIn from './SignIn';
import SignOut from './SignOut';

const baseUrl = '/auth';
export const       SIGNUP_ROUTE = `${baseUrl}/signup`;
export const       SIGNIN_ROUTE = `${baseUrl}/signin`;
export const      SIGNOUT_ROUTE = `${baseUrl}/signout`;
export const PWD_RECOVERY_ROUTE = `${baseUrl}/password-recovery`; // @todo

export default (
  <Route path={baseUrl}>
    <Route path={SIGNUP_ROUTE} exact component={SignUp} />
    <Route path={SIGNIN_ROUTE} exact component={SignIn} />
    <Route path={SIGNOUT_ROUTE} exact component={SignOut} />
  </Route>
);
