import React from 'react';
// import React, { useState } from 'react';
import clsx from 'clsx';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
// import Collapse from '@material-ui/core/Collapse';
// import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
// import Chip from '@material-ui/core/Chip';

import IssueIcon from 'icons/Issue';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // collapse: {
  //   // border: 'solid red 1px', // DEBUG
  //   margin: '0 auto',
  //   position: 'relative',
  //   width: '88%',
  //   zIndex: 1000, // temp
  // },
    title: {
      // border: 'solid red 1px', // DEBUG
      // fontFamily: '"EB Garamond", serif',
      // padding: '33px 0px',
      fontSize: 28,
      fontWeight: 'bold',
      // fontWeight: '300px',
      // textAlign: 'center',
      '& .MuiIcon-root': {
        display: 'inline-flex',
        position: 'relative', top: 3,
        marginRight: 5,
      },
    },
    // description: {
    //   boxSizing: 'border-box',
    //   padding: theme.spacing(1),
    //   // lineHeight: '22.4px',
    //   fontFamily: '"Lato", serif',
    //   fontWeight: '300px',
    //   fontSize: 16,
    //   // textAlign: 'center',
    //   // textSizeAjust: '100%',
    //   zIndex: 1000, // temp
    // },
}));

// const

function Issue(props) {
  const {
    className,
    entity,
  } = props;
  const classes = useStyles();

  // const [showContent, setShowContent] = useState(false);

  // @todo move in the proper func
  // const threshold = 270;
  // let exceed = false;
  // let description = '';
  // if (_.has(entity, 'text')) {
  //   description = entity.text;
  //   exceed = description.length > threshold;
  //   if (exceed && !showContent) {
  //     description = _.truncate(entity.text, {
  //       'length': threshold,
  //       'omission': ' ...',
  //     })
  //   }
  // }

  // return (
  //   <div className={clsx(classes.root, className)}>
  //     <Collapse
  //       className={classes.collapse}
  //       // className={clsx(classes.root, className)}
  //       in={showContent}
  //       collapsedHeight="95px"
  //     >
  //       <Paper elevation={showContent ? 10 : 0}>
  //         <div className={classes.title}>
  //           { entity ? entity.title : <Skeleton variant="text" width="30vw" /> }
  //         </div>
  //         <div className={classes.description}>
  //           {entity ? description : <Skeleton variant="rect" width="50vw" /> }
  //           {exceed && (
  //             <Button
  //               size="small"
  //               color="primary"
  //               onClick={() => { setShowContent(!showContent) }}
  //             >{showContent ? 'less' : 'more'}</Button>
  //           )}
  //         </div>
  //       </Paper>
  //     </Collapse>
  //   </div>
  // );

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.title}>
        <IssueIcon icolor="blue" />
        { entity ? entity.title : <Skeleton variant="text" width="30vw" /> }
      </div>
    </div>
  );
}

export default Issue;
