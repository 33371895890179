import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import EmojiSlider from 'components/EmojiSlider';
// import EmojiSliderSkeleton from 'components/EmojiSliderSkeleton';
import { saveClaimAgreement } from '../store/claimAgreements.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    padding: '40px 90px 50px 90px',
  },
}));

function ClaimAgreementCast(props) {
  const {
    className,
    pending,
    claim,
    agreement,
    // authUser,
    // onSlideCommitted,
    onSave,
  } = props;
  const classes = useStyles();
  const [level, setLevel] = useState(0);

  const handleSlide = (event, value) => {
    // console.log('CastClaimAgreement.handleSlide', value); // DEBUG
    event.preventDefault();
    setLevel(value);
  }

  const handleCastCommitted = (event, value) => {
    // console.log('CastClaimAgreement.handleCastCommitted', value); // DEBUG
    event.preventDefault();
    onSave({ level: value })
      // .then(res => {
      //   onSlideCommitted();
      // });
  };

  useEffect(() => {
    if (!pending && Boolean(agreement)) {
      setLevel(agreement);
    }
  }, [pending, agreement]);

  // console.log('ClaimAgreementCast', props); // DEBUG

  return (
  // return agreement && ( // DO NOT use this
    <div className={clsx(classes.root, className)}>
      <EmojiSlider
        name={`claim-agreement-${claim.id}`}
        value={level}
        onChange={handleSlide}
        onChangeCommitted={handleCastCommitted}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const claimId = ownProps.claim.id;
  const agreementsState = state.discuss.claimAgreements;
  return {
    pending: agreementsState.pending,
    error: agreementsState ? agreementsState.error : null,
    agreement: _.get(agreementsState, `byId[${claimId}].data.level`, null),
    // authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const claim = ownProps.claim;
  return {
    onSave: values => dispatch(saveClaimAgreement({
      ...values,
      debateId: claim.debateId,
      positionId: claim.positionId,
      claimId: claim.id,
    })),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimAgreementCast);
