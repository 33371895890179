import React, { useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { signOut } from './auth.slice';

function SignOut(props) {
  const { onMount } = props;

  const mount = useCallback(onMount, []);
  useEffect(() => {
    mount();
  }, [mount]);

  return <Redirect to="/home" />
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: () => dispatch(signOut()),
  }
};

export default connect(null, mapDispatchToProps)(SignOut);
