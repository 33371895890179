import React from 'react';
// import Moment from 'react-moment';
import Timer from '@xendora/react-timer';
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

const z = n => (n < 10 ? '0' : '') + n;
function secsToTime(seconds) {
  const mins = z((seconds % 3600) / 60 | 0);
  const secs = z(seconds % 60);
  return `${mins}:${secs}`;
}

function Time(props) {
  // const {
  // } = props;
  const classes = useStyles();
  return (
    // @todo
    <Timer
      start={0}
      end={() => false}
      // onEnd={value => alert('ENDED WITH VALUE', value)}
      onTick={value => value + 1}
    >
      {seconds => (
        <span className={classes.root}>
          {secsToTime(seconds)}
        </span>
      )}
    </Timer>
  );
}

Time.propTypes = {
  // ...: PropTypes.object.isRequired,
};

export default Time;
