import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import ClaimCard from './components/ClaimCard';
import ClaimCardOwned from './components/ClaimCardOwned';
// import ClaimCardOpposing from './components/ClaimCardOpposing';
// import ClaimCardSupporting from './components/ClaimCardSupporting';
// import ClaimCreate from './ClaimCreate';
import ClaimEdit from './ClaimEdit';
import ClaimDelete from './ClaimDelete';
import ClaimReply from './ClaimReply';
import ClaimParent from './ClaimParent';
import ClaimThumbRate from './ClaimThumbRate';
import ClaimHeartCast from './ClaimHeartCast';
import ClaimDimensionsCast from './ClaimDimensionsCast';
// import ClaimDimensionsCast from './ClaimDimensionsCast2';
import ParticipantLabel from '../participants/ParticipantLabel';

import { makeGetClaims } from '../store/discuss.selectors';
// import { fetchClaims } from '../store/claims.slice';
import { unfocusClaim } from '../store/claims.slice';

const borderSize = 3;
const formHeight = 40;

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `${borderSize}px solid rgba(191, 191, 191, 0.4)`,
    padding: '8px 10px',
    // '& > span': {
    //   borderRadius: '50%',
    //   width: 24, height: 24,
    //   backgroundColor: theme.palette.tertiary.main,
    //   color: theme.palette.tertiary.contrastText,
    // },
    // '& > :nth-child(2)': {
    //   backgroundColor: theme.palette.primary.main,
    //   color: theme.palette.primary.contrastText,
    // },
    fontWeight: 'bold',
    '& > span': {
      borderRadius: 10,
      padding: '2px 7px',
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
    },
    '& > :nth-child(2)': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  footer: {
    borderBottom: `${borderSize}px solid rgba(191, 191, 191, 0.4)`,
  },
  columns: {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: '0px 7px',
    '& > div': {
      width: '46%',
      alignSelf: 'flex-start',
    },
    '& > .left': {
      marginRight: 'calc(55%)',
    },
    '& > .right': {
      marginLeft: 'calc(55%)',
    },
  },
  form: {
    // moved into the component
    // borderRadius: '0 0 10px 10px', // @todo move in the theme???
    // backgroundColor: theme.palette.grey[300],
    // ---
    minHeight: `${formHeight}px`,
  },
  centered: { margin: '0 auto' },
  middleLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      borderLeft: `${borderSize}px solid rgba(191, 191, 191, 0.4)`,
      position: 'absolute', top: 0, bottom: 0,
      left: `calc(50% - ${borderSize - 2}px)`,
    },
  },
}));

// const getLastOwnedClaim = (claims, owner) => {
//   if (Boolean(owner)) {
//     const owned = _.filter(claims, obj => obj.metadata.createdBy === owner.uid);
//     const ordered = _.orderBy(owned, 'metadata.createdDate', 'asc');
//     return _.last(ordered);
//   }
//   return null;
// };

// @notes
// - arguments -> claims : because 'arguments' is a reserved word
function Claims(props) {
  const {
    position,
    claims,
    authUser,
    focusedId,
    onFocus,
    // onMount,
    // onDismount,
  } = props;
  const classes = useStyles();

  const mapCards = (items) => {
    return items &&
      items.map((item, index) => {
        // @todo trasform this in a function
        const ownerId = item.metadata.createdBy;
        const owned = Boolean(authUser) && ownerId === authUser.uid;
        // ---------------------------------
        const parentId = _.get(item, 'parentId', null);
        const side = item.type === 'OPPOSING' ? 'left' : 'right';
        const Component = owned ? ClaimCardOwned : ClaimCard;
        const dimensionsVariant = owned ? 'white' : 'colored';
        return (
          <Box className={side} key={index} mb={1}>
            <Component
              side={side}
              entity={item}
              owned={owned}
              focused={item.id === focusedId}
              onFocus={onFocus}
              owner={<ParticipantLabel userId={item.metadata.createdBy} />}
              parent={<ClaimParent parentId={parentId} />}
              // reflect={<ClaimDimensionsCast claim={item} />}
              // actionPanelLeft={<>
              //   <PositionCreateShortcut position={item} />
              //   <ClaimDimensionsCast claim={item} />
              // </>}
              actionPanelRight={<>
                <ClaimEdit claim={item} />
                <ClaimDelete claim={item} />
                {/* <ClaimReply position={position} parent={item} /> */}
                <ClaimThumbRate claim={item} />
                <ClaimHeartCast claim={item} />
                {/* <ClaimDimensionsCast claim={item} variant={dimensionsVariant} /> */}
              </>}
            />
          </Box>
        );
      });
  };

  // console.log('Claims', props); // DEBUG

  return (
    <div className={classes.root}>
      {!_.isEmpty(claims) && (
        <>
          <div className={clsx(classes.header, classes.middleLine)}>
            {/* <span><RemoveIcon /></span>
            <span><AddIcon /></span> */}
            <span>Cons</span>
            <span>Pros</span>
          </div>
          <div className={clsx(classes.columns, classes.middleLine)}>
            {mapCards(claims)}
          </div>
        </>
      )}
      <div className={classes.footer}></div>
      {/* <ClaimCreate
        className={classes.form}
        position={position}
        lastClaim={getLastOwnedClaim(claims, authUser)}
      /> */}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const claimsState = state.discuss.claims;
  const getClaims = makeGetClaims();
  return {
    position: ownProps.position,
    claims: getClaims(state, ownProps),
    focusedId: claimsState.focusedId,
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFocus: () => dispatch(unfocusClaim()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Claims);
