import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';

export const fetchClaims = createAsyncThunk(
  'claims/fetchClaims',
  async (debateId) => {
    const claimsByPosition = await api.getResource('claims', {
      relativePath: debateId,
    });
    const claimsById = _.reduce(claimsByPosition, (acc, curr) => _.assign(acc, curr), {});
    return claimsById;
  }
);

// export const fetchClaims = createAsyncThunk(
//   'claims/fetchClaims',
//   async (position) => {
//     const debateId = position.debateId;
//     const positionId = position.id;
//     return await api.getResource('claims', {
//       relativePath: `${debateId}/${positionId}`,
//     });
//   }
// );

export const addClaim = createAsyncThunk(
  'claims/addClaim',
  async (newEntry) => {
    const debateId = newEntry.debateId;
    const positionId = newEntry.positionId;
    return await api.addResource('claims', newEntry, {
      relativePath: `${debateId}/${positionId}`,
    });
  }
);

export const editClaim = createAsyncThunk(
  'claims/editClaim',
  async (values) => {
    // @todo check if id exists OR pass id resource as a param
    const debateId = values.debateId;
    const positionId = values.positionId;
    const claimId = values.id;
    return await api.editResource('claims', values, {
      relativePath: `${debateId}/${positionId}/${claimId}`,
    });
  }
);

export const deleteClaim = createAsyncThunk(
  'claims/deleteClaim',
  async (entity) => {
    // @todo check if id exists OR pass id resource as a param
    const debateId = entity.debateId;
    const positionId = entity.positionId;
    const claimId = entity.id;
    await api.deleteResource('claims', {
      relativePath: `${debateId}/${positionId}/${claimId}`,
    });
    return entity;
  }
);

export const saveClaimMedia = createAsyncThunk(
  'claims/saveClaimMedia',
  async ({media, claim}) => {
    // console.log('saveClaimMedia', entity); // DEBUG
    const debateId = claim.debateId;
    // const positionId = entity.positionId;
    const claimId = claim.id;
    const ext = media.type.split('/').pop();
    return await api.uploadFile('debates', media, {
      relativePath: `${debateId}/media`,
      filename: `${claimId}.${ext}`,
    })
      .then(url => ({ ...claim, media: url }));
  }
);

const initialState = {
  pending: false,
  error: null,
  byId: {
    // <claimId>: { ... }
  },
  byPosition: {
    // <positionId>: [ claim ids ... ]
  },
  focusedId: null,
  focusedFormId: null,
};

const slice = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    focusClaim(state, action) {
      state.focusedId = action.payload;
    },
    unfocusClaim(state) {
      state.focusedId = null;
    },
    focusClaimCreate(state, action) {
      state.focusedFormId = action.payload;
    },
    unfocusClaimCreate(state) {
      state.focusedFormId = null;
    },
    resetClaims(state) {
      state.byId = {};
      state.byPosition = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchClaims
    [fetchClaims.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [fetchClaims.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
      _.each(action.payload, curr => {
        const positionId = curr.positionId;
        if (!_.has(state.byPosition, positionId)) {
          state.byPosition[positionId] = [];
        }
        state.byPosition[positionId].push(curr.id);
      });

    },
    [fetchClaims.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- addClaim
    [addClaim.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [addClaim.fulfilled]: (state, action) => {
      state.pending = false;
      const positionId = action.payload.positionId;
      const claimId = action.payload.id;
      state.byId[claimId] = action.payload;
      if (!_.has(state.byPosition, positionId)) {
        state.byPosition[positionId] = [];
      }
      state.byPosition[positionId].push(claimId);
    },
    [addClaim.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- editClaim
    [editClaim.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [editClaim.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    [editClaim.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- deletePositionMedia
    [deleteClaim.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [deleteClaim.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = _.omit(state.byId, action.payload.id);
    },
    [deleteClaim.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- saveClaimMedia

  },
});

const { actions, reducer } = slice;
export const {
  focusClaim, unfocusClaim,
  focusClaimCreate, unfocusClaimCreate,
  resetClaims
} = actions;
export default reducer;
