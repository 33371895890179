import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Recorder from 'components/recorder';

const useStyles = makeStyles(theme => ({
  root: {
  },
    recorder: {
      border: '1px solid black',
      margin: 20,
    },
    player: {

    },
}));

export default function(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Recorder
        className={classes.recorder}
        onStop={() => { alert('onStop: playing on the sand') }}
        onDone={() => { alert('onDone: playing on the sand') }}
      />

      <audio controls>
        <source src={null} type="audio/ogg" />
        <source src={null} type="audio/mpeg" />
      </audio>

    </div>
  );
}
