import React from 'react';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ForumIcon from '@material-ui/icons/Forum';

import InboxIcon from '@material-ui/icons/Inbox';

import { NavHeaderMockUp } from '@mui-treasury/mockup/layout';

import { DEBATES_ROUTE } from 'features/discuss/discuss.routes';

export default function LeftDrawerList() {

  const item = (route, text, icon='') => (
    <ListItem button component={Link} to={route}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );

  return (
    <>
      <NavHeaderMockUp collapsed={false} />
      <List>
        {item('/',        'Home',    <HomeIcon />)}
        {item(DEBATES_ROUTE, 'Discuss', <ForumIcon />)}

        {item('/sandbox', 'Sandbox', <InboxIcon />)}

      </List>
      <Divider />

    </>
  );

}
