import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Icon from '@material-ui/core/Icon';

import FullyDisagreeImage from 'assets/icons/emojis/fully-disagree.svg';
import SlightlyDisagreeImage from 'assets/icons/emojis/slightly-disagree.svg';
import NeutralImage from 'assets/icons/emojis/neutral.svg';
import SlightlyAgreeImage from 'assets/icons/emojis/slightly-agree.svg';
import FirmlyAgreeImage from 'assets/icons/emojis/firmly-agree.svg';
import Moment from 'react-moment';

const triangleSide = 10;

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  timestamp: {
    // border: 'solid blue 1px', // DEBUG
    position: 'absolute', top: '-58px',
    width: '155px',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
  },
  cloud: {
    position: 'absolute', top: '-40px',
    border: `solid 2px ${theme.palette.primary.main}`,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: '155px',
    textAlign: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: `calc(50% - ${triangleSide}px)`,
      top: 'calc(100% + 2px)',
      borderTop: `solid ${triangleSide}px`,
      borderRight: `solid ${triangleSide}px transparent`,
      borderLeft: `solid ${triangleSide}px transparent`,
      borderBottom: `solid ${triangleSide}px transparent`,
    },
  },
}));

const StyledSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 50,
    width: 50,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -20,
    marginLeft: -25,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

// @todo move to utils.js
function between(x, min, max) {
  return x >= min && x <= max;
}

const selectEmojiSvg = (value) => {
  if (between(value, -100, -51)) {
    return FullyDisagreeImage;
  } else if (between(value, -50, -1)) {
    return SlightlyDisagreeImage;
  } else if (between(value, 1, 50)) {
    return SlightlyAgreeImage;
  } else if (between(value, 51, 1000)) {
    return FirmlyAgreeImage;
  } else {
    return NeutralImage;
  }
};

const getEmoji = (value) => {
  const css = { display: 'flex', height: 'inherit', width: 'inherit' };
  return (
    <Icon>
      <img style={css}
           src={selectEmojiSvg(value)}
           alt={selectText(value)}
      />
    </Icon>
  );
};

// const selectText = (value, pastTense) => {
//   let msg = '';
//   if (between(value, -100, -67)) {
//     msg = "I strongly disagree";
//   } else if (between(value, -66, -34)) {
//     msg = "I disagree";
//   } else if (between(value, -33, -1)) {
//     msg = "I somewhat disagree";
//   } else if (between(value, 1, 33)) {
//     msg = "I somewhat agree";
//   } else if (between(value, 34, 66)) {
//     msg = "I agree";
//   } else if (between(value, 67, 100)) {
//     msg = "I strongly agree";
//   } else {
//     return pastTense ? "I was neutral" : "I'm neutral";
//   }
//   if (pastTense) { msg += 'd' }
//   return msg;
// };

const selectText = (value, pastTense) => {
  if (between(value, -100, -67)) {
    return pastTense ? "I strongly disagreed" : "I strongly disagree";
  } else if (between(value, -66, -34)) {
    return pastTense ? "I disagreed" : "I disagree";
  } else if (between(value, -33, -1)) {
    return pastTense ? "I somewhat disagreed" : "I somewhat disagree";
  } else if (between(value, 1, 33)) {
    return pastTense ? "I somewhat agreed" : "I somewhat agree";
  } else if (between(value, 34, 66)) {
    return pastTense ? "I agreed" : "I agree";
  } else if (between(value, 67, 100)) {
    return pastTense ? "I strongly agreed" : "I strongly agree";
  } else {
    return pastTense ? "I was neutral" : "I'm neutral";
  }
};

function EmojiSlider(props) {
  const {
    className,
    pastTense,
    timestamp,
    ...other
  } = props;
  const classes = useStyles();

  const ThumbComponent = (props) => {
    const val = props['aria-valuenow'];
    return (
      <span {...props}>
        {pastTense && timestamp && <>
          <span className={classes.timestamp}>
            <Moment format="YYYY/MM/DD" date={timestamp} />
          </span>
        </>}
        <span className={classes.cloud}>{selectText(val, pastTense)}</span>
        {getEmoji(val)}
      </span>
    );
  };

  // console.log('CastPositionAgreement', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)}>
      <StyledSlider
        valueLabelDisplay="auto"
        min={-100}
        max={100}
        track={false}
        ThumbComponent={ThumbComponent}
        {...other}
      />
    </div>
  );
}

// EmojiSlider.propTypes = {
//   : PropTypes.func.isRequired,
// };

EmojiSlider.defaultProps = {
  level: 0,
};

export default EmojiSlider;
