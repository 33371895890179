import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';

import * as api from 'api';


export const fetchDebates = createAsyncThunk(
  'debates/fetchDebates',
  async () => {
    return await api.getResource('debates');
  }
);

export const fetchDebate = createAsyncThunk(
  'debates/fetchDebate',
  async (debateId) => {
    return await api.getResource('debates', {
      relativePath: debateId,
    });
  }
);

// function shouldCallAPI(state, id) {
//   return !_.has(state.debates.byId, id);
// }

// export const _selectDebate = createAsyncThunk(
//   'debates/selectDebate',
//   async (debateId, { dispatch, getState }) => {
//     const state = getState();
//     if (shouldCallAPI(state, debateId)) {
//       const res = await dispatch(fetchDebate(debateId));
//       if (_.has(res, 'error')) {
//         throw new Error(res.error.message);
//       }
//     }
//     return debateId;
//   }, {
//     condition: (debateId, { getState }) => {
//       const { debates } = getState();
//       return debates.current !== debateId;
//     },
//   },
// );

export const addDebate = createAsyncThunk(
  'debates/addDebate',
  async (newEntry) => {
    return await api.addResource('debates', newEntry);
  }
);

export const editDebate = createAsyncThunk(
  'debates/editDebate',
  async (values) => {
    // @todo check if id exists OR pass id resource as a param
    const debateId = values.id;
    return await api.editResource('debates', values, {
      relativePath: debateId,
    });
  }
);

export const deleteDebate = createAsyncThunk(
  'debates/deleteDebate',
  async (entity) => {
    // @todo check if id exists OR pass id resource as a param
    const debateId = entity.id;
    await api.deleteResource('debates', {
      relativePath: `${debateId}`,
    });
    return entity;
  }
);

export const saveDebateImage = createAsyncThunk(
  'debates/saveDebateImage',
  async ({image, debate}) => {
    const debateId = debate.id;
    const ext = image.type.split('/').pop();
    return await api.uploadFile('debates', image, {
      relativePath: debateId,
      filename: `image.${ext}`,
    })
      .then(url => ({...debate, image: url }));
  }
);

const slice = createSlice({
  name: 'debates',
  initialState: {
    pending: false,
    error: null,
    current: null,
    byId: {},
  },
  reducers: {
    selectDebate(state, action) {
      state.current = action.payload;
    },
    unselectDebate(state) {
      state.current = null;
    },
    resetDebateError(state) {
      state.error = null;
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchDebates
    [fetchDebates.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [fetchDebates.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [fetchDebates.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- fetchDebate
    [fetchDebate.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [fetchDebate.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    [fetchDebate.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- addDebate
    [addDebate.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [addDebate.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    [addDebate.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- editDebate
    [editDebate.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [editDebate.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    [editDebate.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },


    // ---------------------------------------------------- saveDebateImage
    // [saveDebateImage.fulfilled]: (state, action) => {
    //   state.pending = false;
    //   state.byId[ action.payload.id ] = action.payload;
    // },
  },
});

const { actions, reducer } = slice;
export const { selectDebate, unselectDebate, resetDebateError } = actions;
export default reducer;
