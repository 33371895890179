import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { togglePositionCard } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  expand: {
    // marginLeft: 'auto',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function CollapseButton(props) {
  const {
    // positionId,
    expanded,
    onClick,
  } = props;
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      size="small"
      color="inherit"
      aria-label="show arguments"
      aria-expanded={expanded}
      onClick={onClick}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
}

const mapStateToProps = (state, ownProps) => {
  const positionId = ownProps.positionId;
  const positionCards = state.discuss.layout.positionCards;
  return {
    expanded: !_.get(positionCards, `['${positionId}'].collapsed`, false),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const positionId = ownProps.positionId;
  return {
    onClick: () => dispatch(togglePositionCard(positionId)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollapseButton);
