import React from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { closeSnackbar } from 'features/layout/layout.slice';

const defaultDuration = 3000; // ms

function MainSnackbar(props) {
  const {
    open,
    severity,
    message,
    onClose,
  } = props;

  const handleClose = (event, reason) => {
    // event.preventDefault(); // @todo why doesn't work?
    if (reason === 'clickaway') return;
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={defaultDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = (state) => {
  const snackbarState = state.layout.snackbar;
  return {
    open: snackbarState.open,
    severity: snackbarState.severity,
    message: snackbarState.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(closeSnackbar()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSnackbar);
