import React from 'react';

//import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// @todo improve
export default function NotificationSnack(props) {
  //const classes = useStyles();
  //const { open, onClick } = props;

  return (
    <Snackbar {...props}>
      {/* @todo */}
      {/* icon={<CheckIcon fontSize="inherit" />}   onClose={handleClose}  */}
      <Alert severity="info" variant="filled" elevation={6}>
        Reload
      </Alert>
    </Snackbar>
  );
}
