import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';


export const fetchClaimHearts = createAsyncThunk(
  'claimHearts/fetchClaimHearts',
  async (debateId, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const heartsById = await api.getResource('claimHearts', {
      relativePath: debateId,
    });
    const collection = {};
    _.each(heartsById, position => {
      _.each(position, (item, key) => {
        item.value = _.get(item, `users[${ownerId}].value`, 0);
        delete item.users;
        collection[key] = item;
      });
    });
    return collection;
  }
);

export const giveHeart = createAsyncThunk(
  'claimHearts/giveHeart',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;
    const claimId = props.claimId;

    const resourceName = 'claimHearts';
    const basePath = `${debateId}/${positionId}/${claimId}`;
    const entry = { ...props, value: 1 };

    const addVote = api.addResource(resourceName, entry, {
      relativePath: `${basePath}/users/${ownerId}`,
      unique: true,
    });

    const updateCounter = api.increase(resourceName, {
      relativePath: `${basePath}/total`,
    });

    return await Promise
      .all([addVote, updateCounter])
      .then(([give, total]) => ({ ...give, total }));
  }
);

export const ungiveHeart = createAsyncThunk(
  'claimHearts/ungiveHeart',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;
    const claimId = props.claimId;

    const resourceName = 'claimHearts';
    const basePath = `${debateId}/${positionId}/${claimId}`;

    const deleteVote = api.deleteResource(resourceName, {
      relativePath: `${basePath}/users/${ownerId}`,
    });
    const updateCounter = api.decrease(resourceName, {
      relativePath: `${basePath}/total`,
    });

    return await Promise
      .all([deleteVote, updateCounter])
      .then(([give, total]) => ({ ...give, total }));
  }
);

const slice = createSlice({
  name: 'claimHearts',
  initialState: {
    pending:false,
    error : null,
    byId: {
      //'claimId': {
      //  value: 0,
      //  total: 0,
      //}
    },
  },
  reducers: {
    resetClaimHearts(state) {
      state.byId = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchClaimHearts
    [fetchClaimHearts.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [fetchClaimHearts.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [fetchClaimHearts.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- giveHeart
    [giveHeart.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [giveHeart.fulfilled]: (state, action) => {
      const claimId = action.meta.arg.claimId;
      state.pending = false;
      if (_.isUndefined(state.byId[claimId])) {
        state.byId[claimId] = {};
      }
      state.byId[claimId]['value'] = action.payload.value;
      state.byId[claimId]['total'] = action.payload.total;
    },
    [giveHeart.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- ungiveHeart
    [ungiveHeart.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [ungiveHeart.fulfilled]: (state, action) => {
      const claimId = action.meta.arg.claimId;
      state.pending = false;
      state.byId[claimId] = {
        value: 0,
        total: action.payload.total,
      };
    },
    [ungiveHeart.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetClaimHearts } = actions;
export default reducer;
