import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import { focusPosition } from '../store/positions.slice';
import { focusClaim } from '../store/claims.slice';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    height: '100%',
  },
    title: {
      '& h3': {
        color: theme.palette.primary.main,
      },
    },
    content: {
      // width: 300,
      flex: '1 1 auto',
      overflow: 'auto',
    },
  lemma: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function Summary(props) {
  const {
    // @todo,
    chunks,
    onClick,
  } = props;
  const classes = useStyles();

  // const entity1 = {
  //   id: '-MOFSkOV0KX5gCOegyxz',
  //   type: 'POSITION',
  //   text: "The West should engage with Putin on a transactional basis. The West should appeal to Putin and Russia's self-interest.",
  // };

  // const entity2 = {
  //   id: '-MOFT6AnE43E-hsLL3iU',
  //   type: 'SUPPORTING',
  //   text: "A careful study of Russia's history since the collapse of the Soviet Union shows that the West is not without fault, taking advantage of Russia economically and breaking its word when it came to the expansion of NATO and the European Union. The West has consistently missed opportunities to bring Russia into the fold normalising its participation in multilateral institutions and with it the behaviour of its leaders.",
  // };

  // return (
  //   <div className={classes.root}>
  //     <h3>Summary</h3>
  //     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
  //     standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //     make a type specimen book. It has survived not only five centuries, but also the leap into electronic
  //     typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
  //     sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
  //     including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.

  //     &nbsp;
  //     <Link
  //       className={classes.lemma}
  //       onClick={() => onClick(entity1)} // @todo write in the better way
  //     >{entity1.text}</Link>
  //     &nbsp;
  //     {/* <br /> */}

  //     Lorem Ipsum has been the industry's standard dummy text ever since the
  //     1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
  //     It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
  //     unchanged.

  //     &nbsp;
  //     <Link
  //       className={classes.lemma}
  //       onClick={() => onClick(entity2)} // @todo write in the better way
  //     >{entity2.text}</Link>
  //     &nbsp;

  //     It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
  //     and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
  //   </div>
  // );

  const handleClick = chunk => (event) => {
    alert('todo');
    // onClick(chunk);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h3>Summary</h3>
      </div>
      <div className={classes.content}>
        {chunks.map((item, index) => {
          // @todo check if
          return (<>
            <Link
              key={index}
              className={classes.lemma}
              onClick={handleClick(item)}
            >{item['chunk-text']}</Link>
          </>)
        })}

      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const summaryState = state.discuss.summary;
  return {
    pending: summaryState.pending,
    chunks: _.get(summaryState, 'chunks', []),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: (chunk) => {
      // switch(entity.type) {
      //   case 'POSITION':
      //     dispatch(focusPosition(entity.id));
      //     break;
      //   case 'OPPOSING':
      //   case 'SUPPORTING':
      //     dispatch(focusClaim(entity.id))
      //     break;
      //   default:
      //     console.log('Summary.onHighlight', 'error'); // @todo
      // }
    },
    // onMount: (position) => {
    // },
    // onDismount: (position) => {
    // }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
