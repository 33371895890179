import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';

import Quote from 'components/Quote';
import ParticipantLabel from '../participants/ParticipantLabel';
// import { loadPositionParent } from '../store/claims.slice';
import { focusPosition } from '../store/positions.slice';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'black', // temp
  },
}));

function PositionParent(props) {
  const {
    className,
    loading,
    parent,
    onClickParent,
    // onMount,
    // onDismount,
  } = props;
  const classes = useStyles();

  const handleClick = id => (event) => {
    event.preventDefault();
    onClickParent(id);
  };

  // const mount = useCallback(onMount, []);
  // const dismount = useCallback(onDismount, []);
  // useEffect(() => {
  //   mount();
  //   return () => dismount();
  // }, [mount, dismount]);

  // console.log('PositionParent', props); // DEBUG

  if (_.isNull(parent)) return null;
  if (loading) return null;
  // if (loading) {
  //   return (
  //     <div className={classes.spinner}>
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    // @todo <CircularProgress />
    <div className={clsx(classes.root, className)}>
      <Quote
        entity={parent}
        owner={<ParticipantLabel userId={parent.metadata.createdBy} />}
        // @todo click "offable"
        onClick={handleClick(parent.id)}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const positionsState = state.discuss.positions;
  // const parentId = _.get(ownProps, 'parent.id', null);
  // @todo use a selector?
  return {
    // loading: _.get(claimsState, `byId['${ownProps.replyId}'].pending`, true), // HERE selector
    // parent: _.get(positionsState, `byId['${parentId}']`, null), // HERE selector
    parent: _.get(positionsState, `byId['${ownProps.parentId}']`, null), // HERE selector
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClickParent: id => dispatch(focusPosition(id)),
    // onMount: () => {
    //   // dispatch(loadPositionParent(ownProps.claimId));
    // },
    // onReload: () => {
    // },
    // onDismount: () => {
    // },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionParent);
