import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import FullyDisagreeImage from 'assets/icons/emojis/fully-disagree.svg';
import SlightlyDisagreeImage from 'assets/icons/emojis/slightly-disagree.svg';
import NeutralImage from 'assets/icons/emojis/neutral.svg';
import SlightlyAgreeImage from 'assets/icons/emojis/slightly-agree.svg';
import FirmlyAgreeImage from 'assets/icons/emojis/firmly-agree.svg';

const triangleSide = 10;

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    color: 'rgb(0, 73, 115)',
    width: '100%',
    display: 'inline-block',
    padding: '33px 0',
    position: 'relative',
    boxSizing: 'content-box',
    //
    height: '8px',
  },
  rail: {
    width: '100%',
    display: 'block',
    opacity: '0.38',
    position: 'absolute',
    backgroundColor: 'currentColor',
    //
    height: '8px',
    borderRadius: '4px',
  },
  thumb: {
    display: 'flex',
    outline: 0,
    position: 'absolute', left: '50%',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
  },
  me: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: '50px',
    height: '50px',
    marginTop: '-20px',
    marginLeft: '-25px',
    backgroundColor: '#fff',
  },
  community: {
    border: `2px solid ${theme.palette.secondary.main}`,
    width: '34px',
    height: '34px',
    marginTop: '-12px',
    marginLeft: '-17px',
    backgroundColor: '#fff',
  },
  cloudUp: {
    position: 'absolute',
    top: '-57px',
    border: `solid 2px ${theme.palette.primary.main}`,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: '150px',
    textAlign: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: `calc(50% - ${triangleSide}px)`,
      top: 'calc(100% + 2px)',
      borderTop: `solid ${triangleSide}px`,
      borderRight: `solid ${triangleSide}px transparent`,
      borderLeft: `solid ${triangleSide}px transparent`,
      borderBottom: `solid ${triangleSide}px transparent`,
    },
  },
  cloudDown: {
    position: 'absolute',
    bottom: '-57px',
    border: `solid 2px ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: '150px',
    textAlign: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: `calc(50% - ${triangleSide}px)`,
      bottom: 'calc(100% + 2px)',
      borderTop: `solid ${triangleSide}px transparent`,
      borderRight: `solid ${triangleSide}px transparent`,
      borderLeft: `solid ${triangleSide}px transparent`,
      borderBottom: `solid ${triangleSide}px `,
    },
  },
}));

// @todo move to utils.js
function between(x, min, max) {
  return x >= min && x <= max;
}

const selectEmojiSvg = (value) => {
  if (between(value, -100, -51)) {
    return FullyDisagreeImage;
  } else if (between(value, -50, -1)) {
    return SlightlyDisagreeImage;
  } else if (between(value, 1, 50)) {
    return SlightlyAgreeImage;
  } else if (between(value, 51, 1000)) {
    return FirmlyAgreeImage;
  } else {
    return NeutralImage;
  }
};

const getEmoji = (value) => {
  const css = { display: 'flex', height: 'inherit', width: 'inherit' };
  return (
    <Icon>
      <img style={css}
           src={selectEmojiSvg(value)}
           alt={selectText(value)}
      />
    </Icon>
  );
};

const selectText = (value, pastTense, secondPerson) => {

  if (secondPerson) {
    if (between(value, -100, -67)) {
    return pastTense ? "You strongly disagreed" : "You strongly disagree";
  } else if (between(value, -66, -34)) {
    return pastTense ? "You disagreed" : "You disagree";
  } else if (between(value, -33, -1)) {
    return pastTense ? "You somewhat disagreed" : "You somewhat disagree";
  } else if (between(value, 1, 33)) {
    return pastTense ? "You somewhat agreed" : "You somewhat agree";
  } else if (between(value, 34, 66)) {
    return pastTense ? "You agreed" : "You agree";
  } else if (between(value, 67, 100)) {
    return pastTense ? "You strongly agreed" : "You strongly agree";
  } else {
    return pastTense ? "You were neutral" : "You are neutral";
  }

  }


  if (between(value, -100, -67)) {
    return pastTense ? "I strongly disagreed" : "I strongly disagree";
  } else if (between(value, -66, -34)) {
    return pastTense ? "I disagreed" : "I disagree";
  } else if (between(value, -33, -1)) {
    return pastTense ? "I somewhat disagreed" : "I somewhat disagree";
  } else if (between(value, 1, 33)) {
    return pastTense ? "I somewhat agreed" : "I somewhat agree";
  } else if (between(value, 34, 66)) {
    return pastTense ? "I agreed" : "I agree";
  } else if (between(value, 67, 100)) {
    return pastTense ? "I strongly agreed" : "I strongly agree";
  } else {
    return pastTense ? "I was neutral" : "I'm neutral";
  }
};

function EmojiViewer(props) {
  const {
    className,
    value,
    average,
    pastTense,
    secondPerson,
    noAverage,
    // ...other
  } = props;
  const classes = useStyles();

  // console.log('EmojiViewer', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)}>
      <span className={classes.rail}>&nbsp;</span>

      <span className={clsx(classes.thumb, classes.me)}
        style={{left: `calc(50% + ${value / 2}%`}}
      >
        {/* @todo fix cloudUp css when noAverage */}
        <span className={classes.cloudUp}>
          {!noAverage && <>
            Your view<br />
          </>}
          {selectText(value, pastTense, secondPerson)}
        </span>
        {getEmoji(value)}
      </span>

      {!noAverage &&
        <span className={clsx(classes.thumb, classes.community)}
          style={{left: `calc(50% + ${average / 2}%`}}
        >
          <span className={classes.cloudDown}>
            Group Average View<br />
            {selectText(average, pastTense, secondPerson)}
          </span>
          {getEmoji(average)}
        </span>
      }

    </div>
  );
}

// EmojiViewer.propTypes = {
//   : PropTypes.func.isRequired,
// };

EmojiViewer.defaultProps = {
  value: 0,
  average: 0,
};

export default EmojiViewer;
