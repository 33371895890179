import React, { useState } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import SignInOrSignup from 'features/auth/SignInOrSignup';
import HeartFeedback from 'components/HeartFeedback';
import { giveHeart, ungiveHeart } from '../store/claimHearts.slice';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

function ClaimHeartCast(props) {
  const {
    // className,
    claim,
    pending,
    // error,
    value,
    total,
    authUser,
    onToggleUp,
    onToggleDown,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  // @todo trasform this in a function
  const ownerId = claim.metadata.createdBy;
  const owned = Boolean(authUser) && ownerId === authUser.uid;
  // ---------------------------------

  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };

  const handleToggle = (event) => {
    // event.preventDefault();
    if (pending) return;
    if (!Boolean(authUser)) { setOpenDialog(true); return; }
    value > 0 ? onToggleUp() : onToggleDown();
  };

  return (
    <div className={classes.root}>
      <HeartFeedback
        {...props}
        showLabel={owned}
        disabled={owned}
        onToggle={handleToggle}
      />
      <SignInOrSignup
        openDialog={openDialog}
        onCloseDialog={handleCloseDialog}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const claimId = ownProps.claim.id;
  const heartsState = state.discuss.claimHearts;
  return {
    pending: heartsState.pending,
    error: heartsState.error,
    value: _.get(heartsState, `byId[${claimId}].value`, 0),
    total: _.get(heartsState, `byId[${claimId}].total`, 0),
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const claim = ownProps.claim;
  const newEntry = {
    debateId: claim.debateId,
    positionId: claim.positionId,
    claimId: claim.id,
  };
  return {
    onToggleDown: () => {
      const res = dispatch(giveHeart(newEntry));
      // console.log('onToggleDown', res); // DEBUG
      return res;
    },
    onToggleUp: () => {
      const res = dispatch(ungiveHeart(newEntry));
      // console.log('onToggleUp', res); // DEBUG
      return res;
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimHeartCast);
