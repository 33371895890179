import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Skeleton from '@material-ui/lab/Skeleton';

import { getDebateRoute } from 'features/discuss/discuss.routes';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  media: {
    height: 140,
  },
  content: {
    height: 100,
  },
});

function DebateCard(props) {
  const { entity } = props;
  const classes = useStyles();
  const history = useHistory();

  const debateRoute = getDebateRoute(entity.id);

  const handleOpen = (event) => {
    event.preventDefault();
    history.push(debateRoute);
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleOpen}>
        { entity.image
          ? <CardMedia
              className={classes.media}
              image={entity.image}
              title={entity.title}
              />
          : <Skeleton className={classes.media} variant="rect" animation={false} />
        }
        <CardContent className={classes.content} onClick={handleOpen}>
          <Typography
            gutterBottom variant="h5" component="h2"
           >{_.truncate(entity.title, {length: 22})}</Typography>
          <Typography
            variant="body2" color="textSecondary" component="p"
           >{_.truncate(entity.text, {length: 115})}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <Button
          component={Link}
          to={debateRoute}
          style={{marginLeft: 'auto'}}
          color="primary" size="small"
          >Open</Button>
      </CardActions>
    </Card>
  );
}

DebateCard.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default DebateCard;