import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';

// const defaultDimension = {
//   polarized: 50,
//   unclear: 50,
//   evidences: 50,
//   agree: 50,
// };

const initialAverage = {
  polarized: 0,
  unclear: 0,
  evidences: 0,
  agree: 0,
};

function dimensionAverages(values) {
  const len = _.size(values);
  if (len > 0) {
    const sums = _.reduce(values, (acc, curr) => ({
      polarized:   acc.polarized   + curr.polarized,
      unclear:     acc.unclear     + curr.unclear,
      evidences: acc.evidences + curr.evidences,
      agree:       acc.agree       + curr.agree,
    }), initialAverage);
    return {
      polarized: sums.polarized / len,
      unclear:   sums.unclear   / len,
      evidences: sums.evidences / len,
      agree:     sums.agree     / len,
    };
  }
  return initialAverage;
}

export const loadClaimDimension = createAsyncThunk(
  'claimDimensions/loadClaimDimension',
  async (claim, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = claim.debateId;
    const positionId = claim.positionId;
    const claimId = claim.id;

    const dimensionsByUser = await api.getResource('claimDimensions', {
      relativePath: `${debateId}/${positionId}/${claimId}/users`,
    });
    const ownLevels = _.get(dimensionsByUser, `${ownerId}.levels`, null);
    const allLevels = _.map(dimensionsByUser, 'levels', []);
    return {
      dimension: ownLevels,
      averages: dimensionAverages(allLevels),
    };
  }
);

export const seedClaimDimensions = createAsyncThunk(
  'claimDimensions/seedClaimDimensions',
  async (debateId, thunkAPI) => {
    const dimensionsByPosition = await api.getResource('claimDimensions', {
      relativePath: `${debateId}`,
    });
    const dimensions = _.reduce(dimensionsByPosition, (result, item, positionId) => {
      result[positionId] = item;
      return result;
    }, {});
    return _.reduce(dimensions, (result, item, claimId) => {
      result[claimId] = {
        pending: false,
        error: null,
        votingUsers: _.size(item.users),
        data: null,
        averages: null,
      };
      return result;
    }, {});
  }
);

export const saveClaimDimension = createAsyncThunk(
  'claimDimensions/saveClaimDimension',
  async (newEntry, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = newEntry.debateId;
    const positionId = newEntry.positionId;
    const claimId = newEntry.claimId;
    return await api.addResource('claimDimensions', newEntry, {
      relativePath: `${debateId}/${positionId}/${claimId}/users/${ownerId}`,
      unique: true,
    });
  }
);

const initialState = {
  pending: false,
  error : null,
  byId: {
    //<claimId>: {
    //  pending:false,
    //  error : null,
    //  data: null,
    //}
  },
};

const slice = createSlice({
  name: 'claimDimensions',
  initialState,
  reducers: {
    resetClaimDimensions(state) {
      state.byId = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- seedClaimDimensions
    [seedClaimDimensions.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [seedClaimDimensions.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [seedClaimDimensions.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- loadClaimDimension
    [loadClaimDimension.pending]: (state, action) => {
      // console.log('loadClaimDimension.pending', action); // DEBUG
      state.error = null;
      const claimId = action.meta.arg.id;
      if (_.has(state.byId, claimId)) {
        state.byId[claimId].pending = true;
        state.byId[claimId].error = null;
      } else {
        state.byId[claimId] = {
          pending: true,
          error: null,
          votingUsers: 0,
          data: null,
        };
      }
    },
    [loadClaimDimension.fulfilled]: (state, action) => {
      // console.log('loadClaimDimension.fulfilled', action); // DEBUG
      const claimId = action.meta.arg.id;
      state.byId[claimId].pending = false;
      state.byId[claimId]['data'] = action.payload.dimension;
      state.byId[claimId]['averages'] = action.payload.averages;
    },
    [loadClaimDimension.rejected]: (state, action) => {
      // // console.log('loadClaimDimension.rejected', action); // DEBUG
      const claimId = action.meta.arg.id;
      state.byId[claimId].pending = false;
      state.byId[claimId]['error'] = action.error;
    },
    // ---------------------------------------------------- saveClaimDimension
    [saveClaimDimension.pending]: (state, action) => {
      // console.log('saveClaimDimension.pending', action); // DEBUG
      const claimId = action.meta.arg.claimId;
      if (_.has(state.byId, claimId)) {
        state.byId[claimId].pending = true;
        state.byId[claimId].error = null;
      } else {
        state.byId[claimId] = {
          pending: true,
          error: null,
          votingUsers: 0,
          data: null,
        };
      }
    },
    [saveClaimDimension.fulfilled]: (state, action) => {
      // console.log('saveClaimDimension.fulfilled', action); // DEBUG
      const claimId = action.meta.arg.claimId;
      state.byId[claimId].pending = false;
      if (state.byId[claimId]['data'] === null) {
        state.byId[claimId].votingUsers++;
      }
      state.byId[claimId]['data'] = action.payload;
    },
    [saveClaimDimension.rejected]: (state, action) => {
      // console.log('saveClaimDimension.rejected', action); // DEBUG
      const claimId = action.meta.arg.claimId;
      state.byId[claimId].pending = false;
      state.byId[claimId]['error'] = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetClaimDimensions } = actions;
export default reducer;
