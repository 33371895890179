import React from 'react';
import { connect } from 'react-redux';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
// Left
import SubjectIcon from '@material-ui/icons/Subject';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// Right
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import SettingsIcon from '@material-ui/icons/Settings';

import DebateEdit from './DebateEdit';
import DebateDelete from './DebateDelete';
import { getSelectedDebate } from '../store/discuss.selectors';
import { toggleLeftDrawer, toggleRightDrawer } from '../store/discuss.slice';
// import { openLeftDrawer, openRightDrawer } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
    '& > *': {
      // border: 'solid red 1px', // DEBUG
      display: 'inline-flex',
    },
    '& > :first-child': { paddingLeft: theme.spacing(2) },
    '& > :last-child': { paddingRight: theme.spacing(2) },
  },
  filler: { flex: '1 1 auto' },
}));

function DebateActionPanel(props) {
  const {
    debate,
    onSummary,
    onArgumentMap,
    // onDebateHealth,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        {/* <IconButton
          size="small"
          onClick={onSummary}
        >
          <SubjectIcon />
        </IconButton> */}
        {/* <IconButton
          size="small"
          onClick={onDebateHealth}
        >
          <FavoriteIcon />
        </IconButton> */}
      </div>
      <div className={classes.filler}>&nbsp;</div>
      <div>
        <DebateEdit debate={debate} />
        <DebateDelete debate={debate} />
        <IconButton
          size="small"
          onClick={onArgumentMap}
        >
          <AccountTreeIcon />
        </IconButton>
        {/* <IconButton
          aria-label=""
          size="small"
          onClick={() => {}} // @todo
        >
          <SupervisedUserCircleIcon />
        </IconButton> */}
        {/* <IconButton
          aria-label=""
          size="small"
          onClick={() => {}} // @todo
        >
          <BarChartIcon />
        </IconButton> */}
        {/* <IconButton
          aria-label=""
          size="small"
          onClick={() => {}} // @todo
        >
          <SettingsIcon />
        </IconButton> */}
      </div>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    debate: getSelectedDebate(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSummary: () => dispatch(toggleLeftDrawer('summary')),
    onArgumentMap: () => dispatch(toggleRightDrawer('argumentMap')),
    // onDebateHealth: () => dispatch(openDrawer('debateHealth')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebateActionPanel);