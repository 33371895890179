import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from 'app/Home';
import Dashboard from 'features/profile/Dashboard';
import authRoutes from 'features/auth/auth.routes';
import discussRoutes from 'features/discuss/discuss.routes';

import Sandbox from 'features/sandbox';

import { Unauthorized, Forbidden, PageNotFound } from 'features/errors';

export default function RouterOutlet() {
  return (
    <Switch>
      {/* @todo find the proper way to redirect / to /home */}
      <Route path="/" exact component={Home} />
      <Route path="/home" exact component={Home} />
      <Route path="/dashboard" exact component={Dashboard} />

      {authRoutes}
      {discussRoutes}
      {/* @note add new routes HERE */}


      <Route path="/sandbox" component={Sandbox} />

      <Route path="/401" component={Unauthorized} />
      <Route path="/403" component={Forbidden} />
      <Route path="/404" component={PageNotFound} />
      <Redirect to="/404" />
    </Switch>
  );
};
