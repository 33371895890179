import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';


const defaultAgreement = 0;
const initialAverage = 0;

function agreementAverage(values) {
  const len = _.size(values);
  if (len > 0) {
    const sum = _.reduce(values, (acc, curr) => acc+ curr, initialAverage);
    return sum / len;
  }
  return initialAverage;
}

export const loadClaimAgreement = createAsyncThunk(
  'claimAgreements/loadClaimAgreement',
  async (claim, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = claim.debateId;
    const positionId = claim.positionId;
    const claimId = claim.id;
    try {
      const agreementsByUser = await api.getResource('claimAgreements', {
        relativePath: `${debateId}/${positionId}/${claimId}/users`,
      });
      const agreement = _.get(agreementsByUser, ownerId, defaultAgreement);
      const communityAgreements = _.omit(agreementsByUser, ownerId);
      const levels = _.map(communityAgreements, 'level');
      const average = agreementAverage(levels);
      return { agreement, average };

    } catch (err) {
      // @todo check error
      // thunkAPI.rejectWithValue
      return {
        agreement: defaultAgreement,
        average: initialAverage,
      }
    }
  }
);

export const fetchClaimAgreement = createAsyncThunk(
  'claimAgreements/fetchClaimAgreement',
  async (claim, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = claim.debateId;
    const positionId = claim.positionId;
    const claimId = claim.id;
    return await api.getResource('claimAgreements', {
      relativePath: `${debateId}/${positionId}/${claimId}/users/${ownerId}`,
    });
  }
);

export const saveClaimAgreement = createAsyncThunk(
  'claimAgreements/saveClaimAgreement',
  async (newEntry, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = newEntry.debateId;
    const positionId = newEntry.positionId;
    const claimId = newEntry.claimId;
    return await api.addResource('claimAgreements', newEntry, {
      relativePath: `${debateId}/${positionId}/${claimId}/users/${ownerId}`,
      unique: true,
    });
  }
);

const initialState = {
  byId: {
    //<claimId>: {
      //  pending:false,
      //  error : null,
      //  data: null,
    //}
  },
};

const slice = createSlice({
  name: 'claimAgreements',
  initialState,
  reducers: {
    resetClaimAgreements() {
      return initialState;
    },
  },
  extraReducers: {
    // ---------------------------------------------------- loadPositionDimension
    [loadClaimAgreement.pending]: (state, action) => {
      // console.log('loadClaimAgreement.pending', action); // DEBUG
      const claimId = action.meta.arg.id;
      state.byId[claimId] = {
        pending: true,
        error: null,
        data: null,
      };
    },
    [loadClaimAgreement.fulfilled]: (state, action) => {
      // console.log('loadClaimAgreement.fulfilled', action); // DEBUG
      const claimId = action.meta.arg.id;
      state.byId[claimId].pending = false;
      state.byId[claimId]['data'] = action.payload.agreement;
      state.byId[claimId]['average'] = action.payload.average;
    },
    [loadClaimAgreement.rejected]: (state, action) => {
      // // console.log('loadClaimAgreement.rejected', action); // DEBUG
      const claimId = action.meta.arg.id;
      state.byId[claimId].pending = false;
      state.byId[claimId]['error'] = action.error;
    },
    // ---------------------------------------------------- fetchClaimAgreement
    // [fetchClaimAgreement.pending]: (state, action) => {
    //   // console.log('fetchpositionAgreement.pending', action); // DEBUG
    //   const claimId = action.meta.arg.id;
    //   state.byId[claimId] = {
    //     pending: true,
    //     // data: null, error: null,
    //   };
    // },
    // [fetchClaimAgreement.fulfilled]: (state, action) => {
    //   // console.log('fetchpositionAgreement.fulfilled', action); // DEBUG
    //   const claimId = action.meta.arg.id;
    //   state.byId[claimId].pending = false;
    //   state.byId[claimId]['data'] = action.payload;
    // },
    // [fetchClaimAgreement.rejected]: (state, action) => {
    //   // console.log('fetchpositionAgreement.rejected', action); // DEBUG
    //   const claimId = action.meta.arg.id;
    //   state.byId[claimId].pending = false;
    //   state.byId[claimId]['error'] = action.error;
    // },
    // ---------------------------------------------------- saveClaimAgreement
    [saveClaimAgreement.pending]: (state, action) => {
      // console.log('savepositionAgreement.pending', action); // DEBUG
      const claimId = action.meta.arg.claimId;
      state.byId[claimId] = {
        pending: true,
        // data: null, error: null,
      };
    },
    [saveClaimAgreement.fulfilled]: (state, action) => {
      // console.log('savepositionAgreement.fulfilled', action); // DEBUG
      const claimId = action.meta.arg.claimId;
      state.byId[claimId].pending = false;
      state.byId[claimId]['data'] = action.payload;
    },
    [saveClaimAgreement.rejected]: (state, action) => {
      // console.log('savepositionAgreement.rejected', action); // DEBUG
      const claimId = action.meta.arg.claimId;
      state.byId[claimId].pending = false;
      state.byId[claimId]['error'] = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetClaimAgreements } = actions;
export default reducer;
