import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import ArgumentTree from './components/ArgumentTree';
import { focusPosition } from '../store/positions.slice';
import { focusClaim } from '../store/claims.slice';
import { composeDebate } from '../store/discuss.selectors';

import PositionIcon from 'icons/Position';
import OpposingIcon from 'icons/Opposing';
import SupportingIcon from 'icons/Supporting';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 150,
    height: '100%',
  },
    title: {
      '& h3': {
        color: theme.palette.primary.main,
      },
    },
    stats: {
      // border: 'solid white 1px',
      marginBottom: 15,
      fontSize: 12,
      textAlign: 'right',
      color: theme.palette.primary.contrastText,
      '& .MuiIcon-root': {
        display: 'inline-block',
        position: 'relative', top: 4,
        width: 18, height: 16,
      }
    },
    content: {
      // width: 300,
      flex: '1 1 auto',
      overflow: 'auto',
    },
}));

function ArgumentMap(props) {
  const {
    ready,
    positionCounter,
    claimCounter,
  } = props;
  const classes = useStyles();

  return ready && (
    <div className={classes.root}>
      <div className={classes.title}>
        <h3>Discussion Map</h3>
      </div>
      <div className={classes.stats}>
        <div>{positionCounter} idea{positionCounter!==1 && 's'} <PositionIcon /></div>
        <div>{claimCounter} argument{claimCounter!==1 && 's'} <OpposingIcon />/<SupportingIcon /></div>
      </div>
      <div className={classes.content}>
        <ArgumentTree {...props} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const discussState = state.discuss.layout;
  const positionsState = state.discuss.positions;
  const claimsState = state.discuss.claims;
  return {
    ready: discussState.ready,
    positionCounter: _.size(positionsState.byId),
    claimCounter: _.size(claimsState.byId),
    debate: composeDebate(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClickPosition: id => dispatch(focusPosition(id)),
    onClickClaim: id => dispatch(focusClaim(id)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArgumentMap);
