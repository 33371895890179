import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import DialogTitle from 'components/DialogTitle';
import SignInForm from './components/SignInForm';
import SignUpForm from './components/SignUpForm';
import { signIn, signUp } from './auth.slice';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

function SignInOrSignup(props) {
  const {
    authUser,
    error,
    openDialog,
    onCloseDialog,
    onSignIn,
    onSignUp,
  } = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSignIn = (values) => {
    onSignIn(values);
  };

  const handleSignUp = (values) => {
    onSignUp(values);
  };

  const open = Boolean(authUser) ? false : openDialog;

  const tabs = {
    0: <SignInForm
          error={error}
          onSubmit={handleSignIn}
        />,
    1: <SignUpForm
          error={error}
          onSubmit={handleSignUp}
        />,
  }

  // console.log('SignInOrSignup', props); // DEBUG

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={onCloseDialog}
    >
      <DialogTitle onClose={onCloseDialog}>Login required</DialogTitle>

      <Container maxWidth="xs">
        <Tabs
          value={tabValue}
          variant="fullWidth"
          aria-label="simple tabs example"
          onChange={handleChange}
        >
          <Tab label="Login" />
          <Tab label="Signup" />
        </Tabs>
        {tabs[tabValue]}
      </Container>





      {/* <Container maxWidth="xs">
        <SignInForm
          error={error}
          onSubmit={handleSignIn}
        />

        <SignUpForm
          error={error}
          onSubmit={handleSignUp}
        />
      </Container> */}

    </Dialog>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state.auth.profile,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSignIn: (creds) => dispatch(signIn(creds)),
    onSignUp: (newUser) => dispatch(signUp(newUser)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInOrSignup);
