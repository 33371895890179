import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from 'api';

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (newUser) => {
    return await api.createUserWithEmailAndPassword(newUser);
  }
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (credentials) => {
    return await api.signInWithEmailAndPassword(credentials);
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async () => {
    await api.signOut();
  }
);

const slice = createSlice({
  name: 'auth',
  initialState: {
    ready: false,
    // @todo module error
    loading: false,
    profile: null,
    error: null,
  },
  reducers: {
    authChangedValue: {
      reducer(state, action) {
        state.ready = true;
        state.profile = action.payload;
      },
    },
    authChangedError: {
      reducer(state, action) {
        state.ready = true; // @todo mmm
        // @todo module error
      },
    },
    authCleanUp: {
      reducer(state) {
        state.error = null;
      },
    },
  },
  extraReducers: {
    // ---------------------------------------------------- signUp
    [signUp.pending]: (state, action) => {
      state.loading = true;
      state.profile = null;
      state.error = null;
    },
    [signUp.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [signUp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- signIn
    [signIn.pending]: (state, action) => {
      state.loading = true;
      state.profile = null;
      state.error = null;
    },
    [signIn.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [signIn.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- signOut
    [signOut.pending]: (state, action) => {
      state.loading = true;
    },
    [signOut.fulfilled]: (state, action) => {
      state.loading = false;
      state.profile = null;
    },
  },
});

const { actions, reducer } = slice;
export const {
  authChangedValue, authChangedError, authCleanUp
} = actions;
export default reducer;
