import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  root: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  }
}));

function ScrollUpButton(props) {
  const {
    className,
    anchorEl,
    show
  } = props;
  const classes = useStyles();

  const onClick = (event) => {
    anchorEl.scrollTop = 0;
  };

  // @todo how to transform
  // @see https://material-ui.com/components/popover/
  // Other animation examples
  // @see https://material-ui.com/components/floating-action-button/

  return (
    <Fab
      className={clsx(classes.root, className, {
        [classes.visible]: show,
      })}
      color="primary"
      size="small"
      aria-label="Go to top"
      onClick={onClick}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );

}

export default ScrollUpButton;
