import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// import Summery from './Summary';
import ArgumentMap from './ArgumentMap';
// import DebateHealth from './DebateHealth';
// import { closeRightDrawer } from '../store/discuss.slice';
import { toggleRightDrawer } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: '0px 10px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderBottom: '2px solid rgb(158,158,158)',
    '& h3': {
      color: theme.palette.primary.contrastText,
      textAlign: 'right',
    },
  },
  closeButton: {
    position: 'absolute', top: 12, left: 0,
    '& button': { color: theme.palette.primary.contrastText }
  },
}));

function DebateDrawers(props) {
  const {
    className,
    showSummary,
    showArgumentMap,
    showDebateHealth,
    onClose,
  } = props;
  const classes = useStyles();

  const open = () => showSummary || showArgumentMap || showDebateHealth;

  // console.log('DebateDrawers', props); // DEBUG

  return (
    <div // @todo remove collapse ?
      className={clsx(classes.root, className)}
      style={{ display: open() ? 'block' : 'none' }}
    >
      <div className={classes.closeButton}>
        <IconButton
          size="small"
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>

      {/* {showSummary && <Summery />} */}
      {showArgumentMap && <ArgumentMap />}
      {/* {showDebateHealth && <DebateHealth />} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const debateDrawers = state.discuss.layout.rightDrawers;
  return {
    // showSummary: debateDrawers.summary,
    showArgumentMap: debateDrawers.argumentMap,
    // showDebateHealth: debateDrawers.debateHealth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onClose: () => dispatch(closeRightDrawer()),
    onClose: () => dispatch(toggleRightDrawer('argumentMap')),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebateDrawers);
