import React from 'react';
// import Moment from 'react-moment';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: '2px solid black',
    borderRight: '2px solid black',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: 'black',
    padding: '10px 5px',
  },
}));

function Quote(props) {
  const {
    className,
    entity,
    owner,
    onClick,
  } = props;
  const classes = useStyles();

  // console.log('Quote', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)} onClick={onClick}>
      <div className={classes.owner}>
        {owner}
      </div>
      <div className={classes.text}>
        {entity.text}
      </div>
    </div>
  );
}

Quote.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default Quote;
