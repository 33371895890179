import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import faker from 'faker';
import _ from 'lodash';

import * as api from 'api';

export const fetchParticipants = createAsyncThunk(
  'participants/fetchParticipants',
  async (debateId) => {
    return await api.getResource('participants', {
      relativePath: debateId,
    });
  }
);

export const addParticipant = createAsyncThunk(
  'participants/addParticipant',
  async (newEntry) => {
    const debateId = newEntry.debateId;
    const participantId = newEntry.id;
    // const participantId = thunkAPI.getState().auth.profile.uid;
    return await api.addResource('participants', newEntry, {
      relativePath: `${debateId}/${participantId}`,
      unique: true,
    });
  }
);

export const checkSubscription = createAsyncThunk(
  'participants/checkSubscription',
  async (noarg, thunkAPI) => {
    const authUser = thunkAPI.getState().auth.profile;
    if (Boolean(authUser)) {
      const debatesState = thunkAPI.getState().discuss.debates;
      const participantsState = thunkAPI.getState().discuss.participants;
      const participant = _.get(participantsState, `byId['${authUser.uid}']`, null)
      if (!Boolean(participant)) {
        await thunkAPI.dispatch(addParticipant({
          id: authUser.uid,
          debateId: debatesState.current,
          // @todo check in some way if pseudo already exists
          // pseudo: authUser.defaultPseudo, // @todo
          pseudo: faker.name.findName(), // temp
        }));
      }
    }
  }
);

const initialState = {
  pending: false,
  error: null,
  byId: {},
};

const slice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    resetParticipants(state) {
      state.byId = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchParticipants
    [fetchParticipants.pending]: (state) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [fetchParticipants.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [fetchParticipants.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- addPosition
    [addParticipant.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [addParticipant.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    [addParticipant.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- checkSubscription
    // [checkSubscription.pending]: (state) => {
    //   // state.pending = true;
    //   // state.error = null;
    // },
    // [checkSubscription.fulfilled]: (state, action) => {
    //   // state.pending = false;
    //   // state.byId[ action.payload.id ] = action.payload;
    // },
    // [checkSubscription.rejected]: (state, action) => {
    //   // state.pending = false;
    //   // state.error = action.error;
    // },
  },
});

const { actions, reducer } = slice;
export const { resetParticipants } = actions;
export default reducer;
