import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';

import * as api from 'api';

export const generateSummary = createAsyncThunk(
  'summary/generateSummary',
  async (debateId) => {
    return await fetch(`https://bcause-summary-ws.ew.r.appspot.com/summary/${debateId}`);
  }
);

export const fetchSummary = createAsyncThunk(
  'summary/fetchSummary',
  async (debateId) => {
    return await api.getResource('summary', {
      relativePath: debateId,
    });
  }
);

const slice = createSlice({
  name: 'summary',
  initialState: {
    pending: false,
    error: null,
    chunks: [],
  },
  reducers: {
  },
  extraReducers: {
    // ---------------------------------------------------- fetchSummary
    [generateSummary.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [generateSummary.fulfilled]: (state, action) => {
      console.log('fetchSummary', action);
      state.pending = false;
      state.current = action.payload;
    },
    [generateSummary.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- fetchSummary
    [fetchSummary.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [fetchSummary.fulfilled]: (state, action) => {
      // console.log('fetchSummary', action.payload); // DEBUG
      const chunks = action.payload.current['summary-text-chunks'];
      state.pending = false;
      state.chunks = chunks;
    },
    [fetchSummary.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

const { actions, reducer } = slice;
// export const {  } = actions;
export default reducer;
