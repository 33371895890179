import React from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle'; // substituted by custom
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DialogTitle from 'components/DialogTitle';
import EmojiViewer from 'components/EmojiViewer';
import { openDialog, closeDialog } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    textAlign: 'right',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    marginBottom: 20,
  },
    date: {
      fontWeight: 'bold',
      fontSize: '16px',
    },
    title: { fontWeight: 'bold' },
    slider: {
      // border: 'solid black 1px', // DEBUG
      paddingTop: 50,
    },
    text: {},
    sep: {
      margin: 20,
      // margin: '20px 10px 20px 10px',
    },
}));

function PositionTimeline(props) {
  const {
    show,
    // position,
    claims,
    // pending, // @todo
    // error,
    // authUser,
    openDialog,
    onOpen,
    onClose,
  } = props;
  const classes = useStyles();

  const buildTrigger = () => (
    <Button
      color="primary"
      size="small"
      onClick={onOpen}
    >Your reflection timeline</Button>
  );

  const buildList = (items) => {
    return items &&
      items.map((item, index) => {
        const agreement = item.positionAgreement;
        // const typeText = item.type === 'OPPOSING' ? 'an opposing' : 'a supporting';
        const typeText = item.type === 'OPPOSING' ? 'You counterargued' : 'You said';
        const dateComponent = <Moment format="DD/MM/YYYY" date={item.metadata.createdDate} />
        return (
          // <Box mb={1}></Box>
          <div className={classes.row} key={index}>
            <div className={classes.date}>
              On {dateComponent}
            </div>
            <div className={classes.slider}>
              <EmojiViewer
                name={`position-agreement-view-${item.id}`}
                value={agreement}
                pastTense={true}
                secondPerson={true}
                noAverage={true}
              />
            </div>
            {/* <div className={classes.title}>
              At <strong>{dateComponent}</strong> you added <strong><span style={{
                color: item.type === 'OPPOSING' ? 'rgb(255, 187, 48)' : 'rgb(0, 73, 115)'
              }}>{typeText}</span></strong>
            </div> */}
            <div className={classes.title}>
              {typeText}:
            </div>
            <div className={classes.text}>
              &laquo;{item.text}&raquo;
            </div>
            <div className={classes.sep}><hr /></div>
          </div>
        );
      });
  };

  const buildActions = () => (<>
    <Button
      variant="contained"
      color="primary"
      size="small"
      // className={classes.button}
      // startIcon={<SaveIcon />}
      onClick={onClose}
    >Close</Button>
  </>);

  // useEffect(() => {
  //   if (position) {
  //     setValues(position);
  //   }
  // }, [position]);

  // console.log('PositionDimensionsCast', props); // DEBUG

  return (
    <div className={classes.root}>
      {show && buildTrigger()}
      <Dialog
        open={openDialog}
        // maxWidth="md"
        fullWidth={true}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>Reflection timeline</DialogTitle>
        <DialogContent>
          {buildList(claims)}
        </DialogContent>
        <DialogActions>
          {buildActions()}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const position = ownProps.position;
  const dialogState = state.discuss.layout.dialogs;
  const claimsState = state.discuss.claims;
  const authUser = state.auth.profile;
  const claimsByPosition = _.filter(claimsState.byId, o => o.positionId === position.id);
  return {
    openDialog: dialogState.positionTimeline === position.id,
    show: true,
    claims: _.filter(claimsByPosition, o => o.metadata.createdBy === authUser.uid),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const position = ownProps.position;
  return {
    onOpen: () => dispatch(openDialog({
      resourceName: 'positionTimeline',
      resourceId: position.id,
    })),
    onClose: () => dispatch(closeDialog('positionTimeline')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionTimeline);
