import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ThumbRateV from 'components/ThumbRateV';

const useStyles = makeStyles(theme => ({
  root: {
  },
  box: {
    border: 'solid black 1px', // DEBUG
    '& > div': {
      border: 'solid red 1px', // DEBUG
    },
  },
}));

export default function (props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Container>

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          No init
        </Typography>
        <ThumbRateV
          // className={classes.box}
          onRate={() => {}}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          Not voted yet
        </Typography>
        <ThumbRateV
          amount={25000}
          onRate={() => {}}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          Voted UP
        </Typography>
        <ThumbRateV
          amount={10}
          value={1}
          onRate={() => {}}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          Voted Down
        </Typography>
        <ThumbRateV
          amount={20}
          value={-1}
          onRate={() => {}}
        />

        <p>&nbsp;</p>

      </Container>

    </div>
  );
}
