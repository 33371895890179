import React, { useCallback, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import faker from 'faker';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import SignUpForm from './components/SignUpForm';
import { signUp, authCleanUp } from './auth.slice';
import { SIGNIN_ROUTE } from './auth.routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUp(props) {
  const {
    authUser,
    error,
    onSignUp,
    onDismount,
  } = props;
  const classes = useStyles();

  // const DASHBOARD_ROUTE = '/dashboard'; // @todo move in the proper file
  const DASHBOARD_ROUTE = '/discuss/debates'; // temp

  const handleSignUp = (values) => {
    onSignUp({
      ...values,
      // @todo check in some way if pseudo already exists
      defaultPseudo: faker.name.findName(),
    });
  };

  const dismount = useCallback(onDismount, []);
  useEffect(() => {
    return () => dismount();
  }, [dismount]);

  // console.log('SignIn', props); // DEBUG

  if (Boolean(authUser)) {
    return <Redirect to={DASHBOARD_ROUTE} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Sign up</Typography>

        <SignUpForm
          error={error}
          onSubmit={handleSignUp}
        />

        <Grid container justify="flex-end">
          <Grid item>
            <Link to={SIGNIN_ROUTE}>Already have an account? Sign in</Link>
          </Grid>
        </Grid>

      </div>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state.auth.profile,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSignUp: (newUser) => dispatch(signUp(newUser)),
    onDismount: () => dispatch(authCleanUp()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);