import React from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle'; // substituted by custom
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// import DialogTitle from 'components/DialogTitle';
import { deleteClaim } from '../store/claims.slice';
import { openDialog, closeDialog } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  closeButton: {
    claim: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ClaimDelete(props) {
  const {
    show,
    // claim,
    // pending, // @todo
    // error,
    openDialog,
    onOpen,
    onClose,
    onDelete,
  } = props;
  const classes = useStyles();

  const buildTrigger = () => (
    <IconButton
      size="small"
      color="inherit"
      aria-label="Delete claim"
      onClick={onOpen}
    >
      <DeleteIcon />
    </IconButton>
  );

  const buildActions = () => (<>
    <Button
      variant="contained"
      size="small"
      onClick={onClose}
    >Cancel</Button>
    <Button
      variant="contained"
      color="secondary"
      size="small"
      // className={classes.button}
      startIcon={<DeleteIcon />}
      onClick={onDelete}
    >Delete</Button>
  </>);

  // console.log('ClaimDimensionsCast', props); // DEBUG

  return (
    <div className={classes.root}>
      {show && buildTrigger()}
      <Dialog
        open={openDialog}
        // maxWidth="md"
        fullWidth={true}
        onClose={onClose}
      >
        {/* <DialogTitle onClose={handleClose}>Cognitive ranking for the claim</DialogTitle> */}
        <DialogContent>
          Do you really want delete this argument?
        </DialogContent>
        <DialogActions>
          {buildActions()}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const claim = ownProps.claim;
  const dialogState = state.discuss.layout.dialogs;
  // const authUser = state.auth.profile;
  const uid = _.get(state.auth.profile, 'uid', false);

  // @todo move in a selectort ?
  const claimsState = state.discuss.claims;
  const agreementsState = state.discuss.claimAgreements;
  const thumbsState = state.discuss.claimThumbs;
  const heartsState = state.discuss.claimHearts;
  const dimensionsState = state.discuss.claimDimensions;

  return {
    openDialog: dialogState.claimDelete === claim.id,
    show: uid === claim.metadata.createdBy
      && !_.some(claimsState.byId, ['parentId', claim.id])
      && !_.has(agreementsState, `byId[${claim.id}]`)
      && !_.has(thumbsState, `byId[${claim.id}]`)
      && !_.has(heartsState, `byId[${claim.id}]`)
      && !_.has(dimensionsState, `byId[${claim.id}]`)
      ,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const claim = ownProps.claim;
  return {
    onDelete: () => {
      return dispatch(deleteClaim(claim))
        .then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(closeDialog('claimDelete'));
          }
          return res;
        });
    },
    onOpen: () => dispatch(openDialog({
      resourceName: 'claimDelete',
      resourceId: claim.id,
    })),
    onClose: () => dispatch(closeDialog('claimDelete')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimDelete);
