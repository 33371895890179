import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';


export const fetchPositionHearts = createAsyncThunk(
  'positionHearts/fetchPositionHearts',
  async (debateId, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const heartsById = await api.getResource('positionHearts', {
      relativePath: debateId,
    });
    _.each(heartsById, item => {
      item.value = _.get(item, `users[${ownerId}].value`, 0);
      delete item.users;
    });
    return heartsById;
  }
);

export const giveHeart = createAsyncThunk(
  'positionHearts/giveHeart',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;

    const resourceName = 'positionHearts';
    const basePath = `${debateId}/${positionId}`;
    const entry = { ...props, value: 1 };

    const addVote = api.addResource(resourceName, entry, {
      relativePath: `${basePath}/users/${ownerId}`,
      unique: true,
    });

    const updateCounter = api.increase(resourceName, {
      relativePath: `${basePath}/total`,
    });

    return await Promise
      .all([addVote, updateCounter])
      .then(([give, total]) => ({ ...give, total }));
  }
);

export const ungiveHeart = createAsyncThunk(
  'positionHearts/ungiveHeart',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;

    const resourceName = 'positionHearts';
    const basePath = `${debateId}/${positionId}`;

    const deleteVote = api.deleteResource(resourceName, {
      relativePath: `${basePath}/users/${ownerId}`,
    });
    const updateCounter = api.decrease(resourceName, {
      relativePath: `${basePath}/total`,
    });

    return await Promise
      .all([deleteVote, updateCounter])
      .then(([give, total]) => ({ ...give, total }));
  }
);

const slice = createSlice({
  name: 'positionHearts',
  initialState: {
    pending:false,
    error : null,
    byId: {
      //<positionId>: {
      //  value: 0,
      //  total: 0,
      //}
    },
  },
  reducers: {
    resetPositionHearts(state) {
      state.byId = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchPositionHearts
    [fetchPositionHearts.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [fetchPositionHearts.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [fetchPositionHearts.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- giveHeart
    [giveHeart.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [giveHeart.fulfilled]: (state, action) => {
      const positionId = action.meta.arg.positionId;
      state.pending = false;
      if (_.isUndefined(state.byId[positionId])) {
        state.byId[positionId] = {};
      }
      state.byId[positionId]['value'] = action.payload.value;
      state.byId[positionId]['total'] = action.payload.total;
    },
    [giveHeart.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- ungiveHeart
    [ungiveHeart.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [ungiveHeart.fulfilled]: (state, action) => {
      const positionId = action.meta.arg.positionId;
      state.pending = false;
      state.byId[positionId] = {
        value: 0,
        total: action.payload.total,
      };
    },
    [ungiveHeart.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetPositionHearts } = actions;
export default reducer;
