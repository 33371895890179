import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignInForm(props) {
  const {
    error,
    onSubmit,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    return onSubmit(values);
  };

  return (
    <div className={classes.root}>

      {Boolean(error) &&  <p className={classes.error}>{error.message}</p>}

      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <TextField
          name="email"
          autoComplete="email"
          label="Email Address"
          variant="outlined"
          margin="normal"
          autoFocus
          required
          fullWidth
          onChange={handleChange}
        />
        <TextField
          type="password"
          name="password"
          label="Password"
          autoComplete="current-password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          onChange={handleChange}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          className={classes.submit}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >Sign In</Button>
      </form>

    </div>
  );
}

export default SignInForm;
