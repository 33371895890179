import React, { useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import SignInForm from './components/SignInForm';
import { signIn, authCleanUp } from './auth.slice';
import { SIGNUP_ROUTE, PWD_RECOVERY_ROUTE } from './auth.routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

function SignIn(props) {
  const {
    authUser,
    error,
    onSignIn,
    onDismount,
  } = props;
  const classes = useStyles();

  // const DASHBOARD_ROUTE = '/dashboard'; // @todo move in the proper file
  // const DASHBOARD_ROUTE = '/discuss/debates';
  const DASHBOARD_ROUTE = '/discuss/debate/-M_1YNLl74hj1-KvS_vL'; // temp

  const handleSignIn = (values) => {
    onSignIn(values);
  };

  const dismount = useCallback(onDismount, []);
  useEffect(() => {
    return () => dismount();
  }, [dismount]);

  // console.log('SignIn', props); // DEBUG

  if (Boolean(authUser)) {
    return <Redirect to={DASHBOARD_ROUTE} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Sign in</Typography>

        <SignInForm
          error={error}
          onSubmit={handleSignIn}
        />

        <Grid container>
          <Grid item xs>
            <Link to={PWD_RECOVERY_ROUTE}>Forgot password?</Link>
          </Grid>
          <Grid item>
            <Link to={SIGNUP_ROUTE}>Don't have an account? Sign Up</Link>
          </Grid>
        </Grid>

      </div>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state.auth.profile,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSignIn: (creds) => dispatch(signIn(creds)),
    onDismount: () => dispatch(authCleanUp()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
