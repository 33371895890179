import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leftDrawer: {
    open: false,
  },
  snackbar: {
    open: false,
    resourceId: null,
    severity: '',
    message: '',
  },
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleLeftDrawer: (state) => {
      const previousValue = state.leftDrawer.open;
      state.leftDrawer.open = !previousValue;
    },
    openSnackbar: (state, action) => {
      const p = action.payload;
      state.snackbar = {
        open: true,
        resourceId: p.resourceId,
        severity: p.severity,
        message: p.message,
      };
    },
    closeSnackbar: (state) => {
      state.snackbar = {
        open: false,
        resourceId: null,
        severity: '',
        message: '',
      };
    },
  }
});

const { actions, reducer } = slice;
export const {
  toggleLeftDrawer,
  openDialog, closeDialog,
  openSnackbar, closeSnackbar,
} = actions;
export default reducer;
