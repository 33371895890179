import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle'; // substituted by custom
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import DialogTitle from 'components/DialogTitle';
import { editDebate } from '../store/debates.slice';
import { openDialog, closeDialog } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  fields: {
    '& > div': { marginBottom: theme.spacing(1) }
  },
}));

function DebateEdit(props) {
  const {
    show,
    debate,
    // pending, // @todo
    // error,
    openDialog,
    onOpen,
    onClose,
    onSave,
  } = props;
  const classes = useStyles();
  const [values, setValues] = useState(null);

  function handleChange(event) {
    const { name, value, files } = event.target;
    const _value = _.isEmpty(files) ? value : files[0];
    setValues({ ...values, [name]: _value });
  }

  const handleSave = (event) => {
    event.preventDefault();
    onSave(values);
  };

  const buildTrigger = () => (
    <IconButton
      size="small"
      aria-label="Edit debate"
      onClick={onOpen}
    >
      <EditIcon />
    </IconButton>
  );

  const buildContent = () => (
    <div className={classes.fields}>
      <TextField
        name="title"
        autoComplete="title" // @todo REMOVE it. Only for an easy develop
        label="Title"
        variant="outlined"
        autoFocus
        required
        // error={false} // @todo
        // helperText="Incorrect entry." // @todo
        fullWidth
        onChange={handleChange}
      />
      <TextField
        name="text"
        autoComplete="text" // @todo REMOVE it. Only for an easy develop
        label="Description"
        variant="filled"
        rows={3}
        // required // @todo take a look to previous field
        multiline
        fullWidth
        onChange={handleChange}
      />
    </div>
  );

  const buildActions = () => (<>
    <Button
      variant="contained"
      size="small"
      onClick={onClose}
    >Cancel</Button>
    <Button
      variant="contained"
      color="secondary"
      size="small"
      // className={classes.button}
      startIcon={<SaveIcon />}
      onClick={handleSave}
    >Save</Button>
  </>);

  useEffect(() => {
    if (debate) {
      setValues(debate);
    }
  }, [debate]);

  // console.log('DebateEdit', props); // DEBUG

  return (
    <div className={classes.root}>
      {show && buildTrigger()}
      <Dialog
        open={openDialog}
        // maxWidth="md"
        fullWidth={true}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>Edit debate</DialogTitle>
        <DialogContent>
          {buildContent()}
        </DialogContent>
        <DialogActions>
          {buildActions()}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const debate = ownProps.debate;
  const dialogState = state.discuss.layout.dialogs;
  const positionsState = state.discuss.positions;
  // const authUser = state.auth.profile;
  const uid = _.get(state.auth.profile, 'uid', false);
  return {
    openDialog: dialogState.debateEdit === debate.id,
    show: uid === debate.metadata.createdBy
      && _.isEmpty(positionsState.byId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const debate = ownProps.debate;
  return {
    onSave: values => {
      return dispatch(editDebate(values))
        .then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(closeDialog('debateEdit'));
          }
          return res;
        });
    },
    onOpen: () => dispatch(openDialog({
      resourceName: 'debateEdit',
      resourceId: debate.id,
    })),
    onClose: () => dispatch(closeDialog('debateEdit')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebateEdit);
