import React from 'react';
import _ from 'lodash';

// @see https://stackoverflow.com/questions/38510443/how-to-use-an-svg-file-in-a-svgicon-in-material-ui
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';

import whiteSvg from 'assets/logo-white.svg';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit'
  }
});

export default function(props) {
  const classes = useStyles();
  // const imageColor = _.get(props, 'color', 'white');
  // const image = imageColor === 'blue' ? blueSvg : whiteSvg;
  return (
    <Icon {...props}>
      <img className={classes.imageIcon}
          //  src={image} alt="" // @todo
           src={whiteSvg} alt="" // @todo
      />
    </Icon>
  );
}