import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';


export const fetchPositionThumbs = createAsyncThunk(
  'positionThumbs/fetchPositionThumbs',
  async (debateId, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const thumbsById = await api.getResource('positionThumbs', {
      relativePath: debateId,
    });
    _.each(thumbsById, item => {
      item.value = _.get(item, `users[${ownerId}].value`, 0);
      delete item.users;
    });
    return thumbsById;
  }
);

export const voteThumbUp = createAsyncThunk(
  'positionThumbs/voteThumbUp',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;

    const resourceName = 'positionThumbs';
    const basePath = `${debateId}/${positionId}`;
    const entry = { ...props, value: 1 };

    const addVote = api.addResource(resourceName, entry, {
      relativePath: `${basePath}/users/${ownerId}`,
      unique: true,
    });

    const updateCounter = api.increase(resourceName, {
      relativePath: `${basePath}/totalUp`,
    });

    return await Promise
      .all([addVote, updateCounter])
      .then(([vote, total]) => ({ ...vote, total }));
  }
);

export const unvoteThumbUp = createAsyncThunk(
  'positionThumbs/unvoteThumbUp',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;

    const resourceName = 'positionThumbs';
    const basePath = `${debateId}/${positionId}`;

    const deleteVote = api.deleteResource(resourceName, {
      relativePath: `${basePath}/users/${ownerId}`,
    });
    const updateCounter = api.decrease(resourceName, {
      relativePath: `${basePath}/totalUp`,
    });

    return await Promise
      .all([deleteVote, updateCounter])
      .then(([vote, total]) => ({ ...vote, total }));
  }
);

export const voteThumbDown = createAsyncThunk(
  'positionThumbs/voteThumbDown',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;

    const resourceName = 'positionThumbs';
    const basePath = `${debateId}/${positionId}`;
    const entry = { ...props, value: -1 };

    const addVote = api.addResource(resourceName, entry, {
      relativePath: `${basePath}/users/${ownerId}`,
      unique: true,
    });

    const updateCounter = api.increase(resourceName, {
      relativePath: `${basePath}/totalDown`,
    });

    return await Promise
      .all([addVote, updateCounter])
      .then(([vote, total]) => ({ ...vote, total }));
  }
);

export const unvoteThumbDown = createAsyncThunk(
  'positionThumbs/unvoteThumbDown',
  async (props, thunkAPI) => {
    const ownerId = thunkAPI.getState().auth.profile.uid;
    const debateId = props.debateId;
    const positionId = props.positionId;

    const resourceName = 'positionThumbs';
    const basePath = `${debateId}/${positionId}`;

    const deleteVote = api.deleteResource(resourceName, {
      relativePath: `${basePath}/users/${ownerId}`,
    });
    const updateCounter = api.decrease(resourceName, {
      relativePath: `${basePath}/totalDown`,
    });

    return await Promise
      .all([deleteVote, updateCounter])
      .then(([vote, total]) => ({ ...vote, total }));
  }
);

const slice = createSlice({
  name: 'positionThumbs',
  initialState: {
    pending:false,
    error : null,
    byId: {
      //'positionId': {
      //  value: 0,
      //  totalUp: 0,
      //  totalDown: 0,
      //}
    },
  },
  reducers: {
    resetPositionThumbs(state) {
      state.byId = {};
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchPositionThumbs
    [fetchPositionThumbs.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [fetchPositionThumbs.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [fetchPositionThumbs.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- voteThumbUp
    [voteThumbUp.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [voteThumbUp.fulfilled]: (state, action) => {
      const positionId = action.meta.arg.positionId;
      state.pending = false;
      if (_.isUndefined(state.byId[positionId])) {
        state.byId[positionId] = {};
      }
      state.byId[positionId]['value'] = action.payload.value;
      state.byId[positionId]['totalUp'] = action.payload.total;
    },
    [voteThumbUp.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- unvoteThumbUp
    [unvoteThumbUp.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [unvoteThumbUp.fulfilled]: (state, action) => {
      const positionId = action.meta.arg.positionId;
      state.pending = false;
      state.byId[positionId] = {
        value: 0,
        totalUp: action.payload.total,
      };
    },
    [unvoteThumbUp.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- voteThumbDown
    [voteThumbDown.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [voteThumbDown.fulfilled]: (state, action) => {
      const positionId = action.meta.arg.positionId;
      state.pending = false;
      state.byId[positionId] = {
        value: action.payload.value,
        totalDown: action.payload.total,
      };
    },
    [voteThumbDown.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- unvoteThumbDown
    [unvoteThumbDown.pending]: (state, action) => {
      state.pending = true;
      state.error = null;
    },
    [unvoteThumbDown.fulfilled]: (state, action) => {
      const positionId = action.meta.arg.positionId;
      state.pending = false;
      state.byId[positionId] = {
        value: 0,
        totalDown: action.payload.total,
      };
    },
    [unvoteThumbDown.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
  },
});

const { actions, reducer } = slice;
export const { resetPositionThumbs } = actions;
export default reducer;
