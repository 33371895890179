import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

// import ThanksIcon from 'icon/Thanks';
import ClapIcon from 'icons/Clap';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    padding: '0px 5px',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      // border: 'solid black 1px', // DEBUG
      textAlign: 'center',
    }
  },
  // content: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   padding: '0px 5px',
  //   '& > div': {
  //     // border: 'solid black 1px', // DEBUG
  //     textAlign: 'center',
  //   }
  // },
  selected: { color: 'black' },
}));

const voteLabel = value => {
  const DIV = 1000;
  return value > DIV ? _.ceil(value / DIV, 1) + 'K' : (value || 0);
};

function getTooltipTitle1(value, disabled) {
  if (disabled) {
    return "You cannot rate your content";
  }
  return value > 0
    ? 'You loved this'
    : 'I love this';
};

function getTooltipTitle2(total) {
  return total > 0
    ? `${total} people loved this`
    : 'Nobody loved this yet';
};

// function getTooltipTitle1(value, disabled) {
//   if (disabled) {
//     return "You cannot rate your content";
//   }
//   return value > 0
//     ? 'Thanks, this is a useful contrib'
//     : 'Thanks author';
// };

// function getTooltipTitle2(total) {
//   return total > 0
//     ? `${total} people thanked you`
//     : 'Nobody thanked you yet';
// };

function HeartFeedback(props) {
  const {
    className,
    value,
    total,
    showLabel,
    disabled,
    onToggle,
  } = props;
  const classes = useStyles();

  const handleClick = (event) => {
    event.preventDefault();
    if (disabled) return;
    onToggle();
  };

  /*
  const Content = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} {...props} className={classes.content}>
        <div className={classes.button}>
          <IconButton
            className={clsx({
              [classes.selected]: value > 0,
            })}
            // disabled={disabled}
            size="small"
            color="inherit"
            aria-label="like"
            onClick={handleClick}
          >
            { value > 0 ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
          </IconButton>
        </div>
        <div className={classes.label}>
          {showLabel && voteLabel(total)}
        </div>
      </div>
    );
  });

  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip title={getTooltipTitle1(value, total, disabled)} placement="top" arrow>
        <Content />
      </Tooltip>
    </div>
  );
  */

  // const Counter = React.forwardRef((props, ref) => {
  //   return (
  //     <div ref={ref} {...props} className={classes.content}>

  //     </div>
  //   );
  // });

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.button}>
        <Tooltip title={getTooltipTitle1(value, disabled)} placement="top" arrow>
          <IconButton
            className={clsx({
              [classes.selected]: value > 0,
            })}
            // disabled={disabled}
            size="small"
            color="inherit"
            aria-label="like"
            onClick={handleClick}
          >
            { value > 0 ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.label}>
        <Tooltip title={getTooltipTitle2(total)} placement="top" arrow>
          <Typography>
            {voteLabel(total)}
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
}

// @todo
// HeartFeedbackFeedback.propTypes = {
//   onToggleUp: PropTypes.func.isRequired,
//   onToggleDown: PropTypes.func.isRequired,
// };

export default HeartFeedback;
