import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import PositionCard from 'features/discuss/positions/components/PositionCard';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

export default function(props) {
  const classes = useStyles();

  const mock = {
    "debateId" : "-LwIFKJe6vCQJpuky1lk",
    "id" : "-MAetRFVKc2WvUEnaJUm",
    "metadata" : {
      "createdBy" : "2IV1kmD6ccRTeSjCBv6mEFznjsu1",
      "createdDate" : 1593078824320
    },
    "text" : "I use my god as a burglers alarm, once he helped me to catch some robbers!",
    "type" : "POSITION"
  };

  return (
    <div className={classes.root}>

      <Container maxWidth="xs">

        <p>&nbsp;</p>

        <PositionCard
          side="left"
          entity={mock}
        />

        <p>&nbsp;</p>

        <PositionCard
          side="right"
          entity={mock}
        />

        <p>&nbsp;</p>

        <PositionCard
          side="right"
          entity={mock}
          focused={true}
        />

        <p>&nbsp;</p>



      </Container>

    </div>
  );
}
