import React from 'react';

import { makeStyles,
//useTheme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import EmojiSlider from 'components/EmojiSlider';

const useStyles = makeStyles(theme => ({
  root: {

  },
}));

export default function(props) {
  // const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Container>

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          No init
        </Typography>
        <EmojiSlider
          //defaultLevel={0}
          onSlideCommitted={() => {}}
        />

        <p>&nbsp;</p>

        <Typography component="h2" variant="h4">
          With init
        </Typography>
        <EmojiSlider
          defaultLevel={50}
          onSlideCommitted={(event, value) => {console.log(value)}}
        />

        <p>&nbsp;</p>

      </Container>

    </div>
  );
}
