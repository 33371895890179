import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUpForm(props) {
  const {
    error,
    onSubmit,
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    return onSubmit(values);
  };

  return (
    <div className={classes.root}>

      {Boolean(error) &&  <p className={classes.error}>{error.message}</p>}

      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              autoComplete="fname"
              label="First Name"
              variant="outlined"
              required
              fullWidth
              autoFocus
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              autoComplete="lname"
              label="Last Name"
              variant="outlined"
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              autoComplete="email"
              label="Email Address"
              variant="outlined"
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              name="password"
              autoComplete="current-password"
              label="Password"
              variant="outlined"
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Button
          className={classes.submit}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >Sign Up</Button>


      </form>

    </div>
  );
}

export default SignUpForm;
