import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from 'api';

export const fetchPositions = createAsyncThunk(
  'positions/fetchPositions',
  async (debateId) => {
    return await api.getResource('positions', {
      relativePath: debateId,
    });
  }
);

export const addPosition = createAsyncThunk(
  'positions/addPosition',
  async (newEntry) => {
    return await api.addResource('positions', newEntry, {
      relativePath: newEntry.debateId,
    });
  }
);

export const editPosition = createAsyncThunk(
  'positions/editPosition',
  async (values) => {
    // @todo check if id exists OR pass id resource as a param
    const debateId = values.debateId;
    const positionId = values.id;
    return await api.editResource('positions', values, {
      relativePath: `${debateId}/${positionId}`,
    });
  }
);

export const deletePosition = createAsyncThunk(
  'positions/deletePosition',
  async (entity) => {
    // @todo check if id exists OR pass id resource as a param
    const debateId = entity.debateId;
    const positionId = entity.id;
    await api.deleteResource('positions', {
      relativePath: `${debateId}/${positionId}`,
    });
    return entity;
  }
);

export const savePositionMedia = createAsyncThunk(
  'positions/savePositionMedia',
  async ({media, position}) => {
    const debateId = position.debateId;
    const positionId = position.id;
    const ext = media.type.split('/').pop();
    return await api.uploadFile('debates', media, {
      relativePath: `${debateId}/media`,
      filename: `${positionId}.${ext}`,
    })
      .then(url => ({...position, media: url }));
  }
);

const initialState = {
  pending: false,
  error: null,
  byId: {},
  focusedId: null,
};

const slice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    focusPosition(state, action) {
      state.focusedId = action.payload;
    },
    unfocusPosition(state, action) {
      state.focusedId = null;
    },
    resetPositions() {
      return initialState;
    },
  },
  extraReducers: {
    // ---------------------------------------------------- fetchPositions
    [fetchPositions.pending]: (state) => {
      state.pending = true;
      state.error = null;
      state.byId = {};
    },
    [fetchPositions.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = action.payload;
    },
    [fetchPositions.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- addPosition
    [addPosition.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [addPosition.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    // @todo error
    // ---------------------------------------------------- editPosition
    [editPosition.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [editPosition.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId[ action.payload.id ] = action.payload;
    },
    [editPosition.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- deletePositionMedia
    [deletePosition.pending]: (state) => {
      state.pending = true;
      state.error = null;
    },
    [deletePosition.fulfilled]: (state, action) => {
      state.pending = false;
      state.byId = _.omit(state.byId, action.payload.id);
    },
    [deletePosition.rejected]: (state, action) => {
      state.pending = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- saveClaimMedia

  },
});

const { actions, reducer } = slice;
export const { focusPosition, unfocusPosition, resetPositions } = actions;
export default reducer;
