import React, {
//useRef
 } from 'react';

// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Container from '@material-ui/core/Container';

// import CognitiveDimensions from 'features/discuss/components/CognitiveDimensions';
// import PositionDimensionsForm from 'features/discuss/components/PositionDimensionsForm';

// const useStyles = makeStyles(theme => ({
//   root: {
//   },
// }));

export default function(props) {
  // const theme = useTheme();
  // const classes = useStyles();
  // const cDimensionsRef = useRef(null);

  // const sections = [{
  //   name: 'first-value',
  //   hint: "This is the first value",
  // }, {
  //   name: 'second-value',
  //   hint: "This is the second value",
  // }, {
  //   name: 'third-value',
  //   hint: "This is the third value",
  // }, {
  //   name: 'forth-value',
  //   hint: "This is the forth value",
  // }];

  // const mock = {
  //   'first-value': 5,
  //   'second-value': 4,
  //   'third-value': 3,
  //   'forth-value': 2,
  // };

  return (<></>
    // <div className={classes.root}>

    //   <Container>

    //     <p>&nbsp;</p>

    //     <Typography component="h2" variant="h4">
    //       No init
    //     </Typography>
    //     <CognitiveDimensions
    //       name="dimensions-noinit"
    //       dimensions={sections}
    //       palette={theme.palette.secondary}
    //       onChange={()=>{}}
    //     />

    //     <p>&nbsp;</p>

    //     <Typography component="h2" variant="h4">
    //       With init
    //     </Typography>
    //     <CognitiveDimensions
    //       name="dimensions-withinit"
    //       dimensions={sections}
    //       defaultValues={mock}
    //       palette={theme.palette.secondary}
    //       onChange={()=>{}}
    //     />

    //     <p>&nbsp;</p>

    //     <PositionDimensionsForm
    //       initValues={mock}
    //       onSubmit={()=>{}}
    //       onCancel={()=>{}}
    //     />

    //   </Container>

    // </div>
  );
}
