import React, { useState, useRef, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import MoodIcon from '@material-ui/icons/Mood';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MicIcon from '@material-ui/icons/Mic';
import TelegramIcon from '@material-ui/icons/Telegram';

// import { DropzoneDialog } from 'material-ui-dropzone';

import SignInOrSignup from 'features/auth/SignInOrSignup';
import Recorder from 'components/recorder';
// import PositionAgreementCast from './PositionAgreementCast';
import { addPosition, editPosition, savePositionMedia, focusPosition } from '../store/positions.slice';
import { letScrollToBottom } from '../store/discuss.slice';
import { fetchSummary } from '../store/summary.slice';

const actionHeight = 44;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1000, // @todo organize in the theme
  },
  actions: {
    // border: 'solid black 1px', // DEBUG
    display: 'flex',
    alignItems: 'flex-end',
    '& > *': { margin: 5 },
    '& > button': { display: 'none' },
    '& .MuiCollapse-wrapperInner': { // recorder box
      display: 'flex', alignItems: 'center',
      height: actionHeight,
    },
  },
    form: {
      flexGrow: 1,
      position: 'relative',
      '& form': {
        position: 'absolute', bottom: 3,
        width: '100%', // Fix IE 11 issue.
        minWidth: 400,
      },
    },
    input: {
      backgroundColor: theme.palette.background.paper,
    },
  show: { display: 'block !important' },
}));

function CreatePosition(props) {
  const {
    className,
    pending,
    // error,
    authUser,
    onSave,
  } = props;
  const classes = useStyles();
  const formRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  // const [openAttachment, setOpenAttachment] = useState(false);
  // const [openPreview, setOpenPreview] = useState(false);
  const [openRecorder, setOpenRecorder] = useState(false);
  const [playRecording, setPlayRecorder] = useState(false);
  const [values, setValues] = useState({
    text: '',
    media: null,
    attachments: [],
  });

  const handleOpenDialog = (event) => {
    if (authUser === null) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = (event) => {
    event.preventDefault();
    setOpenDialog(false);
  };

  const handleOpenRecording = () => {
    setOpenRecorder(true);
    setPlayRecorder(true);
  }

  const handleCloseRecording = () => {
    setPlayRecorder(false);
    setOpenRecorder(false);
  }

  const stopRecording = () => {
    setPlayRecorder(false);
    // setOpenRecorder(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const saveRecording = (event, record) => {
    // console.log('saveRecording', record); // DEBUG
    event.preventDefault();
    // if (record === ???) return; // @todo check record
    return onSave({ media: record })
      .then(res => {
        // console.log('PositionCreate.saveRecording()', res); // DEBUG
        if (res.meta.requestStatus === 'rejected') {
          // for debug ONLY
          console.warn('PositionCreate.saveRecording()', res); // DEBUG
        } else if (res.meta.requestStatus === 'fulfilled') {
          setOpenRecorder(false);
        }
      });
  };

  const handleSave = (event, record) => {
    event.preventDefault();
    if (values.text === '') return;
    return onSave(values)
      .then(res => {
        // console.log('PositionCreate.handleSave()', res); // DEBUG
        if (res.meta.requestStatus === 'rejected') {
          // for debug ONLY
          console.warn('PositionCreate.handleSave()', res); // DEBUG
        } else if (res.meta.requestStatus === 'fulfilled') {
          formRef.current.reset();
          setValues({ ...values, text: '' });
        }
      });
  };

  const buildAttachmentPreviewCollapse = () => (
    <></>
    // <Collapse
    //   in={openPreview}
    //   unmountOnExit
    // >
    //   file list preview
    // </Collapse>
  );

  const buildInputForm = () => (
    <form ref={formRef} autoComplete="off">
      <TextField
        name="text"
        placeholder="Briefly enter your position here"
        variant="outlined" size="small"
        disabled={authUser === null}
        multiline rowsMax={5}
        fullWidth
        autoFocus
        InputProps={{
          className: classes.input,
        }}
        onChange={handleChange}
      />
    </form>
  );

  const AttachmentAction = memo(({ show }) => (<>
    <IconButton
      aria-label="attach an evidence"
      className={clsx({ [classes.show]: show })}
      style={{ color: 'white' }}
      // onClick={()=>{ setOpenAttachment(true) }}
    >
      {/* <AttachFileIcon /> */}
    </IconButton>

    {/* <DropzoneDialog
      // acceptedFiles={['image/*']}
      cancelButtonText={"cancel"}
      submitButtonText={"submit"}
      maxFileSize={5000000}
      open={openAttachment}
      onClose={() => setOpenAttachment(false)}
      onSave={files => {
        console.log('Files:', files); // DEBUG
        setValues({ ...values, attachments: files });
        setOpenAttachment(false);
        setOpenPreview(true);
      }}
      showPreviews={true}
      showFileNamesInPreview={true}
    /> */}
  </>));

  const RecordingAction = ({ show }) => (
  // const RecordingAction = memo(({ show }) => (
    <>
      <Collapse
        in={openRecorder}
        orientation="horizontal"
        unmountOnExit
      >
        <Recorder
          start={playRecording}
          // @todo color: 'white'
          onClear={handleCloseRecording}
          onDone={() => {}}
          //onDone={saveRecording}
          // onDone={handleSave}
        />
      </Collapse>
      <IconButton
        aria-label="add voice position"
        className={clsx({ [classes.show]: show && !openRecorder })}
        style={{ color: 'white' }}
        disabled={authUser === null}
        onClick={handleOpenRecording}
      >
        <MicIcon />
      </IconButton>
    </>
  // ));
  );

  const SendAction = memo(({ show }) => (
    <IconButton
      aria-label="add text position"
      className={clsx({ [classes.show]: show })}
      style={{ color: 'white' }}
      disabled={pending}
      onClick={handleSave}
    >
      <TelegramIcon />
    </IconButton>
  ));

  return (
    <div className={clsx(classes.root, className)}>
      <div onClick={handleOpenDialog}>
        <div className={classes.actions}>
          <AttachmentAction show={true} />
          {/* {buildInputForm()} */}
          <div className={classes.form}>
            {buildInputForm()}
          </div>
          {/* <RecordingAction show={values.text === ''} />
          <SendAction show={values.text !== ''} /> */}
          <SendAction show={true} />
        </div>
      </div>
      {buildAttachmentPreviewCollapse()}
      <SignInOrSignup
        openDialog={openDialog}
        onCloseDialog={handleCloseDialog}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const positionsState = state.discuss.positions;
  return {
    pending: positionsState.pending,
    error: positionsState.error,
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSave: async values => {
      let addedPositionResult, editedPositionResult;
      let addedPositionStatus = true,
          savedMediaStatus = true,
          editedPositionStatus = true;
      addedPositionResult = await dispatch(addPosition({
        ...values,
        type: 'POSITION',
        debateId: ownProps.debate.id,
      }));
      addedPositionStatus = addedPositionResult.meta.requestStatus === 'fulfilled';
      if (addedPositionStatus) {
        if (values.media !== null) {
        // if (_.has(values, 'media')) {
          const media = _.get(values, 'media');
          const position = addedPositionResult.payload;
          const savedMedia = await dispatch(savePositionMedia({media, position}))
          savedMediaStatus = savedMedia.meta.requestStatus === 'fulfilled';
          if (savedMediaStatus) {
            const position = savedMedia.payload;
            editedPositionResult = await dispatch(editPosition(position));
            editedPositionStatus = editedPositionResult.meta.requestStatus === 'fulfilled';
          }
        }
      }
      const result = Boolean(editedPositionResult) ? editedPositionResult : addedPositionResult;
      if (addedPositionStatus && savedMediaStatus && editedPositionStatus) {
        const debateId = result.payload.debateId;
        dispatch(letScrollToBottom());
        // dispatch(fetchSummary(debateId)); // @todo
      }
      return result;
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePosition);
