import React from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle'; // substituted by custom
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// import DialogTitle from 'components/DialogTitle';
import { deleteDebate } from '../store/debates.slice';
import { openDialog, closeDialog } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
}));

function DebateDelete(props) {
  const {
    show,
    // debate,
    openDialog,
    onOpen,
    onClose,
    onDelete,
  } = props;
  const classes = useStyles();
  const history = useHistory();


  const handleDelete = (event) => {
    event.preventDefault();
    onDelete()
      .then(res => {
        history.push('/discuss/debates');
      });
  };

  const buildTrigger = () => (
    <IconButton
      size="small"
      aria-label="Delete debate"
      onClick={onOpen}
    >
      <DeleteIcon />
    </IconButton>
  );

  const buildActions = () => (<>
    <Button
      variant="contained"
      size="small"
      onClick={onClose}
    >Cancel</Button>
    <Button
      variant="contained"
      color="secondary"
      size="small"
      // className={classes.button}
      startIcon={<DeleteIcon />}
      onClick={handleDelete}
    >Delete</Button>
  </>);

  // console.log('DebateDimensionsCast', props); // DEBUG

  return (
    <div className={classes.root}>
      {show && buildTrigger()}
      <Dialog
        open={openDialog}
        // maxWidth="md"
        fullWidth={true}
        onClick={onClose}
      >
        {/* <DialogTitle onClose={handleClose}>Cognitive ranking for the debate</DialogTitle> */}
        <DialogContent>
          Do you really want delete this debate?
        </DialogContent>
        <DialogActions>
          {buildActions()}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const debate = ownProps.debate;
  const dialogState = state.discuss.layout.dialogs;
  const positionsState = state.discuss.positions;
  // const authUser = state.auth.profile;
  const uid = _.get(state.auth.profile, 'uid', false);
  return {
    openDialog: dialogState.debateDelete === debate.id,
    show: uid === debate.metadata.createdBy
      && _.isEmpty(positionsState.byId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const debate = ownProps.debate;
  return {
    onDelete: () => {
      return dispatch(deleteDebate(debate))
        .then(res => {
          // @todo delete image as well
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(closeDialog('debateDelete'));
          }
          return res;
        });
    },
    onOpen: () => dispatch(openDialog({
      resourceName: 'debateDelete',
      resourceId: debate.id,
    })),
    onClose: () => dispatch(closeDialog('debateDelete')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebateDelete);
