import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ArgumentLeaf from './ArgumentLeaf';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1.5em',
    maxWidth: 150,
  },
  positionEdge: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    '& > div.line': {
      position: 'absolute',
      bottom: '0px',
      left: 'calc(50%)',
      borderLeft: 'solid white 1px',
      width: '100%',
      height: '100%',
    },
    '& > div.curve': {
      position: 'absolute',
      bottom: 'calc(50%)',
      left: 'calc(50%)',
      borderLeft: 'solid white 1px',
      borderBottom: 'solid white 1px',
      borderRadius: '0px 5px',
      width: '100%',
      height: '100%',
    }
  },
  claimsEdge: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    '& > div.line': {
      position: 'absolute',
      top: 'calc(50%)',
      left: '0px',
      borderTop: 'solid white 1px',
      width: '100%',
      height: '100%',
    },
    '& > div.curve': {
      position: 'absolute',
      top: 'calc(50%)',
      right: 'calc(40%)',
      borderTop: 'solid white 1px',
      borderRight: 'solid white 1px',
      borderRadius: '0px 5px',
      width: '100%',
      height: '100%',
    }
  }
}));

function ArgumentTree(props) {
  const {
    debate,
    onClickPosition,
    onClickClaim,
  } = props;
  const classes = useStyles();

  // console.log('ArgumentTree', props); // DEBUG

  const lastPositionIndex = debate.positions.length - 1;

  const PositionEdge = ({ line, curve, size }) => {
    if (_.isUndefined(line)) line = true;
    if (_.isUndefined(curve)) curve = true;
    return (
      <Box className={classes.positionEdge} style={{ height: `${size}px`}}>
        &nbsp;
        {line && <div className="line"></div>}
        {curve && <div className="curve"></div>}
      </Box>
    );
  };

  const ClaimEdge = ({ line, curve }) => {
    if (_.isUndefined(line)) line = true;
    if (_.isUndefined(curve)) curve = true;
    return (
      <Box className={classes.claimsEdge}>
        &nbsp;
        {line && <div className="line"></div>}
        {curve && <div className="curve"></div>}
      </Box>
    );
  };

  const IssueRow = ({ entity }) => (
    <Grid container spacing={0}>
      <Grid item xs={3}>
        <Box mb={1}>
          <ArgumentLeaf
            entity={entity}
            onClick={() => {
              console.log('ArgumentTree', 'scroll to the top', '@todo');
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const PositionRow = ({ entity, isLastRow }) => {
    // @todo now it is possible improve it
    const hasSupportings = !_.isEmpty(entity.supportings);
    const hasOpposings = !_.isEmpty(entity.opposings);
    return (
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <PositionEdge line={!isLastRow} />
        </Grid>
        <Grid item xs={3}>
          <Box mt={1} mb={1}
            // style={{ backgroundColor: 'white' }}
          >
            <ArgumentLeaf
              entity={entity}
              onClick={onClickPosition}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <ClaimEdge line={hasSupportings} curve={hasOpposings} />
        </Grid>
        <Grid item xs={3}>
          <ClaimEdge line={false} curve={hasSupportings} />
        </Grid>
      </Grid>
    );
  };

  const ClaimsRow = ({ supportings, opposings, isLastRow }) => (
    <Grid container spacing={0}>
      <Grid item xs={3}>
        <PositionEdge line={!isLastRow} curve={false} />
      </Grid>
      <Grid item xs={3}>
        {/* padding */}
      </Grid>
      <Grid item xs={3}>
        {claims(opposings)}
      </Grid>
      <Grid item xs={3}>
        {claims(supportings)}
      </Grid>
    </Grid>
  );

  function claims(claims) {
    return !_.isEmpty(claims) && (
      <Box mt={1} ml={1}>
        {_.map(claims, (item, index) => (
          <Box key={index}>
            {index > 0 && <PositionEdge line={true} curve={false} size={7} />}
            <ArgumentLeaf
              entity={item}
              onClick={onClickClaim}
            />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <IssueRow entity={debate} />
      {debate.positions.map((item, index) => {
        const isLastRow = index === lastPositionIndex;
        return (
          <div key={index}>
            <PositionRow entity={item} isLastRow={isLastRow} />
            <ClaimsRow
              supportings={item.supportings}
              opposings={item.opposings}
              isLastRow={isLastRow}
            />
          </div>
        );
      })}
    </div>
  );
}

ArgumentTree.propTypes = {
  debate: PropTypes.object.isRequired,
};

export default ArgumentTree;
