import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import DialogTitle from 'components/DialogTitle';
import SignInOrSignup from 'features/auth/SignInOrSignup';
import DebateForm from './components/DebateForm';
import { addDebate, editDebate, saveDebateImage } from '../store/debates.slice';
import { openDialog, closeDialog } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

function DebateCreate(props) {
  const {
    className,
    pending,
    error,
    authUser,
    openDialog,
    onOpen,
    onClose,
    onSave,
  } = props;
  const classes = useStyles();
  const [values, setValues] = useState(null);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    const _value = _.isEmpty(files) ? value : files[0];
    setValues({ ...values, [name]: _value });
  };

  const handleSave = (values) => {
    // console.log('handleSave', values); // DEBUG
    if (pending) return;
    return onSave(values)
      .then(res => {
        if (!Boolean(error)) {
          // setOpenDialog(false);
          // @todo prompt down in the snackbar 'save success'
          //       OR better redirect into the new debate
        }
      });
  }

  const buildTrigger = () => (
    <Tooltip
      title="Start a debate"
      placement="left"
    >
      <Fab
        className={clsx(classes.root, className)}
        color="primary"
        aria-label="add debate"
        onClick={onOpen}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  );

  const buildContent = () => (<>
    <DebateForm
      error={error}
      onSubmit={handleSave}
      onCancel={onClose}
    />
  </>);

  // const buildActions = () => (<>
  //   <Button
  //     variant="contained"
  //     size="small"
  //     onClick={onClose}
  //   >Cancel</Button>
  //   <Button
  //     // type="submit"
  //     variant="contained"
  //     color="primary"
  //     size="small"
  //     disabled={pending}
  //     startIcon={<SaveIcon />}
  //     onClick={handleSave}
  //   >Save</Button>
  // </>);

  // console.log("CreateDebate", props); // DEBUG

  return <>
    {buildTrigger()}

    {Boolean(authUser)
      ? <Dialog
          open={openDialog}
          // fullWidth
          // maxWidth={false}
          onClose={onClose}
        >
          <DialogTitle onClose={onClose}>Start a new debate</DialogTitle>
          {/* <Container>
            <DebateForm
              pending={pending}
              error={error}
              onSubmit={handleSave}
              onCancel={handleCloseDialog}
            />
          </Container> */}
          <DialogContent>
            {buildContent()}
          </DialogContent>
          <DialogActions>
            {/* {buildActions()} */}
          </DialogActions>
        </Dialog>
      : <SignInOrSignup
          openDialog={openDialog}
          onCloseDialog={onClose}
        />
    }
  </>;
}

const mapStateToProps = (state) => {
  const debatesState = state.discuss.debates;
  const dialogState = state.discuss.layout.dialogs;
  return {
    pending: debatesState.pending,
    error: debatesState.error,
    authUser: state.auth.profile,
    openDialog: Boolean(dialogState.debateCreate)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: async values => {
      let addedDebateStatus = true,
          savedImageStatus = true,
          editedDebateStatus = true;
      const addedDebate = await dispatch(addDebate({
        ...values,
        type: 'ISSUE',
      }));
      addedDebateStatus = addedDebate.meta.requestStatus === 'fulfilled';
      if (addedDebateStatus) {
        if (_.has(values, 'image')) {
          const image = _.get(values, 'image');
          const debate = addedDebate.payload;
          const savedImage = await dispatch(saveDebateImage({image, debate}));
          savedImageStatus = savedImage.meta.requestStatus === 'fulfilled';
          if (savedImageStatus) {
            const debate = savedImage.payload;
            const editedDebate = await dispatch(editDebate(debate));
            editedDebateStatus = editedDebate.meta.requestStatus === 'fulfilled';
          }
        }
      }
      if (addedDebateStatus && savedImageStatus && editedDebateStatus) {
        dispatch(closeDialog('debateCreate'));
      }
      // return; // @todo ??? EDIT: see how made in CreatePosition
    },
    onOpen: () => dispatch(openDialog({
      resourceName: 'debateCreate',
      resourceId: true,
    })),
    onClose: () => dispatch(closeDialog('debateCreate')),
  }
};

// @todo how to use redux connect withstyles together
// @see https://stackoverflow.com/questions/45704681/react-material-ui-export-multiple-higher-order-components

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebateCreate);
