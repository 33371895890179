import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import LeftDrawerList from './LeftDrawerList';

import { toggleLeftDrawer } from 'features/layout/layout.slice';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

function LeftDrawer(props) {
  const { open, onClose } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
    >

      <div
        className={classes.list}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >


        {/* @todo
            - merge LeftDrawer and LeftDrawerList
            - take list from props
        */}
        <LeftDrawerList />


      </div>

    </Drawer>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.layout.leftDrawer.open
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(toggleLeftDrawer())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftDrawer);
