import { combineReducers } from 'redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import participantsReducer, { fetchParticipants } from './participants.slice';
import debatesReducer, { selectDebate, unselectDebate, fetchDebate } from './debates.slice';
import summaryReducer, { fetchSummary } from './summary.slice';
import positionsReducer, { fetchPositions, resetPositions } from './positions.slice';
import positionAgreementsReducer, { fetchPositionAgreements, resetPositionAgreements } from './positionAgreements.slice';
import positionThumbsReducer, { fetchPositionThumbs } from './positionThumbs.slice';
import positionHeartsReducer, { fetchPositionHearts } from './positionHearts.slice';
import positionDimensionsReducer, { seedPositionDimensions } from './positionDimensions.slice';
import claimsReducer, { fetchClaims } from './claims.slice';
import claimAgreementsReducer, { resetClaimAgreements } from './claimAgreements.slice';
import claimThumbsReducer, { fetchClaimThumbs } from './claimThumbs.slice';
import claimHeartsReducer, { fetchClaimHearts } from './claimHearts.slice';
import claimDimensionsReducer, { seedClaimDimensions } from './claimDimensions.slice';

function falsify(collection) {
  return _.reduce(collection, (result, value, key) => {
    result[key] = false;
    return result;
  }, {});
}

function shouldCallAPI(state, id) {
  return !_.has(state.discuss.debates.byId, id);
}

export const loadDiscussion = createAsyncThunk(
  'discuss/loadDiscussion',
  async (debateId, { dispatch, getState }) => {
    const state = getState();
    if (shouldCallAPI(state, debateId)) {
      const res = await dispatch(fetchDebate(debateId));
      if (_.has(res, 'error')) {
        throw new Error(res.error.message);
      }
    }
    dispatch(selectDebate(debateId));
    return await Promise.all([
      dispatch(fetchSummary(debateId)),
      dispatch(fetchParticipants(debateId)),
      dispatch(fetchPositions(debateId)),
      dispatch(fetchPositionAgreements(debateId)), // @todo
      dispatch(seedPositionDimensions(debateId)),
      dispatch(fetchPositionThumbs(debateId)),
      dispatch(fetchPositionHearts(debateId)),
      dispatch(fetchClaims(debateId)),
      dispatch(fetchClaimThumbs(debateId)),
      dispatch(fetchClaimHearts(debateId)),
      dispatch(seedClaimDimensions(debateId)),
    ]);
  }
);

export const unloadDiscussion = createAsyncThunk(
  'discuss/unloadDiscussion',
  async (debateId, { dispatch, getState }) => { // @todo debateId ???
    console.log('discuss/unloadDiscussion', '@todo'); // @todo
    // dispatch(unselectDebate());
    return await Promise.all([
      // dispatch(resetPositions()),
      // dispatch(resetPositionAgreements()),
      // @todo
      // dispatch(resetPositionDimensions()),
      // dispatch(resetClaims()),
      // dispatch(resetClaimDimensions()),
      // dispatch(resetParticipants()),
      // dispatch(closeDebateDrawer()),
    ]);
  }
);

const initialState = {
  leftDrawers: {
    summary: false,
    // argumentMap: false,
    // debateHealth: false,
  },
  rightDrawers: {
    // summary: false,
    argumentMap: true,
    // debateHealth: false,
  },
  dialogs: {
    debateCreate: null,
    debateImageEdit: null,
    debateEdit: null,
    debateDelete: null,
    positionEdit: null,
    positionDelete: null,
    positionTimeline: null,
    positionDimensions: null,
    claimEdit: null,
    claimDelete: null,
    claimDimensions: null,
  },
  positionCards: {
    // 'positionId': {
    //   collapsed: false,
    // }
  },
  scrollToBottom: true,
  loading: false,
  ready: false,
  error: null,
  // currentDebateId: null,
};

const slice = createSlice({
  name: 'discuss',
  initialState,
  reducers: {
    // ---------------------------------------------------- Drawers
    toggleLeftDrawer: (state, action) => {
      const resourceName = action.payload;
      const oldValue = state.leftDrawers[resourceName];
      state.leftDrawers[resourceName] = !oldValue;
    },
    // openLeftDrawer: (state, action) => {
    //   const resourceName = action.payload;
    //   state.leftDrawers = falsify(state.leftDrawers);
    //   state.leftDrawers[resourceName] = true;
    // },
    // closeLeftDrawer: (state) => {
    //   state.leftDrawers = falsify(state.leftDrawers);
    // },
    toggleRightDrawer: (state, action) => {
      const resourceName = action.payload;
      const oldValue = state.rightDrawers[resourceName];
      state.rightDrawers[resourceName] = !oldValue;
    },
    // openRightDrawer: (state, action) => {
    //   const resourceName = action.payload;
    //   state.rightDrawers = falsify(state.rightDrawers);
    //   state.rightDrawers[resourceName] = true;
    // },
    // closeRightDrawer: (state) => {
    //   state.rightDrawers = falsify(state.rightDrawers);
    // },
    // ---------------------------------------------------- Dialogs
    openDialog: (state, action) => {
      const resourceName = action.payload.resourceName;
      const resourceId = action.payload.resourceId;
      state.dialogs[resourceName] = resourceId;
    },
    closeDialog: (state, action) => {
      const resourceName = action.payload;
      state.dialogs[resourceName] = null;
    },
    // ---------------------------------------------------- positionCards
    togglePositionCard: (state, action) => {
      const positionId = action.payload;
      const oldState = _.get(state.positionCards, positionId, { collapsed: false });
      state.positionCards[positionId] = {
        ...oldState,
        collapsed: !oldState.collapsed,
      };
    },
    expandPositionCard: (state, action) => {
      const positionId = action.payload;
      const oldState = _.get(state.positionCards, positionId, { collapsed: false });
      state.positionCards[positionId] = {
        ...oldState,
        collapsed: false,
      };
    },
    collapsePositionCard: (state, action) => {
      const positionId = action.payload;
      const oldState = _.get(state.positionCards, positionId, { collapsed: false });
      state.positionCards[positionId] = {
        ...oldState,
        collapsed: true,
      };
    },
    // ---------------------------------------------------- toggleScrollToBottom
    toggleScrollToBottom: (state) => {
      state.scrollToBottom = !state.scrollToBottom;
    },
    letScrollToBottom: (state) => {
      state.scrollToBottom = true;
    },
    resetScrollToBottom: (state) => {
      state.scrollToBottom = false;
    },
    // ----------------------------------------------------
    // focusOnReplyParent(state, action) {
    //   state.replyParentId = action.payload;
    // },
    // unfocusReplyParent(state) {
    //   state.replyParentId = null;
    // },
    // unfocusAll(state) {
    //   // state.replyParentId = null;
    //   state.createClaimId = null;
    // },
  },
  extraReducers: {
    // ---------------------------------------------------- loadDiscussion
    [loadDiscussion.pending]: (state) => {
      state.loading = true;
      state.ready = false;
      state.error = null;
    },
    [loadDiscussion.fulfilled]: (state) => {
      state.loading = false;
      state.ready = true;
    },
    [loadDiscussion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // ---------------------------------------------------- unloadDiscussion
    // @todo unloadDiscussion ...
  },
});

const { actions, reducer } = slice;
export const {
  // openLeftDrawer, closeLeftDrawer,
  // openRightDrawer, closeRightDrawer,
  toggleLeftDrawer, toggleRightDrawer,
  openDialog, closeDialog,
  togglePositionCard, expandPositionCard, collapsePositionCard,
  letScrollToBottom, resetScrollToBottom,
  unfocusAll,
} = actions;

export default combineReducers({
  layout: reducer,
  debates: debatesReducer,
  summary: summaryReducer,
  positions: positionsReducer,
  positionAgreements: positionAgreementsReducer,
  positionThumbs: positionThumbsReducer,
  positionHearts: positionHeartsReducer,
  positionDimensions: positionDimensionsReducer,
  claims: claimsReducer,
  claimAgreements: claimAgreementsReducer,
  claimThumbs: claimThumbsReducer,
  claimHearts: claimHeartsReducer,
  claimDimensions: claimDimensionsReducer,
  participants: participantsReducer,
});
