import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import EmojiViewer from 'components/EmojiViewer';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    padding: '40px 90px 50px 90px',
  },
}));

function ClaimAgreementView(props) {
  const {
    className,
    // pending,
    // error,
    claim,
    agreement,
    average,
    // authUser,
  } = props;
  const classes = useStyles();

  // console.log('ClaimAgreementView', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)}>
      <EmojiViewer
        name={`claim-agreement-view-${claim.id}`}
        value={agreement}
        average={average}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const claimId = ownProps.claim.id;
  const agreementsState = state.discuss.claimAgreements;
  return {
    // pending: agreementsState.pending,
    // error: agreementsState ? agreementsState.error : null,
    agreement: _.get(agreementsState, `byId[${claimId}].data.level`, null),
    average: _.get(agreementsState, `byId[${claimId}].average`, null),
  };
};

export default connect(mapStateToProps)(ClaimAgreementView);
