import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  empty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

function FillableContainer(props) {
  const {
    empty,
    message,
    children,
  } = props;
  const classes = useStyles();

  return empty
    ? <div className={classes.empty}>
        <p>{message}</p>
      </div>
    : children;
}

FillableContainer.propTypes = {
  empty: PropTypes.bool.isRequired,
};

FillableContainer.defaultProps = {
  message: 'There are no items yet.',
};

export default FillableContainer;
