import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';

import RecIcon from './RecIcon';
import Time from './Time';
import ClearButton from './ClearButton';
import DoneButton from './DoneButton';

const audioType = 'audio/webm';

const styles = theme => ({
  root: {
    display: 'flex',
    boxSizing: 'content-box',
    width: 170,
  },
  timer: {
    padding: '0px 5px',
    '& > span': {
      padding: '0px 5px',
      display: 'inline-flex', verticalAlign: 'middle',
      lineHeight: '32px',
      fontSize: '20px',
    }
  },
});

// @note some implementation
// @see https://www.youtube.com/watch?v=_XF69WYZOSs
// @see https://github.com/sivaprakashDesingu/react-voice-recorder/blob/8bf981f9e4cfaf7825cfd5a0c4017d976bc437ef/src/components/Recorder.js#L99
// @see https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder
class Recorder extends Component {

  async componentDidMount() {
    console.log('Recorder mounted'); // DEBUG
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (navigator.mediaDevices) {
      const constraints = { audio: true };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.mediaRecorder = new MediaRecorder(stream, { mimeType: audioType });
      this.chunks = [];
      this.mediaRecorder.ondataavailable = e => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };
    } else {
      console.warn("Media Decives will work only with SSL.....");
    }

    // temp code
    if (this.props.start) {
      this.mediaRecorder.start(10);
    }
  }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate', 'start'); // DEBUG
  //   if (this.props.start && this.props.start !== 'none' && prevProps.start !== this.props.start) {
  //     this.mediaRecorder.start();
  //   }
  // }

  componentWillUnmount() {
    console.log('Recorder dismounted'); // DEBUG
    if (Boolean(this.mediaRecorder)) {
      this.mediaRecorder.stream
        .getTracks()
        .forEach(track => track.stop());
    }
  }

  handleClear(event) {
    this.mediaRecorder.stop();
    this.chunks = [];
    return this.props.onClear(event);
  }

  handleDone(event) {
    this.mediaRecorder.stop();
    const blob = new Blob(this.chunks, { type: audioType });
    // const audioURL = URL.createObjectURL(blob);
    // console.log(audioURL); // DEBUG
    return this.props.onDone(event, blob);
  }

  render() {
    const { classes, className } = this.props;
    return (
      <div className={clsx(classes.root, className)}>
        <ClearButton onClear={(e) => { this.handleClear(e) }} />
        <div className={classes.timer}>
          <RecIcon pulse={true} />
          <Time />
        </div>
        <DoneButton onDone={(e) => { this.handleDone(e) }} />
      </div>
    )
  }

}

Recorder.propTypes = {
  classes: PropTypes.object.isRequired,
};

Recorder.defaultProps = {
  start: false,
};

export default withStyles(styles)(Recorder);
