import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
// import AssessmentIcon from '@material-ui/icons/Assessment';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { Radar } from 'react-chartjs-2';

import DialogTitle from 'components/DialogTitle';
import ClaimAgreementCast from './ClaimAgreementCast';
import ClaimAgreementView from './ClaimAgreementView';
import DimensionSlider from '../components/DimensionSlider';
import { loadClaimAgreement } from '../store/claimAgreements.slice';
import { loadClaimDimension, saveClaimDimension } from '../store/claimDimensions.slice';
import { openDialog, closeDialog } from '../store/discuss.slice';
// import ThinkingImage from 'icons/Thinking';
import FeedbackIcon from 'icons/Feedback';
import LikeIcon from 'icons/Like';
import ThumbsIcon from 'icons/Thumbs';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  trigger: {
    padding: '0px 5px',
    height: 'inherit',
    color: 'inherit',
  },
  label: {
    // border: 'solid black 1px',
    padding: '3px 5px',
  },
  recap: {
    // border: 'solid black 1px', // DEBUG
    marginTop: -5,
    marginBottom: 15,
  },
}));

const maxSteps = 3;
const lastStep = maxSteps - 1;

// const defaultAgreement = 0; // self agreement

function getTitle(step) {
  switch(step) {
    case 0: return 'How do you feel about this argument?';
    case 1: return 'Do you agree that';
    case 2: return 'Reflections feedback';
    default:
      return ''; // @todo error
  }
};

const defaultLevels = {
  polarized: 50,
  unclear: 50,
  evidences: 50,
  agree: 50, // forcast agreement
};

const radarOptions = {
  scale: {
    ticks: {
      suggestedMin: 0,
      suggestedMax: 100,
      // stepSize: 1
    }
  }
};

const radarLabels = [
  'This is a very polarized argument',
  'It is unclear if I can trust this',
  'This needs more evidences',
  'Most people would agree with this',
];

// @note we need this function because the sorting is not garantee
function toArray(values) {
  // console.log('Claim.toArray(values)', values); // DEBUG
  return [
    _.get(values, 'polarized', 0),
    _.get(values, 'unclear', 0),
    _.get(values, 'evidences', 0),
    _.get(values, 'agree', 0),
  ];
}

function meDataset(values) {
  return {
    label: 'Me',
    backgroundColor: 'rgba(60,103,188,0.2)',
    borderColor: 'rgba(60,103,188,1)',
    pointBackgroundColor: 'rgba(60,103,188,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(60,103,188,1)',
    borderWidth: 1,
    // data: _.values(values),
    data: toArray(values),
  };
};

function groupDataset(values) {
  return {
    label: 'Group Average',
    backgroundColor: 'rgba(255,192,46,0.2)',
    borderColor: 'rgba(255,192,46,1)',
    pointBackgroundColor: 'rgba(255,192,46,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(255,192,46,1)',
    borderWidth: 1,
    // data: _.values(values),
    data: toArray(values),
  };
};

const getTooltipTitle = (total) => {
    return total > 0
      ? `${total} people reflected on this`
      : 'Nobody reflected on this yet';
  };

function ClaimDimensionsCast(props) {
  const {
    claim,
    variant,
    pending,
    // error,
    openDialog,
    votingUsers,
    dimensions,
    averages,
    authUser,
    onLoad,
    onSave,
    onClose,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [draft, setDraft] = useState(false);
  const [levels, setLevels] = useState(defaultLevels);
  const [activeStep, setActiveStep] = useState(0);

  const handleSlide = name => (event, value) => {
    // console.log('PositionDimensionsCast.handleSlide', name, value); // DEBUG
    event.preventDefault();
    setLevels({ ...levels, [name]: value });
    setDraft(true);
  }

  const handleSave = (event) => {
    // console.log('CastClaimDimensions.handleSave', levels); // DEBUG
    event.preventDefault();
    onSave(levels);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const buildFirstStep = () => (<>
    <ClaimAgreementCast claim={claim} />
  </>);

  // const buildTrigger = () => (
  //   <Link
  //     style={{ fontSize: '12px' }} // temp
  //     href="#"
  //     color="secondary"
  //     onClick={e => {
  //       e.preventDefault();
  //       onLoad();
  //     }}
  //   >
  //     Reflect
  //   </Link>
  // );

  const buildTrigger = () => (
    <Tooltip title="Reflect" placement="top" arrow>
      <IconButton
        size="small"
        // aria-label="Open position cognitive dimensions"
        onClick={onLoad}
      >
        {/* <ThumbsIcon /> */}
        <LikeIcon />
        {/* <LikeIcon variant={variant} /> */}
        {/* <FeedbackIcon variant={variant} /> */}
      </IconButton>
    </Tooltip>
  );

  // const buildTrigger_ = () => (
  //   <Button
  //     className={classes.trigger}
  //     variant="contained"
  //     color="secondary"
  //     size="small"
  //     startIcon={<ThinkingImage />}
  //     // startIcon={<AssessmentIcon />}
  //     aria-label="Open argument cognitive dimensions"
  //     onClick={onLoad}
  //   >
  //     Reflect
  //   </Button>
  // );

  const buildSecondStep = () => (
    <div className={classes.commands}>
      <DimensionSlider
        variant="Primary"
        label={<>This is a very <strong>polarized</strong> argument</>}
        name={`pos-cd-polarized-${claim.id}`}
        value={levels.polarized}
        onChange={handleSlide('polarized')}
      />
      <DimensionSlider
        variant="Primary"
        label={<>It is <strong>unclear</strong> if I can <strong>trust</strong> this</>}
        name={`pos-cd-unclear-${claim.id}`}
        value={levels.unclear}
        onChange={handleSlide('unclear')}
      />
      <DimensionSlider
        variant="Secondary"
        label={<>This needs more <strong>evidences</strong></>}
        name={`pos-cd-evidences-${claim.id}`}
        value={levels.evidences}
        onChange={handleSlide('evidences')}
      />
      <DimensionSlider
        variant="Secondary"
        label={<>Most people would <strong>agree</strong> with this</>}
        name={`pos-cd-agree-${claim.id}`}
        value={levels.agree}
        onChange={handleSlide('agree')}
      />
    </div>
  );

  const buildThirdStep = () => (<>
    <div className={classes.recap}>
      &laquo;{claim.text}&raquo;
    </div>
    <div style={{textAlign: 'center'}}>
      <ClaimAgreementView claim={claim} />
    </div>
    <div className={classes.radar}>
      <Radar
        options={radarOptions}
        data={{
          labels: radarLabels,
          datasets: [
            meDataset(levels),
            groupDataset(averages),
          ],
        }}
      />
    </div>
  </>);

  const buildStepper = () => (
    <MobileStepper
      style={{ width: '100%' }}
      variant="dots"
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      backButton={
        <Button size="small"  disabled={activeStep === 0} onClick={handleBack}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Back
        </Button>
      }
      nextButton={
        activeStep < maxSteps - 1
          ? <Button size="small" onClick={handleNext}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          : <EndButton save={draft} />
      }
    />
  );

  const EndButton = memo(({ save }) => {
    return (
      <div>
        {dimensions &&
          <Button variant="outlined" color="primary" size="small"
            onClick={() => { setActiveStep(0) }}
          >Rethink it through</Button>
        }
        &nbsp;
        {dimensions === null || save
          ? <Button variant="contained" color="primary" size="small"
              disabled={activeStep === maxSteps} startIcon={<SaveIcon />}
              onClick={handleSave}
            >Save</Button>
          : <Button variant="contained" color="primary" size="small"
              disabled={activeStep === maxSteps}
              onClick={onClose}
            >Close</Button>
        }
      </div>
    );
  });

  useEffect(() => {
    if (!pending && dimensions !== null) {
      setLevels(dimensions);
      setDraft(false);
      setActiveStep(lastStep);
    }
  }, [pending, dimensions]);

  useEffect(() => {
    if (!Boolean(authUser)) {
      setActiveStep(lastStep);
    }
  }, [authUser]);

  // console.log('ClaimDimensionsCast', props); // DEBUG

  return (
    <>
    {/* <div className={classes.root}> */}
      <div className={classes.root}>
        <div className={classes.button}>{buildTrigger()}</div>
        <div className={classes.label}>
          <Tooltip title={getTooltipTitle(votingUsers)} placement="top" arrow>
            <Typography>
              {votingUsers}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <Dialog
        open={openDialog}
        // maxWidth="md"
        fullWidth={true}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>{getTitle(activeStep)}</DialogTitle>
        <DialogContent>
          {activeStep === 0 && buildFirstStep()}
          {activeStep === 1 && buildSecondStep()}
          {activeStep === 2 && buildThirdStep()}
        </DialogContent>
        <DialogActions>
          {Boolean(authUser)
            ? buildStepper()
            : <Button variant="contained" color="primary" size="small"
                onClick={onClose}
              >Close</Button>
          }
        </DialogActions>
      </Dialog>
    {/* </div> */}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const claimId = ownProps.claim.id;
  const dimentionsState = state.discuss.claimDimensions;
  const dialogState = state.discuss.layout.dialogs;
  const selected = _.get(dimentionsState, `byId[${claimId}]`, null);
  return {
    openDialog: dialogState.claimDimensions === claimId,
    pending: _.get(selected, 'pending', false),
    error: _.get(selected, 'error', null),
    votingUsers: _.get(selected, 'votingUsers', 0),
    dimensions: _.get(selected, 'data', null),
    averages: _.get(selected, 'averages', null),
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const claim = ownProps.claim;
  return {
    onSave: values => {
      const entry = {
        debateId: claim.debateId,
        positionId: claim.positionId,
        claimId: claim.id,
        levels: values,
      };
      return dispatch(saveClaimDimension(entry))
        .then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(closeDialog('claimDimensions'));
          }
          return res;
        });
    },
    onLoad: () => {
      const loadAgreement = dispatch(loadClaimAgreement(claim));
      const loadDimension = dispatch(loadClaimDimension(claim));
      return Promise.all([ loadAgreement, loadDimension ])
        .then(res => {
          // console.log('ClaimDimensionsCast.onLoad()', res) // DEBUG
          dispatch(openDialog({
            resourceName: 'claimDimensions',
            resourceId: claim.id,
          }));
          return res;
        });
    },
    onClose: () => dispatch(closeDialog('claimDimensions')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimDimensionsCast);
