import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
// import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Summery from './Summary';
// import ArgumentMap from './ArgumentMap';
// import DebateHealth from './DebateHealth';
// import { closeLeftDrawer } from '../store/discuss.slice';
import { toggleLeftDrawer } from '../store/discuss.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 10px',
    paddingBottom: 10,
    height: '100%',
  },
  closeButton: {
    position: 'absolute', top: 15, right: 10,
  },
}));

function DebateDrawers(props) {
  const {
    className,
    showSummary,
    showArgumentMap,
    showDebateHealth,
    onClose,
  } = props;
  const classes = useStyles();

  const open = () => showSummary || showArgumentMap || showDebateHealth;

  // console.log('DebateDrawers', props); // DEBUG

  return (
    <div // @todo remove collapse ?
      className={clsx(classes.root, className)}
      style={{ display: open() ? 'block' : 'none' }}
    >
      <div className={classes.closeButton}>
        <IconButton
          size="small"
          onClick={onClose}
        >
          <ChevronLeftIcon />
        </IconButton>
      </div>

      {showSummary && <Summery />}
      {/* {showArgumentMap && <ArgumentMap />}
      {showDebateHealth && <DebateHealth />} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const debateDrawers = state.discuss.layout.leftDrawers;
  return {
    showSummary: debateDrawers.summary,
    // showArgumentMap: debateDrawers.argumentMap,
    // showDebateHealth: debateDrawers.debateHealth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onClose: () => dispatch(closeLeftDrawer()),
    onClose: () => dispatch(toggleLeftDrawer('summary')),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebateDrawers);
