import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// import Hamburger from 'features/layout/Hamburger';
import LogoIcon from 'icons/Logo';

import { SIGNUP_ROUTE, SIGNIN_ROUTE, SIGNOUT_ROUTE } from 'features/auth/auth.routes';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  title: {
    '& a div span': {
      fontSize: 18,
      fontWeight: 'bold',
    },
  },
  navbar: {
    display: 'flex',
    flexDirection: 'row',
  },
  filler: { flex: '1 1 auto' },
}));

export default function TopBar(props) {
  const { authUser } = props;
  const classes = useStyles();

  const item = (route, text) => (
    <ListItem button component={Link} to={route}>
      <ListItemText primary={text} />
    </ListItem>
  );

  const SignedInLinks = () => (
    <>
      {/* {item('/dashboard', 'Dashboard')} */}
      {item(SIGNOUT_ROUTE, 'Logout')}
    </>
  );

  const SignedOutLinks = () => (
    <>
      {item(SIGNIN_ROUTE, 'Login')}
      {item(SIGNUP_ROUTE, 'Signup')}
    </>
  );

  // @todo toolbar.dense and list.dense NOT in homepage

  return (
    <AppBar
      className={classes.root}
      position="fixed"
    >
      <Toolbar variant="dense">
        {/* <Hamburger /> */}

        <LogoIcon />
        <List className={classes.title} dense>
          {item('/', 'Bcause')}
        </List>

        {/* <List className={classes.title} dense>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <LogoIcon />
            </ListItemIcon>
            <ListItemText primary="Bcause" />
          </ListItem>
        </List> */}

        {/* <Typography className={classes.title} variant="h6">Bcause</Typography> */}
        <div className={classes.filler}></div>
        <List className={classes.navbar} dense>
          {item('/discuss/debates', 'Debates')}
          { _.isNull(authUser)
            ? <SignedOutLinks />
            : <SignedInLinks />
          }
        </List>
        {/* @todo Menu ? */}

      </Toolbar>
    </AppBar>
  );
}
