import React from 'react';
import { Route } from 'react-router-dom';

import DebateCardsBox from './boxes/DebateCardsBox';
import PositionCardsBox from './boxes/PositionCardsBox';
import ClaimCardsBox from './boxes/ClaimCardsBox';
import AgreementBox from './boxes/AgreementBox';
import DimentionsBox from './boxes/DimentionsBox';
import CognitiveRankingBox from './boxes/CognitiveRankingBox';
import ThumbRateBox from './boxes/ThumbRateBox';
import RecorderBox from './boxes/RecorderBox';

const baseUrl = '/sandbox';
export const   DEBATE_CARDS_ROUTE = `${baseUrl}/debate-cards`;
export const POSITION_CARDS_ROUTE = `${baseUrl}/position-cards`;
export const    CLAIM_CARDS_ROUTE = `${baseUrl}/claim-cards`;
export const      AGREEMENT_ROUTE = `${baseUrl}/agreement`;
export const     DIMENTIONS_ROUTE = `${baseUrl}/dimentions`;
export const     COGNITIVER_ROUTE = `${baseUrl}/cognitiver`;
export const     THUMB_RATE_ROUTE = `${baseUrl}/thumb-rate`;
export const       RECORDER_ROUTE = `${baseUrl}/recorder`;

export default (
  <Route path={baseUrl}>
    <Route path={DEBATE_CARDS_ROUTE} exact component={DebateCardsBox} />
    <Route path={POSITION_CARDS_ROUTE} exact component={PositionCardsBox} />
    <Route path={CLAIM_CARDS_ROUTE} exact component={ClaimCardsBox} />
    <Route path={AGREEMENT_ROUTE} exact component={AgreementBox} />
    <Route path={DIMENTIONS_ROUTE} exact component={DimentionsBox} />
    <Route path={COGNITIVER_ROUTE} exact component={CognitiveRankingBox} />
    <Route path={THUMB_RATE_ROUTE} exact component={ThumbRateBox} />
    <Route path={RECORDER_ROUTE} exact component={RecorderBox} />
  </Route>
);
