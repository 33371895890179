import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Popover from '@material-ui/core/Popover';
// import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
// import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import { grey } from '@material-ui/core/colors';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

import IssueIcon from 'icons/Issue';
import PositionIcon from 'icons/Position';
import OpposingIcon from 'icons/Opposing';
import SupportingIcon from 'icons/Supporting';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    textAlign: 'center',
    '& > svg': {
      display: 'block'
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverCard: {
    maxWidth: 300,
  },
  popoverCardContent: {},
}));

// @see https://zhenyong.github.io/react/docs/transferring-props.html
function ArgumentLeaf(props) {
  const {
    entity,
    onClick,
    ...other
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    event.preventDefault();
    onClick(data.id);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const PopoverCard = ({ entity }) => (
    <Card className={classes.popoverCard}>
      <CardContent className={classes.popoverCardContent}>
        { entity.title &&
          <Typography gutterBottom component="h5"
          //? <Typography gutterBottom variant="h5" component="h2"
              >{entity.title}</Typography>
        }
        { entity.text &&
          <Typography
            variant="body2" color="textSecondary" component="p"
            >{entity.text}</Typography>
        }
        { entity.media && <VolumeUpIcon /> }
      </CardContent>
    </Card>
  );

  // @todo improve
  // let icon;
  //      if (entity.name === 'I') icon = <ContactSupportOutlinedIcon fontSize="inherit" />;
  // else if (entity.name === 'P') icon = <EmojiObjectsOutlinedIcon fontSize="inherit" style={{ color: grey[400] }} />; // @todo make it better
  // else if (entity.name === 'S') icon = <AddCircleOutlineIcon fontSize="inherit" color="primary" />;
  // else if (entity.name === 'O') icon = <RemoveCircleOutlineIcon fontSize="inherit" color="secondary"/>;
  // else {
  //   icon = entity.name
  // }
  let icon;
       if (entity.name === 'I') icon = <IssueIcon />;
  else if (entity.name === 'P') icon = <PositionIcon />;
  else if (entity.name === 'S') icon = <SupportingIcon />;
  else if (entity.name === 'O') icon = <OpposingIcon />;
  else {
    icon = entity.name
  }

  // console.log('ArgumentLeaf', entity); // DEBUG

  return (
    <div className={classes.root}>
      <div
        className={classes.box}
        aria-haspopup="true"
        aria-owns={open ? 'mouse-over-popover' : undefined} // @todo check aria-owns meaning
        onClick={e => { handleClick(e, entity.data) }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        {...other}
      >
        {icon}
      </div>

      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PopoverCard entity={entity.data}/>
      </Popover>
    </div>
  );
}

ArgumentLeaf.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default ArgumentLeaf;
