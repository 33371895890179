import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import NotificationSnack from 'components/NotificationSnack';
import DebateCard from './debate/components/DebateCard';
import DebateCreate from './debate/DebateCreate';

import { fetchDebates,
  // resetDebateError /// @todo REMOVE ?
} from './store/debates.slice';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  noDebates: {
    paddingTop: theme.spacing(5),
    textAlign: 'center',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function DebatesPage(props) {
  const {
    loading,
    debates,
    needsReload,
    onMount,
    onReload,
    onDismount
  } = props;
  const classes = useStyles();

  const mount = useCallback(onMount, []);
  const dismount = useCallback(onDismount, []);
  useEffect(() => {
    mount();
    return () => dismount();
  }, [mount, dismount]);

  // console.log('DebatesPage', props); // DEBUG

  return (
    <Container className={classes.root}>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {_.isEmpty(debates) && !loading
        ? <div className={classes.noDebates}>
            <Typography variant="overline">There are no discussions yet.</Typography>
          </div>
        : <Grid container justify="flex-start" spacing={1}>
            {_.map(debates, (entry, index) => (
              <Grid item key={index}>
                <DebateCard entity={entry} />
              </Grid>
            ))}
          </Grid>
      }

      <DebateCreate className={classes.fab} />
      <NotificationSnack open={needsReload} onClick={onReload} />

    </Container>
  );
}

const mapStateToProps = (state) => {
  const debatesState = state.discuss.debates;
  return {
    loading: debatesState.pending,
    debates: debatesState.byId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: () => {
      dispatch(fetchDebates())
        // .then(res => {
        //   // console.log('DebatesPage.onMount()', res); // DEBUG
        //   // @note when there are no debates, firebase return by default
        //   //       an exception 'Resource not found' with no code associated,
        //   //       because on realtime DB can't be stored empty collections
        //   // @todo how to identify this false positive?
        //   // if (_.isUndefined(res.payload)) { // temp code
        //   //   dispatch(resetDebateError());
        //   // }
        // })
      ;
    },
    onReload: () => {

    },
    onDismount: () => {

    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebatesPage);
