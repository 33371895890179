import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const vLineSize = 3; // @todo move as a param

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    '&::before': {
      content: '""',
      borderLeft: `${vLineSize}px solid ${theme.palette.primary.main}`,
      position: 'absolute', top: 6, bottom: 12,
      left: `calc(50% - ${vLineSize - 2}px)`,
    },
  },
    header: {
      position: 'absolute', top: 0, left: 0, right: 0,
      padding: '2px 0px',
      textAlign: 'center',
      zIndex: theme.zIndex.timeline - 1,
    },
    content: {
      position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
      height: '100%',
      zIndex: theme.zIndex.timeline,
    },
    footer: {
      position: 'absolute', bottom: 0, left: 0, right: 0,
      padding: '2px 0px',
      textAlign: 'center',
      zIndex: theme.zIndex.timeline - 1,
    },
  bubble: {
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 20,
    marginTop: 4, // @todo find another way to fix this ?
    padding: theme.spacing(1),
    textAlign: 'center',
    visibility: 'hidden',
  },
  dots: {
    backgroundColor: theme.palette.primary.light,
    position: 'absolute', top: 0, left: 'calc(50% - 2px)',
    visibility: 'hidden',
  },
    dot: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      margin: '5px auto',
      width: '5px', height: '5px',
    },
  visible: {
    visibility: 'visible',
  },
}));

// @todo memo
const Bubble = ({ classes, show, label }) => (
  <div className={clsx(classes.bubble, { [classes.visible]: show })}>
    {label}
  </div>
);

const Dots = ({ classes, show }) => (
  <div className={clsx(classes.dots, { [classes.visible]: show })}>
    <div className={classes.dot}></div>
    <div className={classes.dot}></div>
    <div className={classes.dot}></div>
  </div>
);

function Timeline(props) {
  const {
    className,
    startLabel,
    endLabel,
    scrollable,
    scrollStatus,
    children,
  } = props;
  const classes = useStyles();
  // @todo use external constants
  const isTop = scrollStatus === 'top';
  const isBottom = scrollStatus === 'bottom';

  // console.log('Timeline', props); // DEBUG

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Bubble
          classes={classes}
          show={!scrollable || isTop}
          label={startLabel}
        />
        <Dots
          classes={classes}
          show={scrollable && !isTop}
        />
      </div>
      <div className={classes.content}>
        {children}
      </div>
      <div className={classes.footer}>
        <Bubble
          classes={classes}
          show={!scrollable || isBottom}
          label={endLabel}
        />
        <Dots
          classes={classes}
          show={scrollable && !isBottom}
        />
      </div>
    </div>
  );
}

// Timeline.propTypes = {
//   // entity: PropTypes.object.isRequired,
// };

export default Timeline;
