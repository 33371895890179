import React, { useState } from 'react';
import { connect } from 'react-redux';
// import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import SaveIcon from '@material-ui/icons/Save';

import DialogTitle from 'components/DialogTitle';
import SignInOrSignup from 'features/auth/SignInOrSignup';
import ClaimCardOpposing from './components/ClaimCardOpposing';
import ClaimCardSupporting from './components/ClaimCardSupporting';
// import ClaimReplayForm from './components/ClaimReplayForm';
import ClaimSwitch from './components/ClaimSwitch';
import PositionCard from '../positions/components/PositionCard';
import ParticipantLabel from '../participants/ParticipantLabel';
import { addClaim, focusClaim } from '../store/claims.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },
  head: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    textTransform: 'capitalize',
  },
  columns: {
    display: 'flex',
  },
  col: {
    width: '47.5%',
  },
  opp: {
    padding: 5,
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  sup: {
    padding: 5,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sep: {width: '5%'},

  positionWrapper: {
    padding: '5px 0px 10px 0px',
  },
}));

const getAgreement = (val) => {
  return val === 'SUPPORTING' ? 50 : -50;
};

const getText = (val) => {
  return val === 'SUPPORTING' ? 'support of' : 'opposition to';
};

const isPristine = (val) => {
  // console.log('ReplyToClaim.isPristine()', val); // DEBUG
  return _.isEmpty(val.trim());
};

const defaultState = {
  type: 'OPPOSING',
  positionAgreement: getAgreement(),
  text: '',
}

function ClaimReply(props) {
  const {
    position,
    parent,
    pending,
    error,
    authUser,
    onSave,
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [values, setValues] = useState(defaultState);

  const handleOpenDialog = (event) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event) => {
    setOpenDialog(false);
  };

  const handleTyping = (event) => {
    const { name, value } = event.target;
    // console.log('ReplyToClaim.handleTyping', name, value); // DEBUG
    setValues({ ...values, [name]: value });
  };

  const handleSwitch = (event, value) => {
    const { name } = event.target;
    // console.log('ReplyToClaim.handleSwitch', event, value); // DEBUG
    setValues({ ...values, [name]: value ? 'SUPPORTING' : 'OPPOSING' });
  };

  const handleSave = (event) => {
    event.preventDefault();
    // console.log('ReplyToClaim.handleSave', values); // DEBUG
    return onSave({
      ...values,
      positionAgreement: getAgreement(values.type),
    })
      .then(res => {
        if (!Boolean(error)) {
          handleCloseDialog();
        }
      });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    handleCloseDialog();
  };

  const buildTrigger = () => (
    <Tooltip title="Reply to" placement="top" arrow>
      <IconButton
        size="small"
        color="inherit"
        aria-label="Open reply to an argument dialog"
        onClick={handleOpenDialog}
      >
        <ReplyIcon />
      </IconButton>
    </Tooltip>
  );

  const buildContent = () => (<>
    <Box mb={1}>
      {parent.type === 'OPPOSING'
        ? <ClaimCardOpposing
            owner={<ParticipantLabel userId={parent.metadata.createdBy} />}
            entity={parent}
          />
        : <ClaimCardSupporting
            owner={<ParticipantLabel userId={parent.metadata.createdBy} />}
            entity={parent}
          />
      }
    </Box>
    <Box mb={1}>
      <TextField
        name="text"
        autoComplete="text" // @todo REMOVE it. Only for an easy develop
        label="Elaborate on your reply"
        variant="filled"
        rows={3}
        required
        multiline
        fullWidth
        // onChange={handleChange}
        onChange={handleTyping}
      />
    </Box>
    <Collapse
      in={!isPristine(values.text)}
      timeout="auto"
      unmountOnExit
    >
      <Box mb={1} align="center">
        <ClaimSwitch
          name="type"
          onChange={handleSwitch}
        />
      </Box>
      <Box mb={1} p={1}>
        This argument is in <strong>{getText(values.type)}</strong>
      </Box>
      <Box mb={1}>
        <PositionCard
          owner={<ParticipantLabel userId={position.metadata.createdBy} />}
          entity={position}
          expandable={false} />
      </Box>
    </Collapse>
  </>);

  const buildActions = () => (<>
    <Button
      variant="contained"
      size="small"
      onClick={handleCancel}
    >Cancel</Button>
    <Button
      variant="contained"
      color="primary"
      size="small"
      disabled={pending || isPristine(values.text)}
      startIcon={<SaveIcon />}
      onClick={handleSave}
    >Save</Button>
  </>);

  return (
    <div className={classes.root}>
      {buildTrigger()}
      {Boolean(authUser)
        ? <Dialog
            open={openDialog}
            maxWidth="xs"
            fullWidth={true}
            onClose={handleCloseDialog}
          >
            <DialogTitle>Reply to</DialogTitle>
            <DialogContent>
              {buildContent()}
            </DialogContent>
            <DialogActions>
              {buildActions()}
            </DialogActions>
          </Dialog>
        : <SignInOrSignup
            openDialog={openDialog}
            onCloseDialog={handleCloseDialog}
          />
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const claimsState = state.discuss.claims;
  return {
    pending: claimsState.pending,
    error: claimsState.error,
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // onSave: values => dispatch(addClaim({
    //   ...values,
    //   debateId: ownProps.parent.debateId,
    //   positionId: ownProps.parent.positionId,
    //   parentId: ownProps.parent.id,
    // })),
    onSave: values => {
      const newEntity = {
      ...values,
      debateId: ownProps.parent.debateId,
      positionId: ownProps.parent.positionId,
      parentId: ownProps.parent.id,
      };
      return dispatch(addClaim(newEntity))
        .then(res => {
          const entity = res.payload;
          dispatch(focusClaim(entity.id));
          return res;
        })
      ;
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimReply);
