import React from 'react'
import _ from 'lodash';

// @see https://stackoverflow.com/questions/38510443/how-to-use-an-svg-file-in-a-svgicon-in-material-ui
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';


// import coloredSvg from 'assets/icons/reflect/customer-satisfaction.svg';
// import coloredSvg from 'assets/icons/reflect/reflect2.svg';
// import coloredSvg from 'assets/icons/reflect/reflect3.svg';
import coloredSvg from 'assets/icons/reflect/reflect3_1.svg';
// import coloredSvg from 'assets/icons/reflect/reflect4.svg';
// import whiteSvg from 'assets/icons/reflect/reflect2-neg.svg';
// import coloredSvg from 'assets/icons/reflect/reflect-colored.svg';
// import coloredSvg from 'assets/icons/reflect/like-colored.svg';
// import whiteSvg from 'assets/icons/reflect/like-white.svg';
// import bwSvg from 'assets/icons/reflect/like-bw.svg';
// import bwNegSvg from 'assets/icons/reflect/like-bw-neg.svg';

const useStyles = makeStyles(theme => ({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit'
  },
  avatar: {
    backgroundColor: 'white',
    width: theme.spacing(),
    height: theme.spacing(3),
  },
}));

export default function(props) {
  const classes = useStyles();
  const variant = _.get(props, 'variant', 'colored');

  let image = null;
  switch(variant) {
    // case 'white':
    //   image = whiteSvg;
    //   break;
    // case 'bw':
    //   image = bwSvg;
    //   break;
    // case 'neg':
    //   image = bwNegSvg;
    //   break;
    default:
      image = coloredSvg;
  }

  return (
    <Icon {...props}>
      <img className={classes.imageIcon}
          src={image} alt="" // @todo
      />
    </Icon>
  );
}