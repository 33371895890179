import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
  },
});

export default function PageNotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1>404</h1>
      <h2>Page not found</h2>
    </div>
  );
}