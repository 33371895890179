import { configureStore } from '@reduxjs/toolkit';

import authReducer from 'features/auth/auth.slice';
import layoutReducer from 'features/layout/layout.slice';
import discussReducer from 'features/discuss/store/discuss.slice';

// @todo redux-dynamic-modules
// @see https://www.youtube.com/watch?v=SktRbSZ-4Tk

const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    discuss: discussReducer,
  },
});

// if (module.hot) {
//   // Enable Webpack hot module replacement for reducers
//   module.hot.accept('../reducers', () => {
//     store.replaceReducer(require('../reducers').default)
//   });
// }

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }

export default store;
